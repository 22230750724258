import "./betslip.css";
import {
    addAllToBetSlip,
    addToTJKBetSlip,
    betSlipActionSubject,
    clearTjkBetSlip, clearTjkFobBetSlip,
    getBetCount,
    getLegsSelections,
    isAdded,
    isAddedAll,
    mobileBetSlipActions,
    mobileBetSlipSubject,
    PlayTjkCoupon,
    setTjkBetSlipMaxAmount,
    setTjkBetSlipMultiply,
    setTjkShareCoupon,
    setTjkUsePointBalance,
    tjkBetSlipOptions,
    tjkBetslipStore,
    tjkCalculateProfitOptions,
    tjkCompleteBettingTypes,
    tjkSortedBetTypes,
    updateBetCount,
} from "../../services/betslip.service";
import React, {useEffect, useState} from "react";
import {
    CurrencyFormat,
    dateTimeStringFormat,
    mobileView,
    preventBetSlipValues,
    useForceUpdate,
} from "../../services/misc.functions";
import iconTrash from "../../assets/feather/trash-2.svg";
import iconSave from "../../assets/feather/save.svg";
import calculate from "../../assets/feather/calculate.svg";
import iconSlash from "../../assets/feather/slash.svg";
import editIcon from "../../assets/feather/edit.png";

import iconLeft from "../../assets/feather/chevron-left.svg";
import {SortMyHorse} from "./sortmyhorse";
import _ from "lodash";
import {auth} from "../../store/auth.store";
import {bulletinProvider} from "../../services/bulletin.service";
import {modalServiceSubject} from "../../services/modal.service";
import {useNavigate} from "react-router-dom";
import paths from "../../paths";
import {isMobile} from "../../App";
import {availableBettingSubTypes, availableFobHippodromes, BettingTypesMap} from "../../store/bulletin.store";
import {GetStablemateIcon} from "../../pages/betting/components/betting-datatable";

import Modal from "react-modal";
import iconExit from "../../assets/feather/x-circle.svg";
import html2canvas from 'html2canvas';
import iconClose from "../../assets/feather/x-circle.svg";

let tjkBetslipTimer: any = null;

export function BetSlip(prop: any) {
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();
    const [profitModal, setProfitModal] = useState<boolean>(false);

    useEffect(() => {
        let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
            forceUpdate();
            // console.log(tjkBetSlipOptions);
        });
        return () => {
            betSlipActionSubject_subscription.unsubscribe();
            clearTimeout(tjkBetslipTimer);
        };
    }, []);

    let runsMap: any = {};
    let runsList: any = [];
    let horsesMap: any = {};

    runsList = tjkBetSlipOptions.hippodrome.runs.filter((run: any) =>
        tjkBetSlipOptions.bettingSubType.label.split("-").includes(String(run.no))
    );
    for (let run of runsList) {
        runsMap[Number(run.no)] = run;
    }

    if (tjkBetSlipOptions.bettingSubType === null || tjkBetSlipOptions.hippodrome === null) {
        return <></>;
    }

    let tabs: any = [];
    for (
        let i = Number(tjkBetSlipOptions.bettingSubType.id);
        i < Number(tjkBetSlipOptions.bettingSubType.id) + tjkBetSlipOptions.bettingType.column;
        i += 1
    ) {
        tabs.push(i);
    }

    const runs = tjkBetSlipOptions.hippodrome.runs.filter((run: any) => tabs.includes(Number(run.no)));

    let emptyTabs: any = [];
    let maxHorseSize: number = 0;
    const generateEmptyTabs = () => {
        emptyTabs = [];
        for (let i = tabs.length + 1; i <= 7; i += 1) {
            emptyTabs.push(i);
        }
        maxHorseSize = runs
            .map((el: any) => el.horses.length)
            .sort((a: number, b: number) => a - b)
            .reverse()[0];
    };
    generateEmptyTabs();

    if (!tjkCompleteBettingTypes.includes(tjkBetSlipOptions.bettingType.id)) {
        tjkBetSlipOptions.complete = false;
    }

    let betCount = getBetCount();
    // console.log(tjkBetSlipOptions.betSlipMultiply);
    // console.log(betCount);
    // console.log(tjkBetSlipOptions.bettingType.price);
    let totalAmount = Number(
        (Number(tjkBetSlipOptions.betSlipMultiply) * betCount * tjkBetSlipOptions.bettingType.price).toFixed(2)
    );
    // console.log(totalAmount);
    let betSlipMaxMultiply =
        betCount > 0
            ? Math.floor(tjkBetSlipOptions.bettingType.maxCouponAmount / betCount)
            : tjkBetSlipOptions.bettingType.maxCouponAmount;

    let multiplyChoices: any = [];
    if (betSlipMaxMultiply > 200) {
        multiplyChoices = _.range(1, 101);
        let mod = betSlipMaxMultiply % 50;
        multiplyChoices.push(..._.range(200, betSlipMaxMultiply, 50));
        if (mod > 0) {
            multiplyChoices.push(multiplyChoices[multiplyChoices.length - 1] + mod);
        }
    } else {
        multiplyChoices = _.range(1, betSlipMaxMultiply + 1);
    }

    if (totalAmount > tjkBetSlipOptions.bettingType.maxCouponAmount) {
        tjkBetSlipOptions.errorMessage =
            "Toplam bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + " TL'yi geçemez.";
        totalAmount = tjkBetSlipOptions.betSlipMultiply * betCount * tjkBetSlipOptions.bettingType.price;
    } else if (
        tjkBetSlipOptions.errorMessage ===
        "Toplam bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + " TL'yi geçemez."
    ) {
        tjkBetSlipOptions.errorMessage = null;
    }

    const playCoupon = (save: boolean = false, imgData: any = '') => {
        if (!tjkBetSlipOptions.inProgress) {
            tjkBetSlipOptions.inProgress = true;
            forceUpdate();
            if (!auth.member || !auth.member.id) {
                modalServiceSubject.next({
                    title: "Üye Girişi",
                    content: `<p>Bahis yapabilmeniz için önce oturum açmanız gerekli. Üye değilseniz hemen üye olabilir yada giriş yaparak bahis yapabilirsiniz.</p>`,
                    confirm: {
                        sure: {
                            label: "Giriş Yap",
                            class: "btn btn-success",
                            action: () => {
                                navigate(paths.login.url);
                            },
                        },
                        cancel: {
                            label: "Üye Ol",
                            class: "btn btn-primary",
                            action: () => {
                                navigate(paths.register.url);
                            },
                        },
                    },
                });
                return false;
            }

            PlayTjkCoupon(save, imgData).then((result: any) => {
                if (result) {
                    if (result.status === true && save === false) {
                        try {
                            // @ts-ignore
                            fbq("track", "Purchase", {value: totalAmount, currency: "TRY"});
                        } catch (e) {
                        }
                        clearTjkBetSlip();
                        modalServiceSubject.next({
                            case: "info",
                            title: "Kuponunuz Oynandı",
                            // content: "Kuponunuz başarıyla oynandı. Kuponunuzun durumunu kuponlarım sayfasından takip edebilirsiniz."
                            content: `<p class="text-center"><b>${
                                result.data.coupon_data.approve_code
                            }</b> kodu ile <b>${CurrencyFormat(Number(result.data.coupon_data.amount || 0))} ₺</b> tutarındaki <b>${
                                BettingTypesMap[Number(result.data.coupon_data.bet_type)]?.name
                            }</b> kuponunuz başarıyla oynandı. <br/>Kuponunuzun durumunu kuponlarım sayfasından takip edebilirsiniz.</p>`,
                            confirm: {
                                sure: {
                                    label: "TJK Kuponlarım",
                                    action: () => {
                                        navigate(paths.memberTickets.url);
                                    },
                                },
                                cancel: {
                                    label: "Yeni Kupon Oyna",
                                    action: () => {
                                        if (isMobile) {
                                            mobileBetSlipActions.isOpen = false;
                                            mobileBetSlipSubject.next("update");
                                        }
                                    },
                                },
                            },
                        });
                    } else if (result.status && save === true) {
                        modalServiceSubject.next({
                            case: "info",
                            title: "Kuponunuz Kaydedildi",
                            content: "Kuponunuz başarıyla Kaydedildi. Kuponunuzu kuponlarım sayfasında görüntüleyebilirsiniz",
                            confirm: {
                                sure: {
                                    label: "Kayıtlı Kuponlarım",
                                    action: () => {
                                        navigate(paths.memberSavedTickets.url);
                                    },
                                },
                                cancel: {
                                    label: "Yeni Kupon Oyna",
                                    action: () => {
                                        if (isMobile) {
                                            mobileBetSlipActions.isOpen = false;
                                            mobileBetSlipSubject.next("update");
                                        }
                                    },
                                },
                            },
                        });
                    } else {
                        // tjkBetSlipOptions.errorMessage = typeof(result.message) === 'object' ? JSON.stringify(result.message): result.message;

                        let error_description: any = null;
                        let error_message: any = null;
                        if (result.data && result.data.error) {
                            error_description = result.data.error.error_description;
                            error_message = result.data.error.error_message;
                        }

                        let sure: any = null;
                        if (typeof result.message === "string" && result.message.indexOf("Oturum") !== -1) {
                            sure = {
                                label: "Giriş Yap",
                                action: () => {
                                    navigate(paths.login.url);
                                },
                            };
                        }
                        if (error_message === "TIMEOUT") {
                            sure = {
                                label: "TJK Kuponlarım",
                                action: () => {
                                    navigate(paths.memberTickets.url);
                                },
                            };
                        }

                        modalServiceSubject.next({
                            case: "danger",
                            title: "Hata",
                            content:
                                error_description ||
                                (typeof result.message === "object" ? JSON.stringify(result.message) : result.message),
                            confirm: {
                                sure: sure,
                                cancel: {
                                    label: "Tamam",
                                },
                            },
                        });
                        forceUpdate();
                    }
                }
                tjkBetSlipOptions.inProgress = false;
                forceUpdate();
            });
            forceUpdate();
        }
    };

    const confirmCoupon = async () => {
        clearTimeout(tjkBetslipTimer);
        let imgData = '';
        try {

            const domElement: any = document.getElementById("legs-row");
            // @ts-ignore
            await html2canvas(domElement, {
                width: domElement.clientWidth,
                height: domElement.clientHeight,
                scale: 0.7,
            }).then((canvas: any) => {
                imgData = canvas.toDataURL("image/jpeg");
            });
        } catch (e) {
            console.log('imgData not Found ', e)
        }
        // console.log( tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price);
        if (auth.authentication && auth.member && auth.member.id) {
            if (betCount === 0) {
                tjkBetSlipOptions.errorMessage = "Kuponunuz da oynanacak bir yarış bulunmamaktadır.";
            } else if ((tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) > 6500) {
                tjkBetSlipOptions.errorMessage = "Kupon tutarı 6500 TL'den fazla olamaz.";
            } else if (
                tjkBetSlipOptions.shareCoupon !== true &&
                (tjkBetSlipOptions.usePointBalance
                    ? Number(auth.member.balance_debit) + auth.member.balance_point
                    : auth.member.balance_debit) < (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount)
            ) {
                tjkBetSlipOptions.errorMessage = "Bakiyeniz yetersiz.";
            } else if (
                (tjkBetSlipOptions.system
                    ? tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price
                    : totalAmount) < tjkBetSlipOptions.bettingType.minCouponAmount
            ) {
                tjkBetSlipOptions.errorMessage =
                    "Minumum bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.minCouponAmount) + " TL olmalıdır.";
            }
            if (tjkBetSlipOptions.errorMessage === null) {
                if (isMobile) {
                    playCoupon(false, imgData);
                } else {
                    let slipItem = tjkBetslipStore.filter((el: any) => {
                        if (
                            el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
                            el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
                            el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id)
                        ) {
                            return el;
                        }
                    });
                    let uniqueColumn = [...Array.from(new Set(slipItem.map((i: any) => i.column)))].sort();

                    let columnRunMap: any = {};
                    for (let col of uniqueColumn) {
                        columnRunMap[Number(col)] = slipItem.find((r: any) => r.column === col);
                    }

                    for (let run of runs) {
                        for (let horse of run.horses) {
                            if (!horsesMap[Number(run.no)]) {
                                horsesMap[Number(run.no)] = {};
                            }
                            horsesMap[Number(run.no)][Number(horse.no)] = horse;
                        }
                    }

                    modalServiceSubject.next({
                        title: `BAHİS DETAYI - ${tjkBetSlipOptions.hippodrome.location} - (${dateTimeStringFormat(
                            tjkBetSlipOptions.hippodrome.date,
                            true
                        )})`,
                        width: "960px",
                        content: `
        <table class="table table-striped coupon-detail">
            <tbody>
            
                <tr>
                    <th class="text-left" style="width: 100px">Hipodrom</th>
                    <td class="text-left" style="width: calc(15% - 120px )">${
                            tjkBetSlipOptions.hippodrome.location
                        }</td>
                    <th class="text-left" style="width: 100px">Bahis Türü</th>
                    <td class="text-left" style="width: calc(25% - 120px )"> ${tjkBetSlipOptions.bettingType.name}</td>
                    ${!isMobile ? `<th className="text-left" style={{width: "100px"}}>Koşu</th>` : ""} 
                    ${
                            !isMobile
                                ? `<td className="text-left"
                                       style={{width: "calc(25% - 120px )"}}>${tjkBetSlipOptions.bettingSubType.label}</td>`
                                : ""
                        } 
                    
                </tr>
                
                <tr>
                    <th class="text-left" style="width: 100px">Bahis Sayısı</th>
                    <td class="text-left" style="width: calc(15% - 120px )">
                    
                    ${
                            tjkBetSlipOptions.system &&
                            tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                                ? tjkBetSlipOptions.systemMaxMin.join("-")
                                : ""
                        }
                    
                    ${
                            tjkBetSlipOptions.system &&
                            tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1]
                                ? tjkBetSlipOptions.systemMaxMin[0]
                                : ""
                        }
                    
                    ${!tjkBetSlipOptions.system ? betCount : ""}
                    </td>
                    <th class="text-left" style="width: 100px">Misli</th>
                    <td class="text-left" style="width: calc(25% - 120px )">${tjkBetSlipOptions.betSlipMultiply}</td>
                    ${!isMobile ? `<th className="text-left" style={{width: "100px"}}>Tutar</th>` : ""}
                   ${
                            !isMobile
                                ? `<td className="text-left" style={{width: "calc(25% - 120px )"}}>
                    
                    ${
                                    tjkBetSlipOptions.system &&
                                    tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                                        ? CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) +
                                        "₺ - " +
                                        CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price) +
                                        "₺"
                                        : tjkBetSlipOptions.system
                                            ? CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) + "₺"
                                            : CurrencyFormat(totalAmount) + "₺"
                                }
                    
                  
                                        
                    </td> `
                                : ""
                        }
                </tr>
                ${
                            isMobile
                                ? ` <tr>
                      <th className="text-left" style={{width: "100px"}}>Koşu</th>
                      <td className="text-left"
                          style={{width: "calc(25% - 120px )"}}>${tjkBetSlipOptions.bettingSubType.label}</td>
                      <th className="text-left" style={{width: "100px"}}>Tutar</th>
                      <td className="text-left" style={{width: "calc(25% - 120px )"}}>

                          ${
                                    tjkBetSlipOptions.system &&
                                    tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                                        ? CurrencyFormat(
                                            tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price
                                        ) +
                                        "₺ - " +
                                        CurrencyFormat(
                                            tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price
                                        ) +
                                        "₺"
                                        : tjkBetSlipOptions.system
                                            ? CurrencyFormat(
                                            tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price
                                        ) + "₺"
                                            : CurrencyFormat(totalAmount) + "₺"
                                }


                      </td>
                  </tr>`
                                : ""
                        }
                
            </tbody>
        </table>
        
        <div class="coupon-legs" style="width:auto; margin-top: 15px;">
          ${uniqueColumn
                            .map((e: any) => {
                                return `<div class="col-12" style="width: ${
                                    isMobile ? "" : `calc(100% / ${uniqueColumn.length})`
                                } ;flex: unset;max-width: unset;">
            <div class="run-no">${
                                    tjkBetSlipOptions.bettingType.columnLabel === "Koşu" ? tjkBetSlipOptions.bettingSubType.id : e
                                }. ${tjkBetSlipOptions.bettingType.columnLabel.toUpperCase()}</div>
            ${_.orderBy(slipItem, [
                                    (el: any) => {
                                        if (el.horse === "s") {
                                            return 0;
                                        } else {
                                            return 1;
                                        }
                                    },
                                ])
                                    .filter((h: any) => h.column === e)
                                    .map((item: any, key: number) => {
                                        return `<div class="horse-no d-flex">
                <div class="text-center ${
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no1 === 1 ||
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no2 === 1
                                                ? "agf-horse border-0"
                                                : ""
                                        }" style=" font-weight: bold">${
                                            tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id)
                                                ? key + 1 + ".At"
                                                : item.horse === "s"
                                                    ? "S"
                                                    : horsesMap[columnRunMap[e].run][Number(item.horse)].no
                                        }</div>
                <div class="text-left text-wrap ps-2 ${
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no1 === 1 ||
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no2 === 1
                                                ? "agf-horse border-0"
                                                : ""
                                        }" style="">
                  ${tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? item.horse : ""} ${
                                            item.horse === "s" ? "HAZIR BAHİS" : horsesMap[columnRunMap[e].run][Number(item.horse)].name
                                        }
                </div>
              </div>`;
                                    })
                                    .join("")}
          </div>`;
                            })
                            .join("")}
        </div>
      `,
                        confirm: {
                            sure: {
                                class: "btn-success",
                                label: "Onayla",
                                action: () => {
                                    playCoupon(false, imgData);
                                },
                            },
                            cancel: {
                                label: "Vazgeç",
                            },
                        },
                    });
                }
            }
            forceUpdate();

            tjkBetslipTimer = setTimeout(() => {
                tjkBetSlipOptions.errorMessage = null;
                forceUpdate();
            }, 5000);
        } else {
            navigate(`${paths.login.url}/${window.location.pathname.replaceAll("/", "|")}`);
        }
    };

    const showBettingTypeInfo = (bettingType: any) => {
        modalServiceSubject.next({
            case: "info",
            title: bettingType.name,
            content: bettingType.info,
            confirm: {
                cancel: {
                    label: "Tamam",
                    class: "btn-warning fw-semibold w-100",
                },
            },
        });
    };

    const simpleView = (legs: any) => {
        let str: any = [];
        legs.forEach((l: any, i: number) => {
            if (i === 0) {
                str.push(l);
            } else if (Number(l) === Number(legs[i - 1]) + 1 && Number(l) === Number(legs[i + 1]) - 1) {
                if (str[str.length - 1] !== "-") {
                    str.push("-");
                }
            } else {
                str.push(l);
            }
        });
        return str.join(", ").replaceAll(", -, ", "-");
    };

    let betSlipLegs = getLegsSelections();

    const _calculateProfit = () => {
        if (tjkBetSlipOptions.bettingType.id === 18) {
            var calculatedAgfRatio = 1;

            for (let i = 0; i < runsList.length; i++) {
                for (const horse of runsList[i].horses) {
                    let horseIsAdded: any = isAdded(i + 1, runsList[i], horse) !== false;
                    if (horseIsAdded) {
                        if (runsList[0].isFirstSix) {
                            calculatedAgfRatio *= 100 / horse.agf_ratio1;
                        } else {
                            calculatedAgfRatio *= 100 / horse.agf_ratio2;
                        }
                        break;
                    }
                }
            }

            // var distribution_rate = (distribution / (prizes /6)) / 100;
            // console.log(calculatedAgfRatio);
            return (
                (calculatedAgfRatio * Number(tjkBetSlipOptions.bettingType.price) * 0.4).toFixed(2) +
                " - " +
                (calculatedAgfRatio * Number(tjkBetSlipOptions.bettingType.price) * 0.6).toFixed(2)
            );
        } else {
            return "";
        }
    };
    const calculateProfit = () => {
        var calculatedAgfRatio = 1;
        if (Object.keys(tjkCalculateProfitOptions.selectedHorses).length === 6) {
            for (const key of Object.keys(tjkCalculateProfitOptions.selectedHorses)) {
                if (runsList[0].isFirstSix) {
                    calculatedAgfRatio *= 100 / tjkCalculateProfitOptions.selectedHorses[key].agf_ratio1;
                } else {
                    calculatedAgfRatio *= 100 / tjkCalculateProfitOptions.selectedHorses[key].agf_ratio2;
                }
            }
            return (
                CurrencyFormat(calculatedAgfRatio * Number(tjkBetSlipOptions.bettingType.price) * 0.4, 2) +
                " ₺ - " +
                CurrencyFormat(calculatedAgfRatio * Number(tjkBetSlipOptions.bettingType.price) * 0.6, 2) +
                " ₺"
            );
        } else {
            return "Bütün ayaklar için bir at seçiniz";
        }
    };

    const selectAgf = () => {
        clearTjkBetSlip();

        var isFirstSixth = true;

        for (let i = 0; i < tabs.length; i++) {
            let columnType = tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 ? "run" : "horse";
            let runObject =
                runsMap[
                    columnType === "run"
                        ? Number(tjkBetSlipOptions.bettingSubType.label.split("-")[i])
                        : tjkBetSlipOptions.bettingSubType.id
                    ];
            if (i === 0) {
                isFirstSixth = runObject.isFirstSix;
            }
            // var isFirstSixth = runObject.bets.filter((el: any) => el.code === '35').length > 0;
            let horses = runObject.horses;

            let agfHorses = _.orderBy(horses, [isFirstSixth ? "agf_ratio1" : "agf_ratio2"], ["desc"]);

            addToTJKBetSlip(i + 1, runObject, agfHorses[0], "toggle");

            addToTJKBetSlip(i + 1, runObject, agfHorses[1], "toggle");
        }
    };

    const selectGanyan = (orders: any) => {
        clearTjkBetSlip();
        for (let i = 0; i < tabs.length; i++) {
            let columnType = tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 ? "run" : "horse";
            let runObject =
                runsMap[
                    columnType === "run"
                        ? Number(tjkBetSlipOptions.bettingSubType.label.split("-")[i])
                        : tjkBetSlipOptions.bettingSubType.id
                    ];
            let horses = runObject.horses;
            let minGanyan = _.orderBy(horses, ["ganyan"], [orders]);
            addToTJKBetSlip(i + 1, runObject, minGanyan[0], "toggle");
            addToTJKBetSlip(i + 1, runObject, minGanyan[1], "toggle");
        }
    };

    const selectMix = () => {
        clearTjkBetSlip();

        var isFirstSixth = true;

        for (let i = 0; i < tabs.length; i++) {
            let columnType = tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 ? "run" : "horse";
            let runObject =
                runsMap[
                    columnType === "run"
                        ? Number(tjkBetSlipOptions.bettingSubType.label.split("-")[i])
                        : tjkBetSlipOptions.bettingSubType.id
                    ];
            if (i === 0) {
                isFirstSixth = runObject.isFirstSix;
            }
            let horses = runObject.horses;
            let minGanyan = _.orderBy(horses, ["ganyan"], ["asc"]);
            let agfHorses = _.orderBy(horses, [isFirstSixth ? "agf_ratio1" : "agf_ratio2"], ["desc"]);
            var addingHorses = [];
            addingHorses.push(minGanyan[0]);
            addingHorses.push(minGanyan[1]);
            if (!addingHorses.includes(agfHorses[0])) {
                addingHorses.push(agfHorses[0]);
            }
            if (!addingHorses.includes(agfHorses[1])) {
                addingHorses.push(agfHorses[1]);
            }
            for (const addingHors of addingHorses) {
                addToTJKBetSlip(i + 1, runObject, addingHors, "toggle");
            }
        }
    };

    if (tjkBetSlipOptions.bettingType.id === 0) {
        return <></>;
    }

    return (
        <>
            <div className="betslip" id={"betslip"}>
                {isMobile && (
                    <button
                        className="btn btn-primary btn-icon float-start py-1 px-1 me-2"
                        onClick={() => {
                            // setMobileBetslip(false)
                            mobileBetSlipActions.isOpen = false;
                            mobileBetSlipSubject.next("update");
                        }}
                    >
                        <img alt={"left"} src={iconLeft} className="invert-color me-1"/>
                    </button>
                )}
                <h2 className="betting-type">
                    {tjkBetSlipOptions.bettingType.id === 18 && (
                        <>
                            {runsList[0].isFirstSix && "1. "}
                            {runsList[0].isSecondSix && "2. "}
                        </>
                    )}
                    {tjkBetSlipOptions.bettingType.name}

                    {!prop.isFob && tjkBetSlipOptions.bettingType.info && (
                        <i
                            className="info-circle float-end"
                            onClick={() => {
                                showBettingTypeInfo(tjkBetSlipOptions.bettingType);
                            }}
                        >
                            i
                        </i>
                    )}
                    {prop.isFob && (
                        <img alt={"kapat"} src={iconClose} className="float-end" onClick={() => {
                            prop.setToggleBetslip(false);
                        }}/>

                    )}
                </h2>
                <h3 className="hippodrome">{tjkBetSlipOptions.hippodrome.hippodrome}</h3>
                {tjkBetSlipOptions.bettingType.id === 18 && (
                    <div className={"d-flex"}>
                        <div className="dropdown w-100">
                            <button
                                className={`btn btn-sm btn-six dropdown-toggle w-100 d-flex justify-content-center align-items-center fw-bold`}
                                data-bs-toggle="dropdown"
                                style={{color: "#FFFFFF"}}
                                aria-expanded="false"
                            >
                                <img className={"me-1 invert-color"} src={editIcon} width={14}/> Hızlı 6'lı Yap
                            </button>
                            <ul className="dropdown-menu bg-white w-100">
                                <li className={"w-100"}>
                                    <a
                                        className="dropdown-item text-start"
                                        href="#"
                                        onClick={() => {
                                            selectAgf();
                                        }}
                                    >
                                        AGF'ye Göre Doldur
                                    </a>
                                </li>
                                <li className={"w-100"}>
                                    <a
                                        className="dropdown-item text-start"
                                        href="#"
                                        onClick={() => {
                                            selectGanyan("asc");
                                        }}
                                    >
                                        Ganyan'a Göre Doldur
                                    </a>
                                </li>
                                <li className={"w-100"}>
                                    <a
                                        className="dropdown-item text-start"
                                        href="#"
                                        onClick={() => {
                                            selectMix();
                                        }}
                                    >
                                        AGF + Ganyan'a Göre Doldur
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}

                {tjkBetSlipOptions.bettingType.id !== 1881 && !tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) && (
                    <div className="body betslip-body" id={"betslip-body"}>
                        {tabs.map((column: any, keyColumn: number) => {
                            let columnType = tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 ? "run" : "horse";

                            let runObject =
                                runsMap[
                                    columnType === "run"
                                        ? Number(tjkBetSlipOptions.bettingSubType.label.split("-")[keyColumn])
                                        : tjkBetSlipOptions.bettingSubType.id
                                    ];
                            let horses = runObject?.horses;
                            let isAddedAllStatus: any;
                            if (runObject) {
                                isAddedAllStatus = isAddedAll(keyColumn + 1, runObject);
                            }

                            return (
                                <ul key={`betslip-columns-${keyColumn}`}>
                                    <li
                                        className="column-no"
                                        onClick={() => {
                                            bulletinProvider.selectBettingColumn(keyColumn + 1);
                                            betSlipActionSubject.next(true);
                                        }}
                                    >
                                        {keyColumn + 1}
                                        {isMobile && <>. {tjkBetSlipOptions.bettingType.columnLabel}</>}
                                    </li>

                                    {tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 && (
                                        <li
                                            className={`system ${isAdded(keyColumn + 1, runObject, "s") !== false ? "horse-added" : ""}`}
                                            onClick={() => {
                                                addToTJKBetSlip(keyColumn + 1, runObject, "s", "toggle");
                                                addAllToBetSlip(keyColumn + 1, runObject, "remove");
                                            }}
                                        >
                                            {isMobile ? "Sistem" : "S"}
                                        </li>
                                    )}
                                    {!isMobile && (
                                        <li
                                            onClick={() => {
                                                addAllToBetSlip(keyColumn + 1, runObject, isAddedAllStatus === false ? "add" : "remove");
                                                addToTJKBetSlip(keyColumn + 1, runObject, "s", "remove");
                                            }}
                                            className={`all ${isAddedAllStatus === true ? "selected-all" : ""}`}
                                        >
                                            H
                                        </li>
                                    )}

                                    {isMobile && betSlipLegs[keyColumn].length === 0 && (
                                        <li className="bg-dark-subtle">
                                            <i className="d-block text-center">Bu ayak için en az 1 at seçmelisiniz!</i>
                                        </li>
                                    )}

                                    {horses &&
                                        horses.map((horse: any, keyHorses: number) => {
                                            let horseIsAdded: any = isAdded(keyColumn + 1, runObject, horse) !== false ? "horse-added" : "";
                                            if (isMobile && horseIsAdded !== "horse-added") {
                                                return <></>;
                                            }
                                            return (
                                                <li
                                                    key={`betslip-columns-horses-${keyHorses}`}
                                                    className={`${!isMobile ? horseIsAdded : ""} ${
                                                        horse.agf_sort_no1 === 1 || horse.agf_sort_no2 === 1 ? "agf-horse" : ""
                                                    }`}
                                                    onClick={() => {
                                                        if (!isMobile) {
                                                            addToTJKBetSlip(keyColumn + 1, runObject, horse, "toggle");
                                                        }
                                                    }}
                                                >
                                                    {horse.run_status !== true ? (
                                                        <>
                                                            <img src={iconSlash} alt="/"/>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {horse.no}
                                                            {isMobile && (
                                                                <>
                                                                    {" "}
                                                                    - {horse.name} <GetStablemateIcon
                                                                    horse={horse}/>{" "}
                                                                    <i className={"float-end"}>
                                                                        <img
                                                                            alt={"sil"}
                                                                            src={iconTrash}
                                                                            width={14}
                                                                            onClick={() => {
                                                                                addToTJKBetSlip(keyColumn + 1, runObject, horse, "toggle");
                                                                            }}
                                                                            className="cursor-pointer float-right"
                                                                        />
                                                                    </i>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </li>
                                            );
                                        })}

                                    {maxHorseSize > horses?.length &&
                                        [...Array(maxHorseSize - horses?.length)].map((horse: any, keyEmptyHorses: number) => {
                                            return <li className="emptyHorse"
                                                       key={`betslip-columns-horses-${keyEmptyHorses}`}></li>;
                                        })}
                                </ul>
                            );
                        })}

                        {!isMobile &&
                            emptyTabs.map((emptyColumn: any, emptyColumnKey: number) => {
                                return (
                                    <ul className="emptyColumn" key={`betslip-emptyColumns-${emptyColumnKey}`}>
                                        <li className="column-no">{emptyColumn}</li>
                                        {tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 &&
                                            <li className="system">S</li>}
                                        <li className="all">H</li>
                                        {[...Array(maxHorseSize)].map((horse: any, keyEmptyHorses: number) => {
                                            return <li className="emptyHorse"
                                                       key={`betslip-columns-horses-${keyEmptyHorses}`}></li>;
                                        })}
                                    </ul>
                                );
                            })}
                    </div>
                )}

                {(tjkBetSlipOptions.bettingType.id === 1881 || tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id)) && (
                    <>
                        <SortMyHorse parentUpdate={forceUpdate}></SortMyHorse>
                    </>
                )}

                <div className="footer">
                    {tjkBetSlipOptions.bettingType.id !== 1881 && (
                        <div style={{border: 0, paddingTop: 0}} id={"legs-row"}>
                            {tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? (
                                <div className="d-flex w-100">
                                    {betSlipLegs
                                        ?.map((l: any, k: number) => {
                                            return l;
                                        })
                                        .filter((e: any) => e !== "")
                                        .join(" / ")}
                                </div>
                            ) : (
                                <div className="d-flex w-100">
                                    {betSlipLegs
                                        ?.map((l: any, k: number) => {
                                            return simpleView(l);
                                        })
                                        .filter((e: any) => e !== "")
                                        .join(" / ")}
                                </div>
                            )}
                        </div>
                    )}

                    {tjkCompleteBettingTypes.includes(tjkBetSlipOptions.bettingType.id) && (
                        <div>
                            <label>Komple:</label>
                            <input
                                type="checkbox"
                                checked={tjkBetSlipOptions.complete}
                                onChange={() => {
                                    tjkBetSlipOptions.complete = !tjkBetSlipOptions.complete;
                                    updateBetCount.status = true;
                                    forceUpdate();
                                }}
                            />
                        </div>
                    )}

                    {tjkBetSlipOptions.system ? (
                        <div>
                            <label>Tutar:</label>
                            <input
                                type="number"
                                style={{textAlign: "right"}}
                                pattern="^[0-9\b]+$"
                                min={1}
                                max={betSlipMaxMultiply}
                                step={1}
                                value={tjkBetSlipOptions.maxAmount || ""}
                                onChange={(e) => setTjkBetSlipMaxAmount(e.target.value)}
                                onBlur={() => {
                                    if (tjkBetSlipOptions.maxAmount === "") {
                                        setTjkBetSlipMaxAmount(1);
                                    }
                                }}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    {!tjkBetSlipOptions.system && (
                        <div>
                            <label>Misli:</label>
                            <div className={"betslip-misli"}>
                                <input
                                    type="number"
                                    className={"betslip-input"}
                                    style={{textAlign: "left"}}
                                    pattern="^[0-9\b]+$"
                                    min={1}
                                    max={betSlipMaxMultiply}
                                    step={1}
                                    value={tjkBetSlipOptions.betSlipMultiply || ""}
                                    onKeyDown={preventBetSlipValues}
                                    onChange={(e) => setTjkBetSlipMultiply(e.target.value)}
                                    onBlur={() => {
                                        if (tjkBetSlipOptions.betSlipMultiply === "") {
                                            setTjkBetSlipMultiply(1);
                                        }
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                                <select
                                    className="betslip-select"
                                    value={tjkBetSlipOptions.betSlipMultiply}
                                    onChange={(e) => setTjkBetSlipMultiply(Number(e.target.value))}
                                >
                                    {multiplyChoices.map((el: number, key: number) => {
                                        return (
                                            <option key={key} value={el}>
                                                {el}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    )}

                    <div>
                        <label>Bahis:</label>
                        <span>
              {tjkBetSlipOptions.system ? (
                  <>
                      {tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                          ? tjkBetSlipOptions.systemMaxMin.join("-")
                          : tjkBetSlipOptions.systemMaxMin[0]}
                  </>
              ) : (
                  <>{betCount}</>
              )}
            </span>
                    </div>

                    <div>
                        <label>Tutar:</label>
                        <span>
              {tjkBetSlipOptions.system ? (
                  <>
                      {tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? (
                          <>{CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺</>
                      ) : (
                          <>
                              {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺
                              -{" "}
                              {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price)}₺
                          </>
                      )}
                  </>
              ) : (
                  <>{CurrencyFormat(totalAmount)}₺</>
              )}
            </span>
                    </div>

                    {tjkBetSlipOptions.bettingType.id === 18 && (
                        <div>
                            <label
                                onClick={() => {
                                    setProfitModal(true);
                                }}
                                style={{width: "100%"}}
                                className={"cursor-pointer"}
                            >
                                <img src={calculate} width={21} height={"auto"} className={"me-1"}/>
                                Bu 6'lı Ne Verir?
                            </label>
                            {/*<span style={{width:"50%"}}>{calculateProfit()} ₺</span>*/}
                        </div>
                    )}

                    {auth.authentication && auth.member && auth.member.id ? (
                        <>
                            <div>
                                <label>Puan Kullan:</label>
                                <span>
                  <input
                      type="checkbox"
                      checked={tjkBetSlipOptions.usePointBalance}
                      disabled={auth.member ? auth.member.balance_point <= 0 : true}
                      onChange={() => {
                          setTjkUsePointBalance(!tjkBetSlipOptions.usePointBalance);
                      }}
                  />{" "}
                                    {auth.member ? auth.member.balance_point + "₺" : "0.00₺"}
                </span>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {tjkBetSlipOptions.errorMessage ? (
                        <div className="mt-1 mb-1">
                            <div className="w-100 p-2 alert alert-danger m-0">{tjkBetSlipOptions.errorMessage}</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {auth.authentication && auth.member && auth.member.is_editor === true && (
                        <>
                            <div>
                                <label>Paylaş:</label>
                                <span>
                  <input
                      type="checkbox"
                      checked={tjkBetSlipOptions.shareCoupon}
                      onChange={() => {
                          setTjkShareCoupon(!tjkBetSlipOptions.shareCoupon);
                      }}
                  />
                </span>
                            </div>
                        </>
                    )}

                    {tjkBetSlipOptions.inProgress ? (
                        <>
                            <div className="actions">
                                <button className="button-clear" disabled>
                                    <img src={iconTrash} alt=""/>
                                </button>
                                <button disabled className="button-save">
                                    <img src={iconSave} alt=""/>
                                </button>
                                <button className="button-play" disabled>
                                    Hemen Oyna
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="actions">
                                <button className="button-clear">
                                    <img
                                        src={iconTrash}
                                        onClick={() => {
                                            clearTjkBetSlip();
                                        }}
                                        alt=""
                                    />
                                </button>

                                <button
                                    onClick={() => {
                                        playCoupon(true);
                                    }}
                                    className="button-save"
                                >
                                    <img src={iconSave} alt=""/>
                                </button>

                                {!tjkBetSlipOptions.shareCoupon && tjkBetSlipOptions.hippodrome.status && (
                                    <>
                                        <button
                                            disabled={
                                                tjkBetSlipOptions.inProgress ||
                                                tjkBetSlipOptions.errorMessage ||
                                                (tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] === 0) ||
                                                betCount === 0
                                                    ? true
                                                    : false
                                            }
                                            className={`button-play`}
                                            onClick={() => {
                                                confirmCoupon();
                                            }}
                                        >
                                            Hemen Oyna
                                        </button>
                                    </>
                                )}

                                {!tjkBetSlipOptions.hippodrome.status && (
                                    <button className="button-play" disabled>
                                        Bahisler Açılmadı
                                    </button>
                                )}

                                {tjkBetSlipOptions.shareCoupon && (
                                    <button
                                        disabled={
                                            tjkBetSlipOptions.errorMessage ||
                                            (tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] === 0) ||
                                            betCount === 0
                                                ? true
                                                : false
                                        }
                                        className={`button-play share`}
                                        onClick={() => {
                                            confirmCoupon();
                                        }}
                                    >
                                        Hemen Paylaş
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Modal
                isOpen={profitModal !== false}
                onRequestClose={() => {
                    setProfitModal(false);
                }}
                onAfterClose={() => {
                    tjkCalculateProfitOptions.selectedHorses = {};
                }}
                ariaHideApp={false}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        width: "1140px",
                        maxHeight: "calc(100% - 40px)",
                        marginRight: "-50%",
                        maxWidth: " calc( 100% - 40px )",
                        padding: "10px",
                        transform: "translate(-50%, -50%)",
                        background: "var(--bs-white)",
                        borderRadius: "var(--bs-border-radius-xl)",
                        border: 0,
                    },
                    overlay: {
                        zIndex: 2,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10px)",
                    },
                }}
                contentLabel=""
            >
                <div className="card mt-0">
                    <div className="title modal-sticky-title">
                        <div className={"float-end"}>
                            <img
                                alt={"iconExit"}
                                src={iconExit}
                                width={24}
                                className={"float-end cursor-pointer"}
                                onClick={() => setProfitModal(false)}
                            />
                        </div>
                        <h3>İkramiye Hesaplama</h3>
                    </div>
                    {profitModal && (
                        <div className="body">
                            <div className={`coupon-legs row`}>
                                {runsList.map((el: any, key: any) => {
                                    return (
                                        <div key={"tickets-" + key}
                                             className={`${isMobile ? "col-12 mt-1" : "col-4 mt-2"}`}>
                                            <div className="run-no d-flex justify-content-between px-3">
                                                {key + 1} . {tjkBetSlipOptions.bettingType.columnLabel}
                                            </div>
                                            {el.horses.map((item: any, horseKey: number) => {
                                                let horseIsAdded: any = isAdded(key + 1, el, item) !== false;
                                                if (horseIsAdded) {
                                                    return (
                                                        <div
                                                            key={`order-detail-horse-${horseKey}`}
                                                            className="horse-no d-flex justify-content-between mt-1 align-items-center"
                                                            style={mobileView ? {background: "var(--color-bg-white)"} : {}}
                                                        >
                                                            <div className={"text-left w-50"} style={{paddingLeft: 5}}>
                                                                {item.no} - {item.name}
                                                            </div>
                                                            <div style={{paddingRight: 5}}
                                                                 className={"text-end fw-bold flex-fill"}>
                                                                %{runsList[0].isFirstSix ? item.agf_ratio1 : item.agf_ratio2}
                                                            </div>
                                                            <div style={{paddingRight: 10}}
                                                                 className={"text-end flex-fill"}>
                                                                <label className="switch">
                                                                    <input
                                                                        className="profit-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        checked={tjkCalculateProfitOptions.selectedHorses[key]?.no === item.no}
                                                                        onChange={() => {
                                                                            tjkCalculateProfitOptions.selectedHorses[key] = item;
                                                                            forceUpdate();
                                                                        }}
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <div className={"footer sticky-bottom bg-white px-2 py-3"} style={{bottom: "-15px"}}>
                        <p className={`${isMobile ? "fs-6" : "fs-5"}`}>
                            Tahmini İkramiye: <b>{calculateProfit()}</b>
                        </p>
                        <p className="my-1 alert alert-warning">
                            <b>"Bu 6'lı Ganyan Ne Verir?"</b> özelliği ile hesaplanan ikramiye tutarı, tahmini olarak
                            hesaplanmaktadır. Resmi ikramiye tutarı, TJK’nın paylaştığı resmi sonuçların ilanından sonra
                            belli olur.
                        </p>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export function MobileBetslip(prop: any) {
    const selectBettingColumn = prop.selectBettingColumn;

    let betCount = getBetCount();
    let totalAmount = Number(
        (Number(tjkBetSlipOptions.betSlipMultiply) * betCount * tjkBetSlipOptions.bettingType.price).toFixed(2)
    );
    let betSlipMaxMultiply =
        betCount > 0
            ? Math.floor(tjkBetSlipOptions.bettingType.maxCouponAmount / betCount)
            : tjkBetSlipOptions.bettingType.maxCouponAmount;
    let runsMap: any = {};
    let runsList: any = [];
    runsList = tjkBetSlipOptions.hippodrome.runs.filter((run: any) =>
        tjkBetSlipOptions.bettingSubType.label.split("-").includes(String(run.no))
    );
    for (let run of runsList) {
        runsMap[Number(run.no)] = run;
    }

    if (totalAmount > tjkBetSlipOptions.bettingType.maxCouponAmount) {
        tjkBetSlipOptions.errorMessage =
            "Toplam bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + " TL'yi geçemez.";
        totalAmount = tjkBetSlipOptions.betSlipMultiply * betCount * tjkBetSlipOptions.bettingType.price;
    } else if (
        tjkBetSlipOptions.errorMessage ===
        "Toplam bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + " TL'yi geçemez."
    ) {
        tjkBetSlipOptions.errorMessage = null;
    }
    const selectAgf = () => {
        clearTjkBetSlip();

        var isFirstSixth = true;
        if (availableBettingSubTypes.length > 0) {
            if (tjkBetSlipOptions.bettingSubType.id !== availableBettingSubTypes[0].id) {
                isFirstSixth = false;
            }
        }

        for (let i = 0; i < betSlipLegs.length; i++) {
            let columnType = tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 ? "run" : "horse";
            let runObject =
                runsMap[
                    columnType === "run"
                        ? Number(tjkBetSlipOptions.bettingSubType.label.split("-")[i])
                        : tjkBetSlipOptions.bettingSubType.id
                    ];
            // var isFirstSixth = runObject.bets.filter((el: any) => el.code === '35').length > 0;
            let horses = runObject.horses;
            let agfHorses = _.orderBy(horses, [isFirstSixth ? "agf_sort_no1" : "agf_sort_no2"], ["asc"]);
            addToTJKBetSlip(i + 1, runObject, agfHorses[0], "toggle");

            addToTJKBetSlip(i + 1, runObject, agfHorses[1], "toggle");
        }
    };
    const selectGanyan = (orders: any) => {
        clearTjkBetSlip();
        for (let i = 0; i < betSlipLegs.length; i++) {
            let columnType = tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 ? "run" : "horse";
            let runObject =
                runsMap[
                    columnType === "run"
                        ? Number(tjkBetSlipOptions.bettingSubType.label.split("-")[i])
                        : tjkBetSlipOptions.bettingSubType.id
                    ];
            let horses = runObject.horses;
            let minGanyan = _.orderBy(horses, ["ganyan"], [orders]);
            addToTJKBetSlip(i + 1, runObject, minGanyan[0], "toggle");
            addToTJKBetSlip(i + 1, runObject, minGanyan[1], "toggle");
        }
    };
    const selectMix = () => {
        clearTjkBetSlip();

        var isFirstSixth = true;
        if (availableBettingSubTypes.length > 0) {
            if (tjkBetSlipOptions.bettingSubType.id !== availableBettingSubTypes[0].id) {
                isFirstSixth = false;
            }
        }

        for (let i = 0; i < betSlipLegs.length; i++) {
            let columnType = tjkBetSlipOptions.bettingSubType.label.split("-").length > 1 ? "run" : "horse";
            let runObject =
                runsMap[
                    columnType === "run"
                        ? Number(tjkBetSlipOptions.bettingSubType.label.split("-")[i])
                        : tjkBetSlipOptions.bettingSubType.id
                    ];
            let horses = runObject.horses;
            let minGanyan = _.orderBy(horses, ["ganyan"], ["asc"]);
            let agfHorses = _.orderBy(horses, [isFirstSixth ? "agf_sort_no1" : "agf_sort_no2"], ["asc"]);
            var addingHorses = [];
            addingHorses.push(minGanyan[0]);
            addingHorses.push(minGanyan[1]);
            if (!addingHorses.includes(agfHorses[0])) {
                addingHorses.push(agfHorses[0]);
            }
            if (!addingHorses.includes(agfHorses[1])) {
                addingHorses.push(agfHorses[1]);
            }
            for (const addingHors of addingHorses) {
                addToTJKBetSlip(i + 1, runObject, addingHors, "toggle");
            }
        }
    };

    let betSlipLegs = getLegsSelections();

    const simpleView = (legs: any) => {
        let str: any = [];
        legs.forEach((l: any, i: number) => {
            if (i === 0) {
                str.push(l);
            } else if (Number(l) === Number(legs[i - 1]) + 1 && Number(l) === Number(legs[i + 1]) - 1) {
                if (str[str.length - 1] !== "-") {
                    str.push("-");
                }
            } else {
                str.push(l);
            }
        });
        return str.join(", ").replaceAll(", -, ", "-");
    };

    return (
        <div id="mobile-foot-betslip" className="mobile-foot-betslip sticky">
            <div className="row">
                <div className="col-5">
                    <div className={"d-flex align-items-center justify-content-between"}>
                        <h2 className={"mb-0"}>BİLETİM</h2>
                        {tjkBetSlipOptions.bettingType.id === 18 && (
                            <div className={"d-flex align-items-center"}>
                                <div className="dropdown">
                                    <button
                                        className={`btn btn-sm btn-six dropdown-toggle ms-1 fw-bold`}
                                        data-bs-toggle="dropdown"
                                        style={{color: "#FFFFFF"}}
                                        aria-expanded="false"
                                    >
                                        Hızlı 6'lı
                                    </button>
                                    <ul className="dropdown-menu bg-white">
                                        <li>
                                            <a
                                                className="dropdown-item text-start"
                                                href="#"
                                                onClick={() => {
                                                    selectAgf();
                                                }}
                                            >
                                                AGF'ye Göre Doldur
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item text-start"
                                                href="#"
                                                onClick={() => {
                                                    selectGanyan("asc");
                                                }}
                                            >
                                                Ganyan'a Göre Doldur
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item text-start"
                                                href="#"
                                                onClick={() => {
                                                    selectMix();
                                                }}
                                            >
                                                AGF + Ganyan'a Göre Doldur
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-7 text-end">
                    <button
                        className="btn btn-primary float-end ms-3 fw-bold"
                        onClick={() => {
                            mobileBetSlipActions.isOpen = true;
                            mobileBetSlipSubject.next("update");
                        }}
                    >
                        OYNA
                    </button>
                    BAHİS SAYISI:{" "}
                    {tjkBetSlipOptions.system ? (
                        <>
                            {tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                                ? tjkBetSlipOptions.systemMaxMin.join("-")
                                : tjkBetSlipOptions.systemMaxMin[0]}
                        </>
                    ) : (
                        <>{betCount}</>
                    )}
                    <span className="total d-block fw-bold">
            TUTAR:{" "}
                        {tjkBetSlipOptions.system ? (
                            <>
                                {tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? (
                                    <>{CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺</>
                                ) : (
                                    <>
                                        {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺
                                        -{" "}
                                        {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price)}₺
                                    </>
                                )}
                            </>
                        ) : (
                            <>{CurrencyFormat(totalAmount)}₺</>
                        )}
          </span>
                </div>
            </div>

            <div className="row">
                <div className="d-flex py-2 pt-0 w-100 fw-bold fs-6">
                    {betSlipLegs
                        ?.map((l: any, k: number) => {
                            return simpleView(l) === "" ? "-" : simpleView(l);
                        })
                        .join(" / ")}
                </div>
                {/*{betSlipLegs.map((l: any, k: number) => {*/}
                {/*    return (<div key={`keyMobileHorse-${k}`} onClick={() => {*/}
                {/*        selectBettingColumn(k + 1);*/}
                {/*    }} style={{width: `calc(100% / ${betSlipLegs.length})`}} className={`col ps-1 pe-1 py-2`}>*/}
                {/*        <span>{k + 1}.{tjkBetSlipOptions.bettingType.columnLabel}</span>*/}
                {/*        <span className="horses">{simpleView(l)}</span>*/}
                {/*    </div>)*/}
                {/*})}*/}
            </div>
        </div>
    );
}


export function FobBetslip(prop: any) {
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();

    useEffect(() => {
        let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
            forceUpdate();
            // console.log(tjkBetSlipOptions);
        });
        return () => {
            betSlipActionSubject_subscription.unsubscribe();
            clearTimeout(tjkBetslipTimer);
        };
    }, []);

    let runsMap: any = {};
    let runsList: any = [];
    let horsesMap: any = {};

    runsList = tjkBetSlipOptions.hippodrome.runs.filter((run: any) =>
        tjkBetSlipOptions.bettingSubType.label.split("-").includes(String(run.no))
    );
    for (let run of runsList) {
        runsMap[Number(run.no)] = run;
    }

    if (tjkBetSlipOptions.bettingSubType === null || tjkBetSlipOptions.hippodrome === null) {
        return <></>;
    }

    let tabs: any = [];
    for (
        let i = Number(tjkBetSlipOptions.bettingSubType.id);
        i < Number(tjkBetSlipOptions.bettingSubType.id) + tjkBetSlipOptions.bettingType.column;
        i += 1
    ) {
        tabs.push(i);
    }

    const runs = tjkBetSlipOptions.hippodrome.runs.filter((run: any) => tabs.includes(Number(run.no)));


    if (!tjkCompleteBettingTypes.includes(tjkBetSlipOptions.bettingType.id)) {
        tjkBetSlipOptions.complete = false;
    }

    let betCount = getBetCount();

    let totalAmount = Number(
        (Number(tjkBetSlipOptions.betSlipMultiply) * betCount * tjkBetSlipOptions.bettingType.price).toFixed(2)
    );

    let betSlipMaxMultiply =
        betCount > 0
            ? Math.floor(tjkBetSlipOptions.bettingType.maxCouponAmount / betCount)
            : tjkBetSlipOptions.bettingType.maxCouponAmount;

    let multiplyChoices: any = [];
    if (betSlipMaxMultiply > 200) {
        multiplyChoices = _.range(1, 101);
        let mod = betSlipMaxMultiply % 50;
        multiplyChoices.push(..._.range(200, betSlipMaxMultiply, 50));
        if (mod > 0) {
            multiplyChoices.push(multiplyChoices[multiplyChoices.length - 1] + mod);
        }
    } else {
        multiplyChoices = _.range(1, betSlipMaxMultiply + 1);
    }

    if (totalAmount > tjkBetSlipOptions.bettingType.maxCouponAmount) {
        tjkBetSlipOptions.errorMessage =
            "Toplam bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + " TL'yi geçemez.";
        totalAmount = tjkBetSlipOptions.betSlipMultiply * betCount * tjkBetSlipOptions.bettingType.price;
    } else if (
        tjkBetSlipOptions.errorMessage ===
        "Toplam bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.maxCouponAmount) + " TL'yi geçemez."
    ) {
        tjkBetSlipOptions.errorMessage = null;
    }

    const playCoupon = (save: boolean = false, imgData: any = '') => {
        if (!tjkBetSlipOptions.inProgress) {
            tjkBetSlipOptions.inProgress = true;
            forceUpdate();
            if (!auth.member || !auth.member.id) {
                modalServiceSubject.next({
                    title: "Üye Girişi",
                    content: `<p>Bahis yapabilmeniz için önce oturum açmanız gerekli. Üye değilseniz hemen üye olabilir yada giriş yaparak bahis yapabilirsiniz.</p>`,
                    confirm: {
                        sure: {
                            label: "Giriş Yap",
                            class: "btn btn-success",
                            action: () => {
                                navigate(paths.login.url);
                            },
                        },
                        cancel: {
                            label: "Üye Ol",
                            class: "btn btn-primary",
                            action: () => {
                                navigate(paths.register.url);
                            },
                        },
                    },
                });
                return false;
            }

            PlayTjkCoupon(save, imgData).then((result: any) => {
                if (result) {
                    if (result.status === true && save === false) {
                        try {
                            // @ts-ignore
                            fbq("track", "Purchase", {value: totalAmount, currency: "TRY"});
                        } catch (e) {
                        }
                        clearTjkBetSlip();
                        modalServiceSubject.next({
                            case: "info",
                            title: "Kuponunuz Oynandı",
                            // content: "Kuponunuz başarıyla oynandı. Kuponunuzun durumunu kuponlarım sayfasından takip edebilirsiniz."
                            content: `<p class="text-center"><b>${
                                result.data.coupon_data.approve_code
                            }</b> kodu ile <b>${CurrencyFormat(Number(result.data.coupon_data.amount || 0))} ₺</b> tutarındaki <b>${
                                BettingTypesMap[Number(result.data.coupon_data.bet_type)]?.name
                            }</b> kuponunuz başarıyla oynandı. <br/>Kuponunuzun durumunu kuponlarım sayfasından takip edebilirsiniz.</p>`,
                            confirm: {
                                sure: {
                                    label: "TJK Kuponlarım",
                                    action: () => {
                                        navigate(paths.memberTickets.url);
                                    },
                                },
                                cancel: {
                                    label: "Yeni Kupon Oyna",
                                    action: () => {
                                        if (isMobile) {
                                            mobileBetSlipActions.isOpen = false;
                                            mobileBetSlipSubject.next("update");
                                        }
                                    },
                                },
                            },
                        });
                    } else if (result.status && save === true) {
                        modalServiceSubject.next({
                            case: "info",
                            title: "Kuponunuz Kaydedildi",
                            content: "Kuponunuz başarıyla Kaydedildi. Kuponunuzu kuponlarım sayfasında görüntüleyebilirsiniz",
                            confirm: {
                                sure: {
                                    label: "Kayıtlı Kuponlarım",
                                    action: () => {
                                        navigate(paths.memberSavedTickets.url);
                                    },
                                },
                                cancel: {
                                    label: "Yeni Kupon Oyna",
                                    action: () => {
                                        if (isMobile) {
                                            mobileBetSlipActions.isOpen = false;
                                            mobileBetSlipSubject.next("update");
                                        }
                                    },
                                },
                            },
                        });
                    } else {
                        // tjkBetSlipOptions.errorMessage = typeof(result.message) === 'object' ? JSON.stringify(result.message): result.message;

                        let error_description: any = null;
                        let error_message: any = null;
                        if (result.data && result.data.error) {
                            error_description = result.data.error.error_description;
                            error_message = result.data.error.error_message;
                        }

                        let sure: any = null;
                        if (typeof result.message === "string" && result.message.indexOf("Oturum") !== -1) {
                            sure = {
                                label: "Giriş Yap",
                                action: () => {
                                    navigate(paths.login.url);
                                },
                            };
                        }
                        if (error_message === "TIMEOUT") {
                            sure = {
                                label: "TJK Kuponlarım",
                                action: () => {
                                    navigate(paths.memberTickets.url);
                                },
                            };
                        }

                        modalServiceSubject.next({
                            case: "danger",
                            title: "Hata",
                            content:
                                error_description ||
                                (typeof result.message === "object" ? JSON.stringify(result.message) : result.message),
                            confirm: {
                                sure: sure,
                                cancel: {
                                    label: "Tamam",
                                },
                            },
                        });
                        forceUpdate();
                    }
                }
                tjkBetSlipOptions.inProgress = false;
                forceUpdate();
            });
            forceUpdate();
        }
    };

    const confirmCoupon = async () => {
        clearTimeout(tjkBetslipTimer);
        let imgData = '';
        try {

            const domElement: any = document.getElementById("legs-row");
            // @ts-ignore
            await html2canvas(domElement, {
                width: domElement.clientWidth,
                height: domElement.clientHeight,
                scale: 0.7,
            }).then((canvas: any) => {
                imgData = canvas.toDataURL("image/jpeg");
            });
        } catch (e) {
            console.log('imgData not Found ', e)
        }
        // console.log( tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price);
        if (auth.authentication && auth.member && auth.member.id) {
            if (betCount === 0) {
                tjkBetSlipOptions.errorMessage = "Kuponunuz da oynanacak bir yarış bulunmamaktadır.";
            } else if ((tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) > 6500) {
                tjkBetSlipOptions.errorMessage = "Kupon tutarı 6500 TL'den fazla olamaz.";
            } else if (
                tjkBetSlipOptions.shareCoupon !== true &&
                (tjkBetSlipOptions.usePointBalance
                    ? Number(auth.member.balance_debit) + auth.member.balance_point
                    : auth.member.balance_debit) < (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount)
            ) {
                tjkBetSlipOptions.errorMessage = "Bakiyeniz yetersiz.";
            } else if (
                (tjkBetSlipOptions.system
                    ? tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price
                    : totalAmount) < tjkBetSlipOptions.bettingType.minCouponAmount
            ) {
                tjkBetSlipOptions.errorMessage =
                    "Minumum bahis tutarı " + CurrencyFormat(tjkBetSlipOptions.bettingType.minCouponAmount) + " TL olmalıdır.";
            }
            if (tjkBetSlipOptions.errorMessage === null) {
                if (isMobile) {
                    playCoupon(false, imgData);
                } else {
                    let slipItem = tjkBetslipStore.filter((el: any) => {
                        if (
                            el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
                            el.bettingType === Number(tjkBetSlipOptions.bettingType.id) &&
                            el.bettingSubType === Number(tjkBetSlipOptions.bettingSubType.id)
                        ) {
                            return el;
                        }
                    });
                    let uniqueColumn = [...Array.from(new Set(slipItem.map((i: any) => i.column)))].sort();

                    let columnRunMap: any = {};
                    for (let col of uniqueColumn) {
                        columnRunMap[Number(col)] = slipItem.find((r: any) => r.column === col);
                    }

                    for (let run of runs) {
                        for (let horse of run.horses) {
                            if (!horsesMap[Number(run.no)]) {
                                horsesMap[Number(run.no)] = {};
                            }
                            horsesMap[Number(run.no)][Number(horse.no)] = horse;
                        }
                    }

                    modalServiceSubject.next({
                        title: `BAHİS DETAYI - ${tjkBetSlipOptions.hippodrome.location} - (${dateTimeStringFormat(
                            tjkBetSlipOptions.hippodrome.date,
                            true
                        )})`,
                        width: "960px",
                        content: `
        <table class="table table-striped coupon-detail">
            <tbody>
            
                <tr>
                    <th class="text-left" style="width: 100px">Hipodrom</th>
                    <td class="text-left" style="width: calc(15% - 120px )">${
                            tjkBetSlipOptions.hippodrome.location
                        }</td>
                    <th class="text-left" style="width: 100px">Bahis Türü</th>
                    <td class="text-left" style="width: calc(25% - 120px )"> ${tjkBetSlipOptions.bettingType.name}</td>
                    ${!isMobile ? `<th className="text-left" style={{width: "100px"}}>Koşu</th>` : ""} 
                    ${
                            !isMobile
                                ? `<td className="text-left"
                                       style={{width: "calc(25% - 120px )"}}>${tjkBetSlipOptions.bettingSubType.label}</td>`
                                : ""
                        } 
                    
                </tr>
                
                <tr>
                    <th class="text-left" style="width: 100px">Bahis Sayısı</th>
                    <td class="text-left" style="width: calc(15% - 120px )">
                    
                    ${
                            tjkBetSlipOptions.system &&
                            tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                                ? tjkBetSlipOptions.systemMaxMin.join("-")
                                : ""
                        }
                    
                    ${
                            tjkBetSlipOptions.system &&
                            tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1]
                                ? tjkBetSlipOptions.systemMaxMin[0]
                                : ""
                        }
                    
                    ${!tjkBetSlipOptions.system ? betCount : ""}
                    </td>
                    <th class="text-left" style="width: 100px">Misli</th>
                    <td class="text-left" style="width: calc(25% - 120px )">${tjkBetSlipOptions.betSlipMultiply}</td>
                    ${!isMobile ? `<th className="text-left" style={{width: "100px"}}>Tutar</th>` : ""}
                   ${
                            !isMobile
                                ? `<td className="text-left" style={{width: "calc(25% - 120px )"}}>
                    
                    ${
                                    tjkBetSlipOptions.system &&
                                    tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                                        ? CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) +
                                        "₺ - " +
                                        CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price) +
                                        "₺"
                                        : tjkBetSlipOptions.system
                                            ? CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price) + "₺"
                                            : CurrencyFormat(totalAmount) + "₺"
                                }
                    
                  
                                        
                    </td> `
                                : ""
                        }
                </tr>
                ${
                            isMobile
                                ? ` <tr>
                      <th className="text-left" style={{width: "100px"}}>Koşu</th>
                      <td className="text-left"
                          style={{width: "calc(25% - 120px )"}}>${tjkBetSlipOptions.bettingSubType.label}</td>
                      <th className="text-left" style={{width: "100px"}}>Tutar</th>
                      <td className="text-left" style={{width: "calc(25% - 120px )"}}>

                          ${
                                    tjkBetSlipOptions.system &&
                                    tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                                        ? CurrencyFormat(
                                            tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price
                                        ) +
                                        "₺ - " +
                                        CurrencyFormat(
                                            tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price
                                        ) +
                                        "₺"
                                        : tjkBetSlipOptions.system
                                            ? CurrencyFormat(
                                            tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price
                                        ) + "₺"
                                            : CurrencyFormat(totalAmount) + "₺"
                                }


                      </td>
                  </tr>`
                                : ""
                        }
                
            </tbody>
        </table>
        
        <div class="coupon-legs" style="width:auto; margin-top: 15px;">
          ${uniqueColumn
                            .map((e: any) => {
                                return `<div class="col-12" style="width: ${
                                    isMobile ? "" : `calc(100% / ${uniqueColumn.length})`
                                } ;flex: unset;max-width: unset;">
            <div class="run-no">${
                                    tjkBetSlipOptions.bettingType.columnLabel === "Koşu" ? tjkBetSlipOptions.bettingSubType.id : e
                                }. ${tjkBetSlipOptions.bettingType.columnLabel.toUpperCase()}</div>
            ${_.orderBy(slipItem, [
                                    (el: any) => {
                                        if (el.horse === "s") {
                                            return 0;
                                        } else {
                                            return 1;
                                        }
                                    },
                                ])
                                    .filter((h: any) => h.column === e)
                                    .map((item: any, key: number) => {
                                        return `<div class="horse-no d-flex">
                <div class="text-center ${
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no1 === 1 ||
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no2 === 1
                                                ? "agf-horse border-0"
                                                : ""
                                        }" style=" font-weight: bold">${
                                            tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id)
                                                ? key + 1 + ".At"
                                                : item.horse === "s"
                                                    ? "S"
                                                    : horsesMap[columnRunMap[e].run][Number(item.horse)].no
                                        }</div>
                <div class="text-left text-wrap ps-2 ${
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no1 === 1 ||
                                            horsesMap[columnRunMap[e].run][Number(item.horse)]?.agf_sort_no2 === 1
                                                ? "agf-horse border-0"
                                                : ""
                                        }" style="">
                  ${tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? item.horse : ""} ${
                                            item.horse === "s" ? "HAZIR BAHİS" : horsesMap[columnRunMap[e].run][Number(item.horse)].name
                                        }
                </div>
              </div>`;
                                    })
                                    .join("")}
          </div>`;
                            })
                            .join("")}
        </div>
      `,
                        confirm: {
                            sure: {
                                class: "btn-success",
                                label: "Onayla",
                                action: () => {
                                    playCoupon(false, imgData);
                                },
                            },
                            cancel: {
                                label: "Vazgeç",
                            },
                        },
                    });
                }
            }
            forceUpdate();

            tjkBetslipTimer = setTimeout(() => {
                tjkBetSlipOptions.errorMessage = null;
                forceUpdate();
            }, 5000);
        } else {
            navigate(`${paths.login.url}/${window.location.pathname.replaceAll("/", "|")}`);
        }
    };

    if (tjkBetSlipOptions.bettingType.id === 0) {
        return <></>;
    }

    let slipItem = tjkBetslipStore.filter((el: any) => el.bettingType === 1881);

    return (
        <>
            <div className="betslip" id={"betslip"}>
                {isMobile && (
                    <button
                        className="btn btn-primary btn-icon float-start py-1 px-1 me-2"
                        onClick={() => {
                            // setMobileBetslip(false)
                            mobileBetSlipActions.isOpen = false;
                            mobileBetSlipSubject.next("update");
                        }}
                    >
                        <img alt={"left"} src={iconLeft} className="invert-color me-1"/>
                    </button>
                )}
                <h2 className="betting-type">
                    {tjkBetSlipOptions.bettingType.name}
                    <img alt={"kapat"} src={iconClose} className="float-end" onClick={() => {
                        prop.setToggleBetslip(false);
                    }}/>

                </h2>
                <div className="body betslip-body flex-column" id={"betslip-body"}>
                    {slipItem.map((item: any, index: any) => {

                        var hippodrome = availableFobHippodromes.find((el: any) => Number(el.id) === item.hippodrome);
                        if (hippodrome) {
                            return <div className={"card fob-slip-item mt-2"} key={"foc-slip-item" + index}>
                                <div className={"card-body"}>
                                    <div className={"d-flex justify-content-between"}>
                                        <div className={"d-flex justify-content-between flex-column"}>
                                            <div className={"d-flex align-items-center"}>
                                                <div>{hippodrome.hippodrome} {item.run}.
                                                    Koşu {hippodrome.runsMap[Number(item.run)].time}</div>

                                            </div>
                                            <div className={"d-flex justify-content-between align-items-center"}>
                                                <div>{hippodrome.runsMap[Number(item.run)].horseMap[item.horse].name}</div>
                                            </div>


                                        </div>
                                        <div className={"odd-button selected"}>{hippodrome.runsMap[Number(item.run)].event_odds[Number(item.horse)].win_odds}</div>
                                    </div>



                                </div>
                            </div>
                        }
                    })}
                </div>

                <div className="footer">


                    {!tjkBetSlipOptions.system && (
                        <div>
                            <label>Misli:</label>
                            <div className={"betslip-misli"}>
                                <input
                                    type="number"
                                    className={"betslip-input"}
                                    style={{textAlign: "left"}}
                                    pattern="^[0-9\b]+$"
                                    min={1}
                                    max={betSlipMaxMultiply}
                                    step={1}
                                    value={tjkBetSlipOptions.betSlipMultiply || ""}
                                    onKeyDown={preventBetSlipValues}
                                    onChange={(e) => setTjkBetSlipMultiply(e.target.value)}
                                    onBlur={() => {
                                        if (tjkBetSlipOptions.betSlipMultiply === "") {
                                            setTjkBetSlipMultiply(1);
                                        }
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                />
                                <select
                                    className="betslip-select"
                                    value={tjkBetSlipOptions.betSlipMultiply}
                                    onChange={(e) => setTjkBetSlipMultiply(Number(e.target.value))}
                                >
                                    {multiplyChoices.map((el: number, key: number) => {
                                        return (
                                            <option key={key} value={el}>
                                                {el}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    )}

                    <div>
                        <label>Bahis:</label>
                        <span>
              {tjkBetSlipOptions.system ? (
                  <>
                      {tjkBetSlipOptions.systemMaxMin[0] !== tjkBetSlipOptions.systemMaxMin[1]
                          ? tjkBetSlipOptions.systemMaxMin.join("-")
                          : tjkBetSlipOptions.systemMaxMin[0]}
                  </>
              ) : (
                  <>{betCount}</>
              )}
            </span>
                    </div>

                    <div>
                        <label>Tutar:</label>
                        <span>
              {tjkBetSlipOptions.system ? (
                  <>
                      {tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? (
                          <>{CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺</>
                      ) : (
                          <>
                              {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price)}₺
                              -{" "}
                              {CurrencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price)}₺
                          </>
                      )}
                  </>
              ) : (
                  <>{CurrencyFormat(totalAmount)}₺</>
              )}
            </span>
                    </div>


                    {auth.authentication && auth.member && auth.member.id ? (
                        <>
                            <div>
                                <label>Puan Kullan:</label>
                                <span>
                  <input
                      type="checkbox"
                      checked={tjkBetSlipOptions.usePointBalance}
                      disabled={auth.member ? auth.member.balance_point <= 0 : true}
                      onChange={() => {
                          setTjkUsePointBalance(!tjkBetSlipOptions.usePointBalance);
                      }}
                  />{" "}
                                    {auth.member ? auth.member.balance_point + "₺" : "0.00₺"}
                </span>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {tjkBetSlipOptions.errorMessage ? (
                        <div className="mt-1 mb-1">
                            <div className="w-100 p-2 alert alert-danger m-0">{tjkBetSlipOptions.errorMessage}</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {auth.authentication && auth.member && auth.member.is_editor === true && (
                        <>
                            <div>
                                <label>Paylaş:</label>
                                <span>
                  <input
                      type="checkbox"
                      checked={tjkBetSlipOptions.shareCoupon}
                      onChange={() => {
                          setTjkShareCoupon(!tjkBetSlipOptions.shareCoupon);
                      }}
                  />
                </span>
                            </div>
                        </>
                    )}

                    {tjkBetSlipOptions.inProgress ? (
                        <>
                            <div className="actions">
                                <button className="button-clear" disabled>
                                    <img src={iconTrash} alt=""/>
                                </button>
                                <button disabled className="button-save">
                                    <img src={iconSave} alt=""/>
                                </button>
                                <button className="button-play" disabled>
                                    Hemen Oyna
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="actions">
                                <button className="button-clear">
                                    <img
                                        src={iconTrash}
                                        onClick={() => {
                                            clearTjkFobBetSlip();
                                        }}
                                        alt=""
                                    />
                                </button>

                                <button
                                    onClick={() => {
                                        playCoupon(true);
                                    }}
                                    className="button-save"
                                >
                                    <img src={iconSave} alt=""/>
                                </button>

                                {!tjkBetSlipOptions.shareCoupon && tjkBetSlipOptions.hippodrome.status && (
                                    <>
                                        <button
                                            disabled={
                                                tjkBetSlipOptions.inProgress ||
                                                tjkBetSlipOptions.errorMessage ||
                                                (tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] === 0) ||
                                                betCount === 0
                                                    ? true
                                                    : false
                                            }
                                            className={`button-play`}
                                            onClick={() => {
                                                confirmCoupon();
                                            }}
                                        >
                                            Hemen Oyna
                                        </button>
                                    </>
                                )}

                                {!tjkBetSlipOptions.hippodrome.status && (
                                    <button className="button-play" disabled>
                                        Bahisler Açılmadı
                                    </button>
                                )}

                                {tjkBetSlipOptions.shareCoupon && (
                                    <button
                                        disabled={
                                            tjkBetSlipOptions.errorMessage ||
                                            (tjkBetSlipOptions.system && tjkBetSlipOptions.systemMaxMin[0] === 0) ||
                                            betCount === 0
                                                ? true
                                                : false
                                        }
                                        className={`button-play share`}
                                        onClick={() => {
                                            confirmCoupon();
                                        }}
                                    >
                                        Hemen Paylaş
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

        </>
    );
}
