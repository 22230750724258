import axios, {Method} from 'axios';
import {Storage} from "../services/localstorege.service";
import {loginModalSubject} from "../store/auth.store";
import {userLogout} from "./auth.service";

type IBody = object | string | null

const isSuccessCode = (statusCode: number) => {
    return statusCode >= 200 && statusCode < 300
}

export class ApiService {

    private headers: any = {
        'Content-Type': 'application/json; charset=utf-8'
    }
    private baseUrl = ''

    public setBaseUrl = (url: string) => {
        this.baseUrl = url
    }

    public setHeaders(headers: object) {
        //console.log('request header =>', headers)
        this.headers = headers
    }

    public getHeaders(): object {
        return this.headers
    }

    public async start(
        method: string,
        url: string,
        body: IBody | any,
        authorization: boolean,
        timeout: number = 30000,
    ) {

        if (authorization) {
            const token = Storage.get('token')
            if (token) {
                this.headers.Authorization = `Bearer ${token}`
            }
        } else {
            delete this.headers['Authorization']
        }
        try {

            if (Storage.get('utm_source')) {
                body['utm_source'] = Storage.get('utm_source');
            }
            if (Storage.get('utm_medium')) {
                body['utm_medium'] = Storage.get('utm_medium');
            }
            if (Storage.get('utm_campaign')) {
                body['utm_campaign'] = Storage.get('utm_campaign');
            }
            if (Storage.get('utm_content')) {
                body['utm_content'] = Storage.get('utm_content');
            }
        } catch (error) {
            // console.log(error);
        }


        return new Promise((resolve, reject) => {

            // console.log(this.headers)

            // @ts-ignore-start
            axios({
                method: method as Method,
                baseURL: this.baseUrl,
                url,
                headers: this.headers,
                data: body,
                timeout: 30000
            }).then((response: any) => {
                if (isSuccessCode(response.status)) {

                    if (response.data?.error?.includes('invalid_credentials_m')) {
                        console.log("Oturumunuz sona erdi. Lütfen tekrar giriş yapınız.")
                        userLogout();
                        loginModalSubject.next(false)
                    }

                    return resolve(response.data)
                }
                return reject({
                    error: {message: 'Teknik hata', ...response.data},
                })
            }).catch((error: any) => {
                return reject(error)
            })
        })
    }
}
