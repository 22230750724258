import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {useEffect, useState} from "react";
import {arrayMoveImmutable} from "array-move";
import {
  addToTJKBetSlip,
  betSlipActionSubject,
  removeFromBetSlip,
  tjkBetSlipOptions,
  tjkBetslipStore
} from "../../services/betslip.service";
import iconTrash from "../../assets/feather/trash-2.svg";
import {useForceUpdate} from "../../services/misc.functions";
import {DailyRaces} from "../../store/bulletin.store";

let races:any = [];
let horses:any = [];
let horsesMap:any = {};
let run:any = {};

const DragHandle = SortableHandle(({children}:any)=> {
  return children;
})

const SortableItem = SortableElement(({value}:any) => <li>
  <DragHandle>
    <span>{value.no} - {value.name}</span>
    {value.odd && <div>{value.odd.win_odds || "-"}</div>}
  </DragHandle>
  <img alt={"sil"} src={iconTrash} width={14} onClick={() => {
    removeFromBetSlip(1, run, value);
  }} className="cursor-pointer float-right"/>
</li>);

const SortableList = SortableContainer(({items}:any) => {
  // console.log(horsesMap)
  return (
    <ul className="sortable-horses">
      {items.map((value:any, index:any) => (
        // @ts-ignore
        <SortableItem key={`item-${index}`} index={index} value={horsesMap[Number(value.horse)]} />
      ))}
    </ul>
  );
});


export function SortMyHorse(props:any){
  const [itemOrder, setItemOrder] = useState<any>([]);

  useEffect(() => {
    let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res:any) => {
      if (tjkBetSlipOptions.bettingType) {
        setItemOrder([...tjkBetslipStore.filter((el: any) => el.bettingType === tjkBetSlipOptions.bettingType.id
            && el.bettingSubType === tjkBetSlipOptions.bettingSubType.id
            && el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id))]);
      }
    });
    return () => {
      betSlipActionSubject_subscription.unsubscribe();
    }
  }, []);

  if(DailyRaces.length > 0 && races.length === 0) {
    races = DailyRaces.find((h: any) => Number(h.id) === Number(tjkBetSlipOptions.hippodrome.id)).runs;
    run = races.find((r: any) => Number(r.no) === Number(tjkBetSlipOptions.bettingSubType.id));
    horses = run.horses;
    for (let horse of horses) {

      horse['odd'] = null;
      if(run.event_odds){
        horse.odd = run.event_odds[Number(horse.no)];
      }
      horsesMap[Number(horse.no)] = horse;
    }
  }



  useEffect(() => {

    races = DailyRaces.find((h: any) => Number(h.id) === Number(tjkBetSlipOptions.hippodrome.id)).runs;
    run = races.find((r: any) => Number(r.no) === Number(tjkBetSlipOptions.bettingSubType.id));
    horses = run.horses;
    for (let horse of horses) {
      horsesMap[Number(horse.no)] = horse;
    }

    setItemOrder([...tjkBetslipStore.filter((el:any)=>el.bettingType === tjkBetSlipOptions.bettingType.id
      && el.bettingSubType === tjkBetSlipOptions.bettingSubType.id
      && el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id))]);
  }, [tjkBetSlipOptions.bettingSubType, tjkBetSlipOptions.bettingType, tjkBetSlipOptions.hippodrome]);

  const onSortEnd = (e: any) => {
    const {oldIndex, newIndex} = e;
    let newArray:any = arrayMoveImmutable(itemOrder, oldIndex, newIndex)
    for (let item of newArray) {
      let exIndex = tjkBetslipStore.findIndex(
        (el: any) => el.bettingType === tjkBetSlipOptions.bettingType.id &&
          el.bettingSubType === tjkBetSlipOptions.bettingSubType.id &&
          el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
          Number(el.horse) === Number(item.horse)
      )
      // console.log(exIndex, tjkBetslipStore[exIndex] , newArray.indexOf(item))
      Object.assign(tjkBetslipStore, arrayMoveImmutable(tjkBetslipStore, exIndex, newArray.indexOf(item)))
    }
    setItemOrder(newArray);

    props.parentUpdate()
  };

  const addFromSelect = (e:any)=>{
    addToTJKBetSlip(
      1,
      run,
      run.horses.find((h: any) => Number(h.no) === Number(e)),
    )

    let i = tjkBetslipStore.findIndex(
      (el: any) => el.bettingType === tjkBetSlipOptions.bettingType.id &&
        el.bettingSubType === tjkBetSlipOptions.bettingSubType.id &&
        el.hippodrome === Number(tjkBetSlipOptions.hippodrome.id) &&
        Number(el.horse) === Number(e)
    )

    if (i !== -1) {
      itemOrder.splice(i, 1);
    } else {
      itemOrder.push(tjkBetslipStore[i]);
    }
  }

  // @ts-ignore
  return (<><HorseSelectDropDown items={itemOrder} addFromSelect={addFromSelect} /><SortableList items={itemOrder} useDragHandle={true} helperClass={"sortable-horse-helper"}  onSortEnd={onSortEnd} /></>)
}

function HorseSelectDropDown(prop:any){

  const items = prop.items;
  const addFromSelect = prop.addFromSelect;

  return (<select className="w-100 mb-2" defaultValue={"-1"} onChange={(e: any) => {
    if (e.target.value !== '-1') {
      addFromSelect(e.target.value);
      e.target.value = '-1';
    }
  }}>
    <option value='-1'>At Seçiniz</option>
    {horses.filter((h: any) => h.run_status === true && ![...items.map((i:any)=>String(i.horse))].includes(String(h.no))).map((horse: any, key: number) => {
      return (<option key={`horse-select-${key}`}
                      value={horse.no}>{horse.no} - {horse.name}</option>)
    })}
  </select>)
}
