import {useNavigate, useParams} from "react-router";
import { flatPages} from "../../constants/global.vars";
import paths from "../../paths";
import React, {useEffect} from "react";
import { flatPageSubject} from "../../store/misc.store";
import {useForceUpdate} from "../../services/misc.functions";
import "./flatpage.css";
import {Link, useLocation} from "react-router-dom";

export function Flatpage(){

  const param = useParams();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();


  useEffect(()=>{
    let agreementSubject_subsciber = flatPageSubject.subscribe(()=>{
      forceUpdate();
    });
    return ()=>{
      agreementSubject_subsciber.unsubscribe();
    }

  },[])


  // console.log(param.slug , flatPages)

  if(!param.slug || !flatPages[param.slug]){
    setTimeout(()=>{
      navigate(paths.home.url);
    },100)
    return <></>
  }

  const  content = flatPages[param.slug]

  return (<>
    <div className="page-widget dark p-4">
      <div className="row">
        <div className="col-12">
          <h1>{content.title}</h1>
          <div className="page-widget agreement-page">
            <div className="p-3">
              <p dangerouslySetInnerHTML={{__html:content.content.replace(/\n\r/g, "<br />").replace(/\r\n/g, "<br />")}}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
