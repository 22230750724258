import "./draw.detail.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// import iconTimer from "../../assets/icons/timer.png";
import iconBackArrow from "../../../assets/feather/x-circle.svg";
// import iconClose from "../../assets/icons/feather/x-circle.svg";
import Modal from "react-modal";
import { dateFormat, gtmViewItem, slugify, useForceUpdate } from "../../../services/misc.functions";
import { RaffleModel } from "../../../models/raffle.model";
import { cartStore, cartToggleSubject, raffleDraws, raffleDrawSubject } from "../../../store/raffle.store";
import { isMobile } from "../../../App";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import paths from "../../../paths";
import { addToCart, removeFromCart, updateCart } from "../../../services/raffle.service";
import { passwordModalStyles } from "../../../constants/global.vars";

let sidebar: any = null;
let detailBackdrop: any = null;
let ticket: RaffleModel;
let prevUrl: string = "";
let prevTitle: string = "";
export function DrawDetail() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let drawPath = String(pathname);
  const [showDetail, setShowDetail] = useState<any>(null);
  const forceUpdate = useForceUpdate();
  const [hidden, setHidden] = useState<boolean>(true);

  if (showDetail) {
    ticket = showDetail;
  }

  useEffect(() => {
    let raffleDrawSubject_subscriber = raffleDrawSubject.subscribe((res: any) => {
      // console.log(res);
      sidebar = document.getElementById("id_detail_wrapper");
      if (res && res.action === "open") {
        let draw: any = raffleDraws.find((el: RaffleModel) => el.id === res.id);
        if (draw) {
          openDetail(draw);
        }
      } else if (res && res.action === "history") {
        openDetail(res.draw);
      } else {
        closeDetail();
      }
    });
    return () => {
      raffleDrawSubject_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let cartToggleSubject_subscriber = cartToggleSubject.subscribe((res: any) => {
      if (
        (res && res.item && res.item.item && res.item.item.id === ticket?.id) ||
        (res.list && res.list.includes(ticket?.id))
      ) {
        forceUpdate();
      }
    });
    return () => {
      cartToggleSubject_subscriber.unsubscribe();
    };
  }, []);

  const openDetail = (draw: RaffleModel) => {
    // console.log("openDetail", draw)
    // if(sidebar){
    //   sidebar.style.bottom = "0";
    //   sidebar.style.visibility = "visible";
    //
    // }
    // detailBackdrop = document.getElementById("detail-backdrop");
    // if (detailBackdrop) {
    //   detailBackdrop.classList.add('show');
    // }
    prevUrl = window.location.pathname;
    prevTitle = document.title;
    document.title = draw.title_abbreviated;
    window.history.pushState(null, "", "/esya-piyangosu/" + draw.id + "/" + slugify(draw.name) + "/?n=" + prevUrl);
    setShowDetail(draw);
  };
  const closeDetail = () => {
    // if(sidebar){
    //   sidebar.style.bottom = "-600px";
    //   sidebar.style.visibility = "hidden";
    // }

    if (showDetail) {
      document.title = prevTitle;
      window.history.pushState(null, "", prevUrl);
    }
    setShowDetail(null);
  };

  if (showDetail) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <Modal
      isOpen={showDetail !== null}
      id={"id_detail_wrapper"}
      className={"default draw-detail-modal"}
      style={passwordModalStyles}
      onRequestClose={() => {
        closeDetail();
      }}
      onAfterOpen={() => {
        gtmViewItem([ticket], "Çekiliş");
      }}
      contentLabel=""
    >
      {/* {!isMobile && (
        <div className={"modal-close"}>
          <img src={iconClose} width={24} className={"float-right cursor-p"} onClick={() => closeDetail()} />
        </div>
      )} */}

      <div className={"draw-detail-wrapper"}>
        <img
          className={`${showDetail ? "btnBackFixed" : ""} btnBack`}
          src={iconBackArrow}
          onClick={() => {
            closeDetail();
          }}
        />

        {showDetail && (
          <>
            <div className="body">
              <div className="photos">
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  autoplay={{ delay: 3000 }}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <img src={ticket.detail_photo || ticket.photo} />
                  </SwiperSlide>
                </Swiper>
              </div>

              <div className="timer">
                {/* <img src={iconTimer} /> */}
                {/* <span>{ticket.leftDays()} gün</span> */}
              </div>

              <h2 className="draw-title">{ticket.title_abbreviated}</h2>
              <p className="draw-name mb-2 mt-2">{ticket.name}</p>

              <div className="flags mb-4">
                {ticket.is_new && (
                  <span className="px-2 ms-0 translate-middle text-white rounded-pill bg-primary">Yeni</span>
                )}
                {ticket.is_popular && (
                  <span className="px-2 translate-middle text-white rounded-pill bg-warning">Çok Satan</span>
                )}
                {ticket.second_hand && (
                  <span className="px-2 translate-middle text-white rounded-pill bg-secondary">2.El</span>
                )}
                {ticket.is_running_out && (
                  <span className="px-2 translate-middle text-white rounded-pill bg-success">Kuponlu</span>
                )}
              </div>

              <div
                className="desciption"
                dangerouslySetInnerHTML={{ __html: ticket.detail.replaceAll("\n", "<br/>") }}
              ></div>

              <div className="footer">
                <div className="cutEffect">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div
                  className={
                    drawPath !== paths.raffleTickets && !isMobile
                      ? "row product-foot"
                      : "row product-foot column-gap-xxl-5"
                  }
                >
                  {!isMobile && (
                    <div className="col-5">
                      <div className="detail">
                        <div className="fw-medium">
                          Çekiliş Tarihi: <span className="text-dark fw-medium">{dateFormat(ticket.draw_date)}</span>
                        </div>
                        <div className="fw-medium">
                          Toplam Bilet: <span className="text-dark fw-medium">{ticket.total_tickets} adet</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={isMobile ? "col-12 p-3" : "col-4"}>
                    {ticket.is_added && (
                      <div className={!isMobile ? "add" : "add"}>
                        <div className="input w-60">
                          <i
                            onClick={() => {
                              if (
                                cartStore.items[ticket.cartIndex] &&
                                cartStore.items[ticket.cartIndex]?.quantity > 0
                              ) {
                                updateCart(
                                  cartStore.items[ticket.cartIndex],
                                  ticket.cartIndex,
                                  Number(cartStore.items[ticket.cartIndex]?.quantity) - 1
                                );
                              }
                            }}
                          >
                            -
                          </i>
                          <input
                            className="no-arrows"
                            type="number"
                            value={cartStore.items[ticket.cartIndex]?.quantity || ""}
                            onChange={(e: any) => {
                              updateCart(cartStore.items[ticket.cartIndex], ticket.cartIndex, e.target.value);
                            }}
                            onBlur={(e: any) => {
                              if (e.target.value === "" || e.target.value === "0") {
                                removeFromCart(cartStore.items[ticket.cartIndex], ticket.cartIndex);
                              }
                            }}
                            max={100}
                            step={1}
                            min={1}
                          />
                          <i
                            onClick={() => {
                              if (
                                cartStore.items[ticket.cartIndex] &&
                                cartStore.items[ticket.cartIndex]?.quantity < 100 &&
                                cartStore.items[ticket.cartIndex]?.quantity < ticket.remaining_tickets
                              ) {
                                updateCart(
                                  cartStore.items[ticket.cartIndex],
                                  ticket.cartIndex,
                                  Number(cartStore.items[ticket.cartIndex].quantity) + 1
                                );
                              }
                            }}
                          >
                            +
                          </i>
                        </div>
                        <div className="price w-40">
                          <span>{Number(ticket.ticket_price).toFixed(2)}₺</span>
                        </div>
                      </div>
                    )}

                    {ticket.is_added !== true && ticket.remaining_tickets > 0 && (
                      <button
                        onClick={() => {
                          addToCart(ticket, 1, "raffle");
                        }}
                        disabled={![true, 1].includes(ticket.status)}
                        className={"btn btn-add-to-cart w-100 rounded-pill text-white"}
                      >
                        {ticket.status === true || ticket.status === 1 ? <>Bilet Al</> : <>Çekilişe Kapalı</>}
                        <span className="price">{Number(ticket.ticket_price).toFixed(2)}₺</span>
                      </button>
                    )}

                    {ticket.remaining_tickets === 0 && (
                      <button className="btn fw-bold w-100 text-center btn-secondary rounded-pill">Tükendi</button>
                    )}
                  </div>
                  {!isMobile && (
                    <div className="col-3">
                      {drawPath !== paths.raffleTickets && !isMobile && (
                        <button
                          onClick={() => {
                            closeDetail();
                            cartToggleSubject.next({
                              action: "visibleToggleCart",
                              show: hidden,
                            });
                          }}
                          className="mx-1 px-2 text-white basket-btn bg-warning"
                        >
                          Sepete Git
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
