import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {env, passwordModalStyles} from "../../constants/global.vars";
import Modal from "react-modal"
import {ApiService} from "../../services/http.service";
import {modalServiceSubject} from "../../services/modal.service";
import paths from "../../paths";
import InputMask from "react-input-mask";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import EyeIcon from "../../assets/feather/eye.svg";
import {exceptionModal} from "../../services/misc.functions";

let s: any;
let resendButton: any;
export default function ForgetPassword(props: any) {
    const [step, setStep] = useState<any>(1);
    const [code, setCode] = useState<any>('');
    const [showSelection, setShowSelection] = useState<any>(null);
    const [selectedType, setSelectedType] = useState<any>('');
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [userId, setUserId] = useState<any>(null)
    const passwordModal = props.passwordModal;
    const closePasswordModal = props.closePasswordModal;
    let api = new ApiService();

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm<any>()

    const onSubmit: SubmitHandler<any> = data => {
        api
            .start(
                "post",
                env.accounting_api + "/member/api/member-contact-information/",
                data,
                false
            )
            .then((res: any) => {
                if (res.status) {
                    setShowSelection(res.data);
                    setStep(2)
                } else {
                    modalServiceSubject.next({
                        case: "danger",
                        title: "Hata!",
                        content: `<p>${res.message}</p>`,
                        timeout: 5000,
                        confirm: {
                            cancel: {
                                class: "btn btn-danger",
                                label: "Kapat",

                            },
                        },
                    });
                }
                // console.log(res)
            })
            .catch((e: any) => {
                // console.log(e)
            });

    }

    const senderTimer = (s: any = null) => {
        let resendTimer: any = document.getElementById("id_resendTimer");
        if (resendTimer) {
            let secondLeft: number = Number(
                Number(resendTimer.innerHTML.split(":")[0]) * 60 +
                Number(resendTimer.innerHTML.split(":")[1]) -
                1
            );
            if (secondLeft < 0) {
                clearInterval(s);
                resendButton = document.getElementById("id_resendCode");
                if (resendButton) {
                    resendButton.classList.remove("disabled");
                }
            } else {
                resendTimer.innerHTML =
                    Math.floor(secondLeft / 60) +
                    ":" +
                    ("00" + (secondLeft % 60)).slice(-2);
            }
        }
    };

    const callChangePasswordApi = (type:any=null,data:any) => {

        var payload = {
            return_address: `${window.location.protocol}//${window.location.host}${paths.resetPassword.url}`,
            user_id: showSelection.user_id,
            messenger_type: type ?? selectedType,
        };

        api.start('post', env.accounting_api + '/member/api/password-reminder/', payload, false)
            .then((res: any) => {
                if (res.status) {
                    setStep(3);
                    s = setInterval(senderTimer, 1000);
                    if (data.resend) {
                        if (resendButton) {
                            resendButton.classList.add("disabled");
                        }
                        let resendTimer: any =
                            document.getElementById("id_resendTimer");
                        if (resendTimer) {
                            resendTimer.innerHTML = "2:00";
                        }
                    }
                } else {
                    modalServiceSubject.next({title: 'Hata!', content: `<p>${res.message}</p>`, timeout: 5000})
                }
                // console.log(res)
            })
            .catch((e: any) => {
                exceptionModal(e);
            })
    }

    const onSubmitCode: SubmitHandler<any> = (data) => {
        data.code = data.code.replaceAll(' ', '');

        api
            .start(
                "post",
                env.accounting_api + "/member/api/password-code-validate/",
                data,
                false
            )
            .then((res: any) => {
                if (res.status) {
                    setCode(data.code);
                    setStep(4);

                } else {
                    modalServiceSubject.next({
                        case: "danger",
                        title: "Hata!",
                        content: `<p>${res.message}</p>`,
                        timeout: 5000,
                        confirm: {
                            cancel: {
                                class: "btn btn-danger",
                                label: "Kapat",
                            },
                        },
                    });
                }

            })
            .catch((e: any) => {
                exceptionModal(e);
            });

    };

    const onSubmitPassword: SubmitHandler<any> = (data) => {
        data.code = code.replaceAll(' ', '');
        api
            .start(
                "post",
                env.accounting_api + "/member/api/password-renew-with-code/",
                data,
                false
            )
            .then((res: any) => {
                if (res.status) {
                    modalServiceSubject.next({
                        case: "success",
                        title: "İşleminiz Başarılı",
                        content: `<p>${res.message}</p>`,
                        timeout: 5000,
                        confirm: {
                            cancel: {
                                class: "btn btn-success",
                                label: "Kapat",

                            },
                        },
                    });
                    closePasswordModal();
                } else {
                    setErrorMessage(res.message);
                }

            })
            .catch((e: any) => {
                exceptionModal(e);
            });

    };

    const isPasswordVisible = () => {
        let password: any = document.getElementById("id_password");
        let eyeIcon: any = document.getElementById("eye-icon");

        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        } else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };

    const isPasswordVisible2 = () => {
        let password: any = document.getElementById("id_password2");
        let eyeIcon: any = document.getElementById("eye-icon2");

        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        } else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };

    return (
        <>
            <Modal
                isOpen={passwordModal}
                onRequestClose={() => {
                    try {
                        clearInterval(s);
                    } catch (e) {
                    }
                    setStep(1);
                    reset({email: "", code: "", password: "", password2: ""});
                    closePasswordModal();
                }}
                style={passwordModalStyles}
                contentLabel="">


                {step === 1 &&
                    <div className="forget-password-modal">
                        <h2>Şifremi Unuttum</h2>
                        <p>
                            TC Kimlik numaranızı girin. Kayıtlarımız ile eşleşir ise,
                            size doğrulma kodu göndereceğiz.
                        </p>
                        <div>
                            <label>TCKN </label>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input className="form-control" {...register('tc_id', {
                                    required: true,
                                    minLength: 5,
                                })}
                                       placeholder={"Hesabınıza kayıtlı TCKN"}/>
                                <div>
                                    {errors.email && errors.email.type === "required" &&
                                        <span className="text-validation"> TC No gereklidir.</span>}
                                    {errors.email && errors.email.type === "minLength" &&
                                        <span className="text-validation"> TC No hatalı</span>}
                                </div>
                                <div className={"d-flex justify-content-between align-items-center mt-2"}>
                                    <div>
                                        <button
                                            type={"button"}
                                            onClick={() => {
                                                closePasswordModal();
                                            }}
                                            className="btn btn-warning">Vazgeç
                                        </button>
                                    </div>
                                    <div><input className="btn btn-primary" value={"Gönder"} type="submit"/></div>

                                </div>
                            </form>
                        </div>


                    </div>}
                {step === 2 &&
                    <div className="forget-password-modal">
                        <>
                            <p>Şifre Sıfırlama linkini gönderim yöntemini seçiniz</p>
                            {showSelection.mobile && (
                                <div
                                    className={"card forget-password-card pointer"}
                                    onClick={() => {
                                        setSelectedType('sms');
                                        callChangePasswordApi('sms',{'mobile': userId, "resend": true});
                                    }}
                                >
                                    <div className={"card-body"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <h3>Telefon İle sıfırlama</h3>
                                                <p className={"m-0"}>
                                                    {showSelection.mobile} numaralı telefonunuza sıfırlama
                                                    linki gönderilecektir
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showSelection.email && (
                                <div
                                    className={"card forget-password-card"}
                                    onClick={() => {
                                        setSelectedType('mail');
                                        callChangePasswordApi('mail',{'email': userId, "resend": true})
                                    }}
                                >
                                    <div className={"card-body"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <h3>E-posta İle sıfırlama</h3>
                                                <p className={"m-0"}>
                                                    {showSelection.email} e-posta adresinize sıfırlama
                                                    linki gönderilecektir
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                }
                {step === 3 &&
                    <div className="forget-password-modal">
                        <h2>Şifremi Unuttum</h2>
                        <p>
                            SMS olarak gelen 6 haneli doğrulma kodunu giriniz
                        </p>

                        <form onSubmit={handleSubmit(onSubmitCode)}>
                            <label>Doğrulama Kodu</label>


                            <InputMask
                                mask="999 999"
                                className="form-control password-code-input"
                                placeholder={"_ _ _ _ _ _"}
                                {...register<any>("code", {required: true})}
                            />

                            <div className={"d-flex justify-content-between align-items-center mt-2"}>
                                <div style={{flex: 1}}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            clearInterval(s);
                                            callChangePasswordApi( selectedType,{'email': userId, "resend": true});

                                        }}
                                        style={{
                                            fontSize: 13,
                                        }}
                                        className="btn disabled btn-warning"
                                        id="id_resendCode"
                                    >
                                        Tekrar Gönder
                                    </button>
                                </div>
                                <div
                                    style={{flex: 1}}
                                    id="emailHelp"
                                    className="form-text fs-5 text-center"
                                >
                                    <span id="id_resendTimer">3:00</span>
                                </div>
                                <div style={{flex: 1}} className={"text-end"}>
                                    <button type={"submit"}
                                        className="btn btn-primary">Gönder
                                    </button>
                                </div>
                            </div>


                        </form>
                    </div>
                }
                {step === 4 &&
                    <div className="forget-password-modal">
                        <h2>Şifre Değiştir</h2>

                        <div>
                            <p>Şifreniz en az 8 karakterden oluşmalı ve aşağıdaki öğeleri içermelidir:</p>
                            <ul>
                                <li>En az bir büyük harf ve bir küçük harf</li>
                                <li>En az bir rakam ve bir özel karakter (!, @, #, vb.)</li>
                            </ul>
                        </div>

                        <form onSubmit={handleSubmit(onSubmitPassword)}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label htmlFor="id_password" className="form-label">
                                        Şifre (Tekrar)
                                    </label>
                                    <input
                                        type="password"

                                        id="id_password"
                                        {...register("password", {required: true})}
                                    />
                                    <div className="cursor-pointer eye-icon float-right mr-2"
                                         onClick={() => isPasswordVisible()}>
                                        <img alt={"eye"} id="eye-icon" className="eye-icon" height={16} width={16}
                                             src={EyeOffIcon}/>
                                    </div>
                                </div>

                            </div>
                            <div className={"row mt-3"}>
                                <div className={"col-12"}>
                                    <label htmlFor="id_password2" className="form-label">
                                        Şifre (Tekrar)
                                    </label>
                                    <input
                                        type="password"

                                        id="id_password2"
                                        {...register("password2", {required: true})}
                                    />
                                    <div className="cursor-pointer eye-icon float-right mr-2"
                                         onClick={() => isPasswordVisible2()}>
                                        <img alt={"eye"} id="eye-icon2" className="eye-icon" height={16} width={16}
                                             src={EyeOffIcon}/>
                                    </div>
                                </div>

                            </div>
                            {errorMessage && <div className="position-relative">
                                <p className="my-2 alert alert-danger">
                                    {errorMessage}
                                </p>
                            </div>}
                            <div className={"d-flex mt-3"}>
                                <button
                                    className="btn btn-primary float-right  flex-fill"
                                >Gönder
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </Modal>
        </>
    )
}
