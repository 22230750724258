import "./member.css";
import { auth, authSubject } from "../../store/auth.store";
import { SubmitHandler, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { ApiService } from "../../services/http.service";
import {env, professions, regions} from "../../constants/global.vars";
import { toast } from "react-toastify";
import { wssProvider } from "../../services/websocket.service";
import refreshIcon from "../../assets/icons/refresh.svg";
import successIcon from "../../assets/icons/success.svg";
import closedIcon from "../../assets/icons/closed.svg";
import React, { useEffect, useState } from "react";
import { sendEmailValidationLink, sendValidationCode } from "../../services/auth.service";
import { isMobile } from "../../App";
import { dateFormat, useForceUpdate } from "../../services/misc.functions";

let s: any;
let resendButton: any;

export function MemberInfo() {
  const [showValidateCodeInput, setShowValidateCodeInput] = useState<any>(false);
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    let auth_subscriber = authSubject.subscribe((res: any) => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  const removeClasses = (ref: any) => {
    ref.classList.remove("border-success");
    ref.classList.remove("bg-success-subtle");
    ref.classList.remove("border-danger");
    ref.classList.remove("bg-danger-subtle");
    ref.classList.remove("border-warning");
    ref.classList.remove("bg-warning-subtle");
  };

  const mobileChangeHandler = (e: any) => {
    if (e.target.value !== auth.member.mobile) {
      removeClasses(e.target);
      e.target.classList.add("border-warning");
      e.target.classList.add("bg-warning-subtle");
    } else if (auth.member.mobile_validation === true) {
      removeClasses(e.target);
      e.target.classList.add("border-success");
      e.target.classList.add("bg-success-subtle");
    } else if (auth.member.mobile_validation !== true) {
      removeClasses(e.target);
      e.target.classList.add("border-danger");
      e.target.classList.add("bg-danger-subtle");
    }
  };

  const senderTimer = (s: any = null) => {
    let resendTimer: any = document.getElementById("id_resendTimer");
    if (resendTimer) {
      let secondLeft: number = Number(
        Number(resendTimer.innerHTML.split(":")[0]) * 60 + Number(resendTimer.innerHTML.split(":")[1]) - 1
      );
      if (secondLeft < 0) {
        clearInterval(s);
        resendButton = document.getElementById("id_resendCode");
        if (resendButton) {
          resendButton.classList.remove("hide");
        }
      } else {
        resendTimer.innerHTML = Math.floor(secondLeft / 60) + ":" + ("00" + (secondLeft % 60)).slice(-2);
      }
    }
  };

  const updateNotificationSettings = (payload: any, obj: any) => {
    // for (const [k, v] of Object.entries(payload)) {
    //   auth.member[k] = v;
    // }
    // forceUpdate();
    obj.target.disabled = true;
    const api = new ApiService();
    api
      .start("post", env.accounting_api + "/member/api/update-account-info/", payload, true)
      .then((res: any) => {
        wssProvider.checkauth();
        forceUpdate();
      })
      .finally(() => {
        obj.target.disabled = false;
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    // console.log(payload);
    const api = new ApiService();
    payload["mobile"] = payload.mobile.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");

    api
      .start("post", env.accounting_api + "/member/api/update-account-info/", payload, true)
      .then((res: any) => {
        if (res && res.status) {
          // console.log(res);
          setShowValidateCodeInput(false);
          wssProvider.checkauth();
          toast.success("Hesap bilgileriniz güncellendi.");
        } else if (res && res.error && res.error === "sms_validate") {
          console.log("sms_validate");
          s = setInterval(senderTimer, 1000);
          setShowValidateCodeInput(true);
        } else if (res && res.error && res.error === "mail_validation") {
          console.log("mail_validate");
        } else {
          toast.error(res.message);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <>
      <h1>Üyelik Bilgilerim</h1>
      <div className="page-widget">
        <form className="member-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row flex-wrap align-items-center">
            <label
              className={`text-nowrap text-nowrap form-label col-lg-3 col-sm-12  ${
                isMobile ? "text-start" : "text-end"
              }`}
            >
              ÜYE NUMARASI
            </label>
            <input
              className="col-lg-3 col-sm-12"
              type="text"
              placeholder="ÜYE NUMARASI"
              {...register("id")}
              value={auth.member.id}
              disabled
            />
          </div>

          <div className="form-row flex-wrap align-items-center ">
            <label
              className={`text-nowrap text-nowrap form-label col-lg-3 col-sm-12  ${
                isMobile ? "text-start" : "text-end"
              }`}
            >
              İSİM SOYİSİM
            </label>
            <input
              className="col-lg-3 col-sm-12"
              type="text"
              placeholder="AD"
              {...register("first_name")}
              value={`${auth.member.first_name} ${auth.member.last_name}`}
              disabled
            />
          </div>

          <div className="form-row flex-wrap align-items-center">
            <label
              className={`text-nowrap text-nowrap form-label col-lg-3 col-sm-12  ${
                isMobile ? "text-start" : "text-end"
              }`}
            >
              DOĞUM TARİHİ
            </label>
            <input
              className="col-lg-3 col-sm-12"
              type="text"
              placeholder="DOĞUM TARİHİ"
              {...register("birthday")}
              value={dateFormat(auth.member.birthday).replaceAll("/", ".")}
              disabled
            />
          </div>

          <div className="form-row row flex-wrap align-items-center">
            <label
              className={`text-nowrap text-nowrap form-label col-lg-3 col-sm-12  ${
                isMobile ? "text-start" : "text-end"
              }`}
            >
              GSM NO
            </label>

            <div className={`${isMobile ? "w-100" : "w-25"} px-0`}>
              <div
                className={`sideInput  align-items-center m-0 p-0 form-control ${
                  auth.member.mobile_validation === true ? "" : "bg-danger-subtle border-danger"
                }`}
              >
                <InputMask
                  style={{ border: "none", fontSize: 12 }}
                  defaultValue={auth.member.mobile}
                  mask="+\90 (599) 999 99 99"
                  onChangeCapture={mobileChangeHandler}
                  placeholder={"(___) ___ __ __"}
                  id="id_mobile"
                  {...register<any>("mobile", { required: true })}
                />

                {auth.member.mobile_validation === true ? (
                  <img alt={"onayli"} src={successIcon} width={15} height={15} />
                ) : (
                  <></>
                )}
              </div>
              {errors.mobile && (
                <p className="invalid-feedback text-nowrap position-absolute fs-7">Lütfen telefon numarası giriniz.</p>
              )}
            </div>

            {showValidateCodeInput ? (
              <div className="d-flex flex-row col-lg-4 col-sm-8 px-0 px-lg-1 gap-1 mt-2 mt-lg-0">
                <input
                  className={`col-6 form-control ${errors.sms_verify_code ? "border-danger" : ""}`}
                  placeholder="ONAY KODU"
                  {...register<any>("sms_verify_code", { required: true })}
                  id="id_sms_verify_code"
                />

                <button className="btn btn-warning">ONAYLA</button>

                <button
                  className="btn hide btn-sm position-absolute btn-warning"
                  id="id_resendCode"
                  onClick={() => {
                    clearInterval(s);
                    sendValidationCode(null);
                    if (resendButton) {
                      resendButton.classList.add("hide");
                    }
                    let resendTimer: any = document.getElementById("id_resendTimer");
                    if (resendTimer) {
                      resendTimer.innerHTML = "2:00";
                    }
                    s = setInterval(senderTimer, 1000);
                  }}
                >
                  <img alt={"yenile"} src={refreshIcon} />
                </button>

                <button
                  className="btn"
                  onClick={() => {
                    setShowValidateCodeInput(false);
                    setValue("mobile", auth.member.mobile);
                    removeClasses(document.getElementById("id_mobile"));
                  }}
                >
                  <img alt={"kapat"} src={closedIcon} width={20} height={20} />
                </button>
              </div>
            ) : (
              <div className="col-4"></div>
            )}
          </div>

          <div className="form-row flex-wrap align-items-center">
            <label
              className={`text-nowrap text-nowrap form-label col-12 col-lg-3   ${isMobile ? "text-start" : "text-end"}`}
            >
              E-POSTA
            </label>

            <div className={`${isMobile ? "w-100" : "w-25"} px-0`}>
              <div className={`sideInput col-12`}>
                <input
                  className={`${auth.member.email_validation ? "" : "bg-danger-subtle border-danger"}`}
                  style={{ fontSize: 12, border: "none" }}
                  type="text"
                  placeholder="E-POSTA"
                  {...register("email", { required: true })}
                  defaultValue={auth.member.email}
                />

                {auth.member.email_validation === true ? (
                  <img alt={"onayli"} src={successIcon} width={16} height={16} />
                ) : (
                  <></>
                )}
              </div>

              {errors.mail && <p className="invalid-feedback">Lütfen e-posta adresi giriniz.</p>}
            </div>

            {!auth.member.email_validation && (
              <div className={"col-3 col-lg-2 mt-2 mt-lg-0 px-0 px-lg-1"}>
                <button
                  type={"button"}
                  onClick={() => {
                    sendEmailValidationLink();
                  }}
                  className="btn btn-warning w-100"
                >
                  ONAYLA
                </button>
              </div>
            )}
          </div>

          <div className={`flex-wrap align-items-center ${isMobile ? "mb-3" : "form-row"}`}>
            <label
              className={`text-nowrap text-nowrap form-label col-lg-3 col-sm-12  ${
                isMobile ? "text-start" : "text-end"
              }`}
            >
              İL
            </label>
            <div className={"col-lg-3 col-sm-12 p-0"}>
              <select className="form-select w-100" defaultValue={auth.member?.city} {...register("city")}>
                <option value="">İl Seçiniz</option>
                {Array.from(new Set(regions.map((region) => region.state))).map((city) => {
                  return (
                    <option value={city} key={`city-${city}`}>
                      {city}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className={`flex-wrap align-items-center ${isMobile ? "mb-3" : "form-row"}`}>
            <label
                className={`text-nowrap text-nowrap form-label col-lg-3 col-sm-12  ${
                    isMobile ? "text-start" : "text-end"
                }`}
            >
              MESLEK
            </label>
            <div className={"col-lg-3 col-sm-12 p-0"}>
              <select className="form-select w-100" defaultValue={auth.member?.profession ? auth.member?.profession.toString() : 'DEFAULT'} {...register("profession")}>
                <option value='DEFAULT' disabled>MESLEK BİLGİSİ</option>
                {professions.map((x: any) => {
                  return (
                      <option value={x.id.toString()}
                              key={x.name}>{x.name}</option>)
                })}
              </select>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-3"}></div>
            <div className={"col-6"}>
              <button className="btn btn-warning w-100" type="submit">
                Kaydet
              </button>
            </div>
          </div>

          {/* <div className="form-row w-100 align-items-center">
        <label className="text-nowrap form-label">İLÇE</label>
        <select className="form-select">
          {regions.map((region) => {
            return <option>{region.name}</option>;
          })}
        </select>
      </div> */}
          {/*

            <h1 className={"text-reset ps-0 my-3"}>Bildirim Tercihlerim</h1>
            <div className="row px-2">
                <div className="form-check form-switch notification-item">
                    <label
                        className="form-check-label"
                        htmlFor="id_switch_mail_notification"
                    >
                        <h4>E-Posta</h4>
                        <p className="white-space-normal">
                            noreply@4nala.com.tr adresinden gelmeyen e-postalara itibar
                            etmeyiniz.
                        </p>

                    </label>
                    <input
                        checked={auth.member.mail_notification}
                        className="form-check-input float-end"
                        type="checkbox"
                        onChange={(e: any) => {
                            updateNotificationSettings(
                                {mail_notification: !auth.member.mail_notification},
                                e
                            );
                        }}
                        role="switch"
                        id="id_switch_mail_notification"
                    />
                </div>
            </div>
            <div className={"row px-2 mt-2"}>
                <div className="form-check form-switch notification-item">
                    <label
                        className="form-check-label"
                        htmlFor="id_switch_sms_notification"
                    >
                        <h3>SMS</h3>
                        <p className="white-space-normal">
                            4Nala ile gelmeyen SMS'lere itibar etmeyiniz!
                        </p>

                    </label>
                    <input
                        checked={auth.member.sms_notification}
                        className="form-check-input float-end"
                        type="checkbox"
                        onChange={(e: any) => {
                            updateNotificationSettings(
                                {sms_notification: !auth.member.sms_notification},
                                e
                            );
                        }}
                        role="switch"
                        id="id_switch_sms_notification"
                    />
                </div>
            </div>
            <div className={"row px-2 mt-2"}>
                <div className="form-check form-switch notification-item">
                    <label
                        className="form-check-label"
                        htmlFor="is_switch_call_notification"
                    >
                        <h3>Müşteri Hizmetleri</h3>
                        <p className="white-space-normal">
                            0850 123 45 67 telefon numarasından gelmeyen aramalara itibar
                            etmeyiniz.
                        </p>

                    </label>
                    <input
                        checked={auth.member.call_notification}
                        className="form-check-input float-end mt-3"
                        type="checkbox"
                        onChange={(e: any) => {
                            updateNotificationSettings(
                                {call_notification: !auth.member.call_notification},
                                e
                            );
                        }}
                        role="switch"
                        id="is_switch_call_notification"
                    />
                </div>
            </div>


*/}
        </form>
        {/*  <div className={"row p-3"}>
              <div className={"col-12"}>
                  <Link className={"btn btn-danger w-100 "} to={paths.support.url + '/cozum-merkezi'}>
                      HESABIMI KAPAT
                  </Link>
              </div>
          </div>*/}
      </div>
    </>
  );
}
