export interface LoginRequest {
  user: string
  password: string,
  option:any,
  code:any,
}

export interface ForgetPasswordRequest {
  email: string,
}


export class Member {
  public address: string | any;
  public birthday: string | any;
  public city: string | any;
  public county: string | any;
  public create_date: string | any;
  public date_joined: string | any;
  public balance_debit: string | any;
  public balance_debit_available: string | any;
  public display_label: string | any;
  public email: string | any;
  public father_name: string | any;
  public favorite_team_id: string | any;
  public first_name: string | any;
  public gender: string | any;
  public get_gender_display: string | any;
  public get_status_display: string | any;
  public groups: string | any;
  public id: string | any;
  public id_no: string | any;
  public is_editor: boolean | any;
  public is_tipster: boolean | any;
  public id_serial: string | any;
  public is_active: string | any;
  public is_partner: string | any;
  public is_staff: boolean | any;
  public is_superuser: boolean | any;
  public last_login: string | any;
  public last_name: string | any;
  public mail_notification: boolean | any;
  public mobile: string | any;
  public mother_name: string | any;
  public nick_name: string | any;
  public balance_point: string | any;
  public push_notification: string | any;
  public call_notification: string | any;
  public referer: string | any;
  public secure_answer: string | any;
  public secure_question: string | any;
  public sms_notification: boolean | any;
  public status: string | any;
  public subscribed: string | any;
  public tc_id: string | any;
  public update_date: string | any;
  public username: string | any;
  public profession: string | any;
  live_draw_notification: boolean | any;
  game_start_notification: boolean | any;
  new_draw_notification: boolean | any;
  new_game_notification: boolean | any;
  satisfaction_survey_notification: boolean | any;

  public max_rank: any;
  email_validation:any;
  mobile_validation:any;
  campaign_agreement:any;
  notification_count:any;
  password:any;
  get_full_name:any;
  available_balance:any;


  constructor(obj:any) {
    this.address = obj.address;
    this.birthday = obj.birthday;
    this.city = obj.city;
    this.county = obj.county;
    this.create_date = obj.create_date;
    this.date_joined = obj.date_joined;
    this.balance_debit = obj.balance_debit;
    this.balance_debit_available = obj.balance_debit_available;
    this.display_label = obj.display_label;
    this.email = obj.email;
    this.father_name = obj.father_name;
    this.favorite_team_id = obj.favorite_team_id;
    this.first_name = obj.first_name;
    this.gender = obj.gender;
    this.get_gender_display = obj.get_gender_display;
    this.get_status_display = obj.get_status_display;
    this.groups = obj.groups;
    this.id = obj.id;
    this.id_no = obj.id_no;
    this.is_editor = obj.is_editor;
    this.is_tipster = obj.is_tipster;
    this.id_serial = obj.id_serial;
    this.is_active = obj.is_active;
    this.is_partner = obj.is_partner;
    this.is_staff = obj.is_staff;
    this.is_superuser = obj.is_superuser;
    this.last_login = obj.last_login;
    this.last_name = obj.last_name;
    this.mail_notification = obj.mail_notification;
    this.mobile = obj.mobile;
    this.mother_name = obj.mother_name;
    this.nick_name = obj.nick_name;
    this.balance_point = obj.balance_point;
    this.push_notification = obj.push_notification;
    this.call_notification = obj.call_notification;
    this.referer = obj.referer;
    this.secure_answer = obj.secure_answer;
    this.secure_question = obj.secure_question;
    this.sms_notification = obj.sms_notification;
    this.status = obj.status;
    this.subscribed = obj.subscribed;
    this.tc_id = obj.tc_id;
    this.update_date = obj.update_date;
    this.username = obj.username;
    this.profession = obj.profession;
    // this.profession = null;

    this.max_rank = obj.max_rank;
    this.email_validation = obj.email_validation;
    this.mobile_validation = obj.mobile_validation;

    this.campaign_agreement = obj.campaign_agreement;
    this.game_start_notification = obj.game_start_notification;
    this.live_draw_notification = obj.live_draw_notification;
    this.new_draw_notification = obj.new_draw_notification;
    this.new_game_notification = obj.new_game_notification;
    this.satisfaction_survey_notification = obj.satisfaction_survey_notification;

    this.notification_count = obj.notication_count;
    this.password = obj.password;
  }

  public getFullName() {
    return this.first_name + " " + this.last_name;
  }

  public getAvailableBalance() {
    return Number(this.balance_debit) + (Number(this.balance_point) );
  }

}
