import "./home.css";
import tjkTv from "../../assets/img/tjktv.png";
import iconCampaign from "../../assets/feather/campaign.svg";
import iconChanges from "../../assets/icons/changes_icon.svg";
import iconDeposit from "../../assets/icons/deposit_icon.svg";
import iconWithdraw from "../../assets/icons/withdraw_icon.svg";
import iconReport from "../../assets/icons/report_icon.svg";
import iconInfo from "../../assets/icons/info.svg";
import chevronRight from "../../assets/feather/chevron-right.svg";

import Tabs from "./tabs";
import {Link} from "react-router-dom";
import paths from "../../paths";
import {homeHippodrome, homeHippodromeSubject} from "../../store/misc.store";
import {useForceUpdate} from "../../services/misc.functions";
import React, {useEffect} from "react";
import {VerifyEmail} from "./verify.email";
import {ResetPassword} from "./resetpassword";
import {isMobile} from "../../App";

import HomeShortcuts from "./home.shortcuts";
import {JockeyStatus} from "./jockey.status";
import HomeRaffles from "./home.raffles";
import {Slider} from "../../shared/slider/slider";
import {HippodromeWidget} from "../../shared/hippodrome/hippodrome";
import redHorse from "../../assets/img/kirmiziat.png";
import blackHorse from "../../assets/img/siyahat.png";
import {availableHippodromes} from "../../store/bulletin.store";
import {betSlipActionSubject, tjkBetSlipOptions} from "../../services/betslip.service";
import {bulletinProvider} from "../../services/bulletin.service";
import {useNavigate} from "react-router";

export function Home() {
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();

    useEffect(() => {
        let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
            forceUpdate();
        });
        return () => {
            homeHippodromeSubject_subscription.unsubscribe();
        };
    }, []);

    return (
        <>
            <Slider/>
            <HippodromeWidget/>
            {!isMobile && !homeHippodrome.keyword && (
                <div className="row home-shortcuts">
                    <div className="col-lg-3">
                        <div className="item skeleton"></div>
                    </div>
                    <div className="col-lg-3">
                        <div className="item skeleton"></div>
                    </div>
                    <div className="col-lg-3">
                        <div className="item skeleton"></div>
                    </div>
                    <div className="col-lg-3">
                        <div className="item skeleton"></div>
                    </div>
                </div>
            )}

            <HomeShortcuts/>

            {isMobile && (
                <Link to={paths.betting.url} className="btn btn-sm w-100 fw-semibold fs-5 rounded-4 py-2 btn-warning">
                    BAHİS YAP
                </Link>
              /*  <div className={"row"}>
                    <div className={"col-6"}>
                        <Link to={paths.betting.url} className="btn btn-sm w-100  rounded-4 py-2 btn-warning">
                            <div className={"d-flex  align-items-center"}>
                                <img
                                    className={"me-2"}
                                    alt={"iconFather"}
                                    src={redHorse}
                                    height={35}
                                />
                                <div className={"d-flex justify-content-center align-items-baseline flex-column fs-6"}>
                                    <span className={"lh-sm"}>Müşterek Oranlı</span>
                                    <span className={"lh-sm fw-bold"}>BAHİS YAP</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={"col-6"}>
                        <Link to={paths.fobBetting.url} className="btn btn-sm w-100  rounded-4 py-2 btn-warning position-relative border-0">
                            <div className="ribbon ribbon-home">
                                <span>YENİ</span>
                            </div>
                            <div className={"d-flex  align-items-center"}>
                                <img
                                    className={"me-2"}
                                    alt={"iconFather"}
                                    src={blackHorse}
                                    height={35}
                                />
                                <div className={"d-flex justify-content-center align-items-baseline flex-column fs-6"}>
                                    <span className={"lh-sm"}>Sabit Oranlı</span>
                                    <span className={"lh-sm fw-bold"}>BAHİS YAP</span>
                                </div>

                            </div>

                        </Link>
                    </div>
                </div>*/


            )}

            <div className="row">
                {!isMobile && (
                    <div className={"col-lg-12"}>
                        <Tabs/>
                    </div>
                )}
                {isMobile && <HomeRaffles/>}
                {!isMobile && (
                    <div className="col-lg-9">
                        <div className="page-widget p-3 mt-3 mb-3">
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <JockeyStatus isAll={false}/>
                                </div>
                                {/*<div className={"col-lg-4"}>*/}
                                {/*    <NotRunningHorses/>*/}
                                {/*</div>*/}
                                {/*<div className={"col-lg-8"}>*/}
                                {/*    <ChangedJokeys/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                )}
                {!isMobile && (
                    <div className="col-lg-3">
                        {/*    <div className="page-widget">
                    <h2>CANLI YAYINLAR</h2>
                    <div className="body pb-3">
                        <Link to={`${paths.stream.url}/0`} className="btn btn-danger mb-1 fw-medium w-100 btn-icon">
                            <img src={tjkTv}/>
                            TJK TV Canlı İzle
                        </Link>
                        <Link to={`${paths.stream.url}/2`} className="btn btn-danger mb-1 fw-medium w-100 btn-icon">
                            <img src={iconStream}/>
                            TAY TV Canlı İzle
                        </Link>
                        <Link to={`${paths.stream.url}/1`} className="btn btn-danger mb-1 fw-medium w-100 btn-icon">
                            <img src={tjkTv} alt=""/>
                            TJK TV Canlı İzle (Yurt dışı)
                        </Link>
                    </div>
                </div>*/}
                        <div className="page-widget mt-3">
                            <h2 style={{marginBottom: "4px"}}>4NALA LİNKLER</h2>
                            <div className="body pb-3">
                                <Link to={`${paths.campaigns.url}`}
                                      className="btn btn-info fw-medium w-100 btn-icon mb-1 py-3">
                                    <img src={iconCampaign} alt=""/>
                                    KAMPANYALAR
                                </Link>
                                <Link to={`${paths.deposit.url}`}
                                      className="btn btn-info  fw-medium w-100 btn-icon mb-1 py-3">
                                    <img src={iconDeposit} alt=""/>
                                    PARA YATIR
                                </Link>
                                <Link to={`${paths.withdraw.url}`}
                                      className="btn btn-info  fw-medium w-100 btn-icon mb-1 py-3">
                                    <img src={iconWithdraw} alt=""/>
                                    PARA ÇEK
                                </Link>
                                <Link
                                    to={`${paths.support.url}/cozum-merkezi`}
                                    className="btn btn-info  fw-medium w-100 btn-icon mb-1 py-3"
                                >
                                    <img src={iconInfo} alt="-"/>
                                    YARDIM
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {isMobile && (
                <>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"home-link"}>
                                <Link className={"align-items-center"} to={`${paths.horseStats.url}`}>
                                    <img alt={"KOŞMAYAN ATLAR / JOKEY DEĞİŞİKLİĞİ"} src={iconChanges}/>
                                    KOŞMAYAN ATLAR / JOKEY DEĞİŞİKLİĞİ
                                </Link>
                                <div>
                                    <img alt={"git"} src={chevronRight}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <div className={"home-link"}>
                                <Link className={"align-items-center"} to={`${paths.reportedJokeys.url}`}>
                                    <img alt={"RAPORLU / CEZALI JOKEYLER"} src={iconReport}/>
                                    RAPORLU / CEZALI JOKEYLER
                                </Link>
                                <div>
                                    <img alt={"git"} src={chevronRight}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <div className={"home-link"}>
                                <Link className={"align-items-center"} to={`${paths.stream.url}/0`}>
                                    <img alt={"tjk tv"} src={tjkTv}/>
                                    TJK TV İZLE
                                </Link>
                                <div>
                                    <img alt={"git"} src={chevronRight}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <div className={"home-link"}>
                                <Link className={"align-items-center"} to={`${paths.deposit.url}`}>
                                    <img alt={"para yatır"} src={iconDeposit}/>
                                    PARA YATIR
                                </Link>
                                <div>
                                    <img alt={"git"} src={chevronRight}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <div className={"home-link"}>
                                <Link className={"align-items-center"} to={`${paths.withdraw.url}`}>
                                    <img alt={"para çek"} src={iconWithdraw}/>
                                    PARA ÇEK
                                </Link>
                                <div>
                                    <img alt={"git"} src={chevronRight}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <ResetPassword></ResetPassword>
            <VerifyEmail></VerifyEmail>
        </>
    );
}
