import "./register.css";
import {Home} from "../home/home";
import React, {useEffect, useState} from "react";
import {
    backDrop,
    ServerDate,
    showAgreementModal,
    showSelfieExample,
    showSelfieRules,
    useForceUpdate,
    GetCookies
} from "../../services/misc.functions";
import {SubmitHandler, useForm} from "react-hook-form";
import {ApiService} from "../../services/http.service";
import {env, nationalities, professions, redirectionErrorCodes} from "../../constants/global.vars";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import paths from "../../paths";
import backgroundImage from "../../assets/img/riders.jpeg";
import xIcon from "../../assets/feather/x.svg";
import {modalServiceSubject} from "../../services/modal.service";
import {userLogin, userValidation} from "../../services/auth.service";
import {auth, authSubject} from "../../store/auth.store";
import ReactInputMask from "react-input-mask";
import EyeIcon from "../../assets/feather/eye.svg";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import SelfieExample from "../../assets/img/kyc_example.jpeg";
import {isMobile} from "../../App";

let validationControl: any;
const years: any = [];
const days: any = [];
const months = [
    {val: "01", name: "01"},
    {val: "02", name: "Şubat"},
    {val: "03", name: "Mart"},
    {val: "04", name: "Nisan"},
    {val: "05", name: "Mayıs"},
    {val: "06", name: "Haziran"},
    {val: "07", name: "Temmuz"},
    {val: "08", name: "Ağustos"},
    {val: "09", name: "Eylül"},
    {val: "10", name: "Ekim"},
    {val: "11", name: "Kasım"},
    {val: "12", name: "Aralık"},
];
let today = ServerDate();
let yy = today.getFullYear() - 18;
for (let i = yy; i >= yy - 100; i--) {
    years.push(i);
}
for (let j = 1; j < 32; j++) {
    days.push(j < 10 ? "0" + j : j.toString());
}

export function Register() {
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (!isMobile) {
            backDrop(true);
        }
        return () => {
            backDrop(false);
        };
    }, [isMobile]);

    useEffect(() => {
        if (auth.member?.id) {
            navigate(paths.home);
        }
    }, [auth.member?.id]);

    useEffect(() => {
        let auth_subscriber = authSubject.subscribe((res: any) => {
            forceUpdate();
        });
        return () => {
            auth_subscriber.unsubscribe();
        };
    }, []);

    const onSubmit: SubmitHandler<any> = (payload: any) => {
        auth.userValidation = false;
        const api = new ApiService();
        payload.mobile = payload.mobile.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");
        payload.birthday = payload.birthday_year + "-" + payload.birthday_month + "-" + payload.birthday_day;
        let loginButton: any = document.getElementById("id_btnRegister");
        if (loginButton) {
            loginButton.disabled = true;
        }


        if (payload.password !== payload.password_confirm) {
            modalServiceSubject.next({
                case: "danger",
                title: "Parolanızı kontrol ediniz!",
                content: "<p>Girdiğiniz parola ve doğrulama birbiri ile eşleşmiyor, lütfen parolanızı kontrol ediniz.</p>",
                timeout: 5000,
                confirm: {
                    cancel: {
                        label: "Tamam",
                        class: "btn",
                    },
                },
            });
            if (loginButton) {
                loginButton.disabled = false;
            }
        } else {
            const cookieData = GetCookies();
            payload.cookie = cookieData['initialTrafficSource'] || '';

            if (searchParams.get('utm_source')) {
                const cookieString = `utmcsr=${searchParams.get('utm_source')}|utmcmd=${searchParams.get('utm_medium')}|utmccn=${searchParams.get('utm_campaign')}`;
                payload.cookie = cookieString;
            }
            api
                .start("post", env.accounting_api + "/member/api/register/", payload, false)
                .then((res: any) => {
                    if (res.status) {
                        userValidation(payload);
                        validationControl = setInterval(() => {
                            if (auth.userValidation === true) {
                                clearInterval(validationControl);
                                try {
                                    // @ts-ignore
                                    fbq("track", "CompleteRegistration");
                                } catch (e) {
                                }
                                userLogin({
                                    user: payload.tc_id,
                                    password: payload.password,
                                }).then((response: any) => {
                                    if (response.status) {
                                        navigate(paths.home.url);
                                    }
                                    // else{
                                    //   navigate(paths.home);
                                    // }
                                });
                            }
                        }, 100);
                    } else {
                        // fixme bu kısımda bir log atılmalı
                        modalServiceSubject.next({
                            case: "danger",
                            title: "Hata",
                            width: 380,
                            content: `<p>${res.message}</p>`,
                            confirm: {
                                cancel: {
                                    label: "Tamam",
                                    class: "btn-danger",
                                },
                                sure: redirectionErrorCodes.includes(res.error)
                                    ? {
                                        label: "Giriş yap",
                                        action: () => {
                                            navigate(paths.login);
                                        },
                                    }
                                    : null,
                            },
                        });
                    }
                })
                .catch((e: any) => {
                    // console.log(e)
                })
                .finally(() => {
                    if (loginButton) {
                        loginButton.disabled = false;
                    }
                });
        }
    };

    return (
        <>
            {!isMobile && <Home></Home>}
            {!isMobile && (
                <div className="register-widget">
                    <Link className="btn-close" to={paths.home.url}>
                        <img alt={"kapat"} src={xIcon} height={24}/>
                    </Link>

                    <div className="register-widget-background">
                        <img alt={""} className="register-background-image " src={backgroundImage}/>
                        <img
                            alt={"4nala"}
                            onClick={() => navigate(paths.home.url)}
                            className="register-background-logo cursor-pointer"
                            src="/assets/logo_tjk_at_yarisi_hipodrom_4nala_atyarisi.svg"
                        />
                        <div className="register-background-desc">
                            AT YARIŞLARI
                            <br/>
                            HEYECANI
                            <span className="fw-bold text-warning">
                <br/>
                YENİDEN
                <br/>
                BAŞLIYOR!
              </span>
                        </div>
                    </div>

                    <div className="w-50 register-form">
                        <h5 className="text-center pb-2">Üye Ol</h5>

                        <RegisterForm onSubmit={onSubmit}/>
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="register-mobile">
                    <div className="register-mobile-header">
                        <img alt={"4nala"} src="/assets/logo_tjk_at_yarisi_hipodrom_4nala_atyarisi.svg" width={220}/>
                    </div>
                    <RegisterForm onSubmit={onSubmit}/>
                </div>
            )}
        </>
    );
}

export function RegisterForm(props: any) {
    const onSubmit: any = props.onSubmit;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const [showNationality, setShowNationality] = useState<any>(false);

    const isPasswordVisible = () => {
        let password: any = document.getElementById("id_password");
        let eyeIcon: any = document.getElementById("eye-icon");

        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        } else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };

    const isPasswordVisible2 = () => {
        let password: any = document.getElementById("id_password2");
        let eyeIcon: any = document.getElementById("eye-icon2");

        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        } else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
                <div className="w-50">
                    <input type="text" placeholder="AD" {...register("first_name", {required: true})} />
                    {errors.first_name && <p className="invalid-feedback">Lütfen adınızı girin</p>}
                </div>
                <div className="w-50">
                    <input type="text" placeholder="SOYAD" {...register("last_name", {required: true})} />
                    {errors.last_name && <p className="invalid-feedback">Lütfen soyadınızı girin</p>}
                </div>
            </div>
            <div className="form-row">
                <div className="w-100">
                    <input
                        type="tel"
                        placeholder="TC KİMLİK NO"
                        {...register("tc_id", {required: true})}
                        onChange={(e: any) => {
                            if (e.target.value && e.target.value.startsWith("99")) {
                                setShowNationality(true);
                            } else {
                                setShowNationality(false);
                            }
                        }}
                    />
                    {errors.tc_id && <p className="invalid-feedback">11 Haneli TC kimlik numaranızı girin</p>}
                </div>
            </div>
            {showNationality && (
                <div className="form-row">
                    <div className="w-100">
                        <select
                            className={`form-select w-100 ${!isMobile ? "bg-transparent" : "bg-light"}`}
                            {...register("nationality", {required: true})}
                        >
                            <option value="" disabled selected>
                                UYRUK BİLGİSİ
                            </option>
                            {nationalities.map((x: any) => {
                                return (
                                    <option value={x.id} key={x.name}>
                                        {x.name}
                                    </option>
                                );
                            })}
                        </select>

                        {errors.nationality && <p className="invalid-feedback">Uyruk bilginizi giriniz</p>}

                        <label htmlFor="id_passport_selfie" className="form-label">
                            Selfie ve Pasaport Fotoğrafı (max:5mb){" "}
                        </label>
                        <input
                            type="file"
                            {...register<any>("passport_selfie", {required: true})}
                            className="form-control"
                            id="id_passport_selfie"
                            accept="image/png,image/jpg,image/jpeg"
                        />
                        {errors.passport_selfie && (
                            <div className="form-text text-danger">Selfie ve Pasaport Fotoğrafı gereklidir.</div>
                        )}

                        <p className="mt-1" style={{fontSize: "12px"}}>
                            Lütfen yüzünüzün net bir şekilde göründüğü, güncel bir fotoğraf yükleyin. Fotoğrafınız;
                            kimlik doğrulama,
                            güvenlik ve topluluk standartlarına uygunluk açısından değerlendirilecektir.
                            <p className="text-align-end text-end">
                                <a
                                    className="fw-bold pointer me-1"
                                    onClick={() => {
                                        showSelfieRules();
                                    }}
                                >
                                    Kurallar
                                </a>
                                <a
                                    className="fw-bold pointer"
                                    onClick={() => {
                                        showSelfieExample(
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img width="200" height="200" src={SelfieExample}/>
                                            </div>
                                        );
                                    }}
                                >
                                    Örnek Görsel
                                </a>
                            </p>
                        </p>
                    </div>
                </div>
            )}
            <div className="form-row">
                <div className="w-100">
                    <label htmlFor="id_birthday" className={`form-label ${!isMobile ? "" : "text-light"}`}>
                        Doğum Tarihi
                    </label>
                    <div className="row">
                        <div className="col d-flex flex-column">
                            <select
                                className={`form-select ${!isMobile ? "bg-transparent" : "bg-light"}`}
                                {...register("birthday_day", {required: true})}
                                defaultValue={"DEFAULT"}
                            >
                                <option value="DEFAULT" disabled>
                                    Gün
                                </option>
                                {days.map((x: any) => {
                                    return (
                                        <option value={x} key={x}>
                                            {x}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors.birthday_day && errors.birthday_day.type === "required" && (
                                <span className="text-validation">Doğum tarihi gereklidir.</span>
                            )}
                        </div>
                        <div className="col d-flex flex-column">
                            <select
                                className={`form-select ${!isMobile ? "bg-transparent" : "bg-light"}`}
                                {...register("birthday_month", {required: true})}
                                defaultValue={"DEFAULT"}
                            >
                                <option value="DEFAULT" disabled>
                                    Ay
                                </option>
                                {months.map((x: any) => (
                                    <option value={x.val} key={x.val}>
                                        {x.val}
                                    </option>
                                ))}
                            </select>
                            {errors.birthday_month && errors.birthday_month.type === "required" && (
                                <span className="text-validation">Doğum tarihi gereklidir.</span>
                            )}
                        </div>
                        <div className="col d-flex flex-column">
                            <select
                                className={`form-select ${!isMobile ? "bg-transparent" : "bg-light"}`}
                                {...register("birthday_year", {required: true})}
                                defaultValue={"DEFAULT"}
                            >
                                <option value="DEFAULT" disabled>
                                    Yıl
                                </option>
                                {years.map((x: any) => (
                                    <option value={x} key={x}>
                                        {x}
                                    </option>
                                ))}
                            </select>
                            {errors.birthday_year && errors.birthday_year.type === "required" && (
                                <span className="text-validation">Doğum tarihi gereklidir.</span>
                            )}
                        </div>
                    </div>
                    {/*<input type="text" onFocus={(e:any)=>{ e.target.type='date' }} placeholder="DOĞUM TARİHİ" {...register("birthday", { required: true })} />*/}
                    {/*{errors.birthday && <p className="invalid-feedback">Doğum tarihinizi Gün/Ay/Yıl şeklinde girin</p>}*/}
                </div>
            </div>
            <div className="form-row">
                <div className="w-100">
                    <input
                        type="email"
                        placeholder="E-POSTA"
                        {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                        })}
                    />
                    {errors.email && <p className="invalid-feedback">Geçerli e-posta adresinizi girin</p>}
                </div>
            </div>
            <div className="form-row">
                <div className="w-100">
                    <ReactInputMask
                        mask="(599) 999 99 99"
                        className="form-control"
                        type={"tel"}
                        placeholder={"CEP TELEFONU"}
                        {...register<any>("mobile", {required: true})}
                    />

                    {errors.mobile && <p className="invalid-feedback">Cep telefonu numaranızı girin</p>}
                </div>
            </div>
            <div className="form-row">
                <div className="w-100">
                    <select
                        className={`form-select w-100 ${!isMobile ? "bg-transparent" : "bg-light"}`}
                        {...register("profession", {required: false})}
                        defaultValue={"DEFAULT"}
                    >
                        <option value="DEFAULT" disabled>
                            MESLEK BİLGİSİ
                        </option>
                        {professions.map((x: any) => {
                            return (
                                <option value={x.id} key={x.name}>
                                    {x.name}
                                </option>
                            );
                        })}
                    </select>

                    {errors.profession && <p className="invalid-feedback">Meslek bilginizi giriniz</p>}
                </div>
            </div>

            <div className="form-row">
                <div className="w-100">
                    <input
                        className="form-control"
                        id="id_password"
                        type="password"
                        placeholder="Şifre"
                        {...register("password", {required: true})}
                    />
                    <div className="cursor-pointer password-eye" onClick={() => isPasswordVisible()}>
                        <img id="eye-icon" height={16} width={16} src={EyeOffIcon} alt="Toggle visibility"/>
                    </div>
                </div>
                {errors.password && <p className="invalid-feedback">En az 8 haneli şifrenizi belirleyin</p>}
            </div>

            <div className="form-row">
                <div className="w-100">
                    <input
                        className="form-control"
                        id="id_password2"
                        type="password"
                        placeholder="Şifre (Tekrar)"
                        {...register("password_confirm", {required: true})}
                    />
                    <div className="cursor-pointer password-eye" onClick={() => isPasswordVisible2()}>
                        <img id="eye-icon2" height={16} width={16} src={EyeOffIcon} alt="Toggle visibility"/>
                    </div>
                </div>
                {errors.password && <p className="invalid-feedback">En az 8 haneli şifrenizi belirleyin</p>}
            </div>
            <div className="form-row">
                <div className={`${isMobile ? "text-light" : ""}`}>
                    <p className="white-space-normal">
                        Şifreniz en az 8 karakter, en fazla 20 karakterden oluşmalı ve aşağıdaki öğeleri içermelidir:
                        <ul>
                            <li>En az bir büyük harf</li>
                            <li>En az bir küçük harf</li>
                            <li>En az bir rakam</li>
                            <li>En az bir özel karakter (!, @, #, vb.)</li>
                        </ul>
                    </p>
                </div>
            </div>

            {/* <div className="form-row">
              <div className="w-100">
                <div className="d-flex flex-row align-items-center input gap-1 bg-white border-secondary-subtle ">
                  <input
                    className="border border-0 bg-transparent"
                    id="id_password2"
                    type="password"
                    placeholder="Şifreniz (Tekrar)"
                    {...register("password_confirm", { required: true })}
                  />
                  <div className="cursor-pointer password-eye float-right mr-2" onClick={() => isPasswordVisible2()}>
                    <img id="eye-icon2" height={16} width={16} src={EyeOffIcon} />
                  </div>
                </div>
                {errors.password_confirm && <p className="invalid-feedback">En az 8 haneli şifrenizi tekrar girin</p>}
              </div>
            </div> */}

            <div className="form-row">
                <div className="w-100">
                    <label className={`checkbox-text d-block ${isMobile ? "text-light" : ""}`}>
                        Lütfen aşağıdaki kutucukları tıklamadan önce Kişisel Verilerin İşlenmesine İlişkin{" "}
                        <a
                            className="fw-bold"
                            onClick={() => {
                                showAgreementModal("aydinlatma-metni");
                            }}
                        >
                            Aydınlatma Metnini
                        </a>{" "}
                        okuyunuz.
                    </label>
                </div>
            </div>

            <div className="form-row">
                <div className="w-100 checkbox">
                    <input id="terms" type="checkbox" {...register("terms", {required: true})} />
                    <label htmlFor="terms" className="checkbox-text d-block">
                        <a
                            className="fw-bold"
                            onClick={() => {
                                showAgreementModal("uyelik-sozlesmesi");
                            }}
                        >
                            Üyelik Sözleşmesini okudum
                        </a>
                        , kabul ediyorum.
                    </label>
                    {errors.terms &&
                        <p className="invalid-feedback">Üyelik sözleşmesini kabul etmeden devam edemezsiniz.</p>}
                </div>
            </div>

            <div className="form-row">
                <div className="w-100 checkbox">
                    <input id="privacy" type="checkbox" {...register("privacy", {required: true})} />
                    <label htmlFor="privacy" className="checkbox-text d-block">
                        Tarafıma kişiye özel bir hizmet sunulabilmesi için{" "}
                        <a
                            className="fw-bold"
                            onClick={() => {
                                showAgreementModal("acik-riza-metni");
                            }}
                        >
                            Açık Rıza Metni
                        </a>
                        'ni kabul ediyorum.
                    </label>
                    {errors.privacy &&
                        <p className="invalid-feedback">Açıkrıza metnini kabul etmeden devam edemezsiniz.</p>}
                </div>
            </div>

            <div className="form-row">
                <div className="w-100 checkbox">
                    <input id="commercial" type="checkbox" {...register("commercial", {required: false})} />
                    <label htmlFor="commercial" className="checkbox-text d-block">
                        <a className="fw-bold" onClick={() => showAgreementModal("elektronik-ticari-ileti-kurallari")}>
                            Ticari Elektronik İleti Aydınlatma Metni
                        </a>{" "}
                        ile açıklandığı şekilde Joker Şans Oyunları Anonim Şirketi 4NALA Şirketi tarafından kampanya,
                        reklam,
                        promosyon, tanıtım amaçlarıyla yukarıda belirttiğim iletişim adreslerime ticari elektronik ileti
                        gönderilmesini kabul ediyorum.
                    </label>
                </div>
            </div>

            <button id="id_btnRegister" className="btn btn-warning w-100 " type="submit">
                ÜYE OL
            </button>

            <div className="d-flex justify-content-center mt-2">
                <a className="text-center" href="/giris-yap">
                    Zaten bir hesabım var.
                </a>
            </div>
        </form>
    );
}
