import "./ticket.css";
import {homeHippodrome, tipstersData, todayResults} from "../../../store/misc.store";
import {
    availableBettingSubTypes,

    BettingTypesMap,
    BulletinMap, BulletinMapCardID,
} from "../../../store/bulletin.store";
import {
    addToTJKBetSlip,
    betSlipActionSubject,
    tjkBetSlipOptions,
    tjkBetslipStore,
    updateBetCount,
} from "../../../services/betslip.service";
import {useNavigate} from "react-router-dom";
import paths from "../../../paths";
import {bulletinProvider} from "../../../services/bulletin.service";
import {modalServiceSubject} from "../../../services/modal.service";
import React from "react";

export function Ticket(props: any) {
    const navigate = useNavigate();

    let selectedHippodrome = props.hippodrome !== undefined ? props.hippodrome : homeHippodrome;
    let ticket = props.ticket;

    let playableTicketTypes = [];
    let availableTypes = bulletinProvider.getAvailableBettingTypes(selectedHippodrome);
    let myRuns: any = [];


    for (let i = 0; i < BettingTypesMap[ticket.bet_type].column; i++) {
        myRuns.push(i + Number(ticket.race_id));
    }
    for (const availableType of availableTypes) {
        let availableSubTypes = bulletinProvider.getAvailableBettingSubTypes(availableType);
        for (const availableSubType of availableSubTypes) {
            if (myRuns.join(",").includes(availableSubType.label.split('-').join(","))) {
                if (availableType.columnLabel !== 'At') {
                    availableSubType.betType = availableType;
                    playableTicketTypes.push(availableSubType);
                }
            }
        }
        // for (const betTypeRun of availableType.runs) {
        //     playableRuns = [];
        //     for (let i = 0; i < availableType.column; i++) {
        //         playableRuns.push(betTypeRun + i);
        //     }
        //     playableRunsMap[betTypeRun] = playableRuns;
        // }
    }


    const playTicket = (ticket: any) => {
        // console.log(BulletinMap[Number(selectedHippodrome.id)])
        let betType = BulletinMap[selectedHippodrome._id].wager_details.find(
            (w: any) => Number(w.type) === Number(ticket.bet_type)
        );
        // console.log("ticket",ticket)
        // console.log("betType",betType)

        if (betType && betType.runs.includes(ticket.race_id)) {
            let slipItem = tjkBetslipStore.filter((el: any) => {
                if (
                    el.hippodrome === Number(selectedHippodrome.id) &&
                    el.bettingType === Number(ticket.bet_type) &&
                    el.bettingSubType === Number(ticket.race_id)
                ) {
                    return el;
                }
            });
            for (let item of slipItem) {
                tjkBetslipStore.splice(tjkBetslipStore.indexOf(item), 1);
            }
            ticket.legs.map((run: any, legIndex: number) => {
                run.map((horse: any, horseIndex: number) => {
                    let isExist: any = tjkBetslipStore.findIndex(
                        (el: any) =>
                            el.hippodrome === Number(selectedHippodrome.id) &&
                            el.bettingType === Number(ticket.bet_type) &&
                            el.bettingSubType === ticket.race_id &&
                            el.column === legIndex + 1 &&
                            el.run === Number(ticket.race_id + legIndex) &&
                            el.horse === String(horse)
                    );

                    if (isExist === -1) {
                        let runNo = ticket.race_id;
                        if (BettingTypesMap[Number(ticket.bet_type)].columnLabel != "At") {
                            runNo = runNo + legIndex;
                        }
                        if (BulletinMap[selectedHippodrome._id].runsMap[Number(runNo)].horseMap[horse].run_status) {
                            tjkBetslipStore.push({
                                hippodrome: Number(selectedHippodrome.id),
                                bettingType: Number(ticket.bet_type),
                                bettingSubType: ticket.race_id,
                                column: legIndex + 1,
                                run: Number(runNo),
                                horse: String(horse),
                            });
                        }
                    }
                });
            });
            tjkBetSlipOptions.hippodrome = selectedHippodrome;
            bulletinProvider.getAvailableBettingTypes(selectedHippodrome);
            bulletinProvider.selectBettingType(selectedHippodrome, Number(ticket.bet_type));
            bulletinProvider.selectBettingSubType(
                availableBettingSubTypes.find((el: any) => Number(el.id) === Number(ticket.race_id))
            );
            tjkBetSlipOptions.column = 1;
            tjkBetSlipOptions.betSlipMultiply = ticket.multiply;
            updateBetCount.status = true;
            betSlipActionSubject.next({action: "clear", data: null});

            navigate(`${paths.betting.url}/${selectedHippodrome.keyword.toLowerCase()}${selectedHippodrome._id}`);
        } else {
            modalServiceSubject.next({
                title: "Kapanmış Bahis",
                content:
                    '<div class="text-center"><p>Bu kupon artık oynanabilir değil. Farklı bir kupon yapabilir yada yorumcularımzın diğer kuponlarını oynayabilirsiniz.</p></div>',
                confirm: {
                    cancel: {
                        label: "Tamam",
                    },
                },
            });
        }
    };

    const playRapid = async (ticket: any, type: any, subType: any) => {
        let betType = BulletinMapCardID[Number(ticket.card_id)].wager_details.find((w: any) => Number(w.type) === type);

        // let playableRuns: any = [];
        // let playableRunsMap: Record<any, any> = {};

        if (!betType) {
            modalServiceSubject.next({
                title: "Kapanmış Bahis",
                content:
                    '<div class="text-center"><p>Bu kupon artık oynanabilir değil. Farklı bir kupon yapabilir ya da yorumcularımızın diğer kuponlarını oynayabilirsiniz.</p></div>',
                confirm: {
                    cancel: {
                        label: "Tamam",
                    },
                },
            });
        }

        tjkBetSlipOptions.hippodrome = selectedHippodrome;
        bulletinProvider.selectBettingType(selectedHippodrome, type);
        bulletinProvider.selectBettingSubType(subType);
        tjkBetSlipOptions.column = 1;
        let column = 0;
        for (const subTypeElement of subType.label.split('-')) {
            for (let i = 0; i < ticket.legs.length; i++) {
                if (i + ticket.race_id === Number(subTypeElement)) {
                    column++;
                    var run = selectedHippodrome.runsMap[subTypeElement];
                    for (const legElement of ticket.legs[i]) {
                        // console.log(column);
                        var horse = run.horseMap[legElement]
                        if (horse.run_status) {
                            addToTJKBetSlip(column, run, horse, "add");
                        }
                    }

                }
            }

        }

        tjkBetSlipOptions.betSlipMultiply = ticket.multiply;
        // updateBetCount.status = true;
        betSlipActionSubject.next({action: "clear", data: null});
        navigate(`${paths.betting.url}/${selectedHippodrome.keyword.toLowerCase()}${selectedHippodrome._id}`);

        // }
    };


    return (
        <>
            <div className="ticket-widget" key={ticket.id}>
                <div className="header d-flex align-items-center justify-content-between">
                    <div className={"d-flex"}>
                        <img
                            className="avatar"
                            src={tipstersData[ticket.member_id]?.avatar || "/assets/tipster/default_avatar_tipster_at_yarisi.png"}
                            alt=""
                        />
                        <div className={"d-flex flex-column"}>
                            <h3>
                                {tipstersData[ticket.member_id]?.nick_name ??
                                    `${tipstersData[ticket.member_id]?.first_name} ${tipstersData[ticket.member_id]?.last_name}`}
                            </h3>
                            <span className={"bet-info"}>{selectedHippodrome.hippodrome}</span>
                        </div>
                    </div>

                    <div className={"d-flex flex-column align-items-end"}>
            <span className="bet-info">
              {/* {BettingTypesMap[Number(ticket.bet_type)]?.name === "6'lı Ganyan" && (ticket.race_id > 1 ? "2." : "1.")}{" "} */}
                {BettingTypesMap[Number(ticket.bet_type)]?.shortName ?? BettingTypesMap[Number(ticket.bet_type)]?.name}
            </span>
                        {BettingTypesMap[Number(ticket.bet_type)].columnLabel === "At" && (
                            <>
                                <span className="bet-info">{ticket.race_id}. Koşu</span>
                            </>
                        )}
                    </div>
                </div>
                <div className="body">
                    <div className={"legs"}>
                        {ticket.legs.map((run: any, legIndex: number) => {

                            return <div
                                className={`run ${
                                    BulletinMap[selectedHippodrome._id].runsMap[ticket.race_id + legIndex]?.status === 0 ? "" : "disabled"
                                }`}
                                key={`legIndex-${legIndex}`}
                            >
                                <div>
                                    {BettingTypesMap[Number(ticket.bet_type)].columnLabel !== "At"
                                        ? ticket.race_id + legIndex
                                        : legIndex + 1}
                                    .{ticket.legs.length > 4 && <br/>}
                                    {ticket.bet_type === 18 ? 'Koşu' : BettingTypesMap[Number(ticket.bet_type)].columnLabel}
                                </div>
                                {run.map((horse: any, horseIndex: number) => {
                                    let isWinner = false;
                                    try {
                                       isWinner = todayResults[selectedHippodrome.keyword].runsMap[BettingTypesMap[Number(ticket.bet_type)].columnLabel !== "At"
                                            ? ticket.race_id + legIndex
                                            : legIndex + 1]?.winnerHorse.no.toString() === horse.toString()
                                    }catch (e) {
                                    }
                                    return <div className={`${isWinner ? 'text-danger fw-bold' : ''}`} key={`horseIndex-${horseIndex}`}>{horse}</div>
                                })}
                            </div>
                        })}

                    </div>
                    {playableTicketTypes.length > 0 && <div className={"row "}>
                        <div className={"col-12"}>
                            <div className="dropdown">
                                <button
                                    className={`btn btn-sm btn-six dropdown-toggle mt-3 w-100 fw-bold`}
                                    data-bs-toggle="dropdown"
                                    style={{color: "#FFFFFF"}}
                                    aria-expanded="false"
                                >
                                    Hızlı Kupon
                                </button>
                                <ul className="dropdown-menu rapid-menu bg-white">
                                    {
                                        playableTicketTypes.map((type: any, key: any) => {
                                            return <li>
                                                <a
                                                    className="dropdown-item text-start"
                                                    href="#"
                                                    onClick={() => {
                                                        playRapid(ticket, type.betType.id, type)
                                                    }}
                                                >
                                                    {type.betType.name} ({type.label})
                                                </a>
                                            </li>
                                        })
                                    }


                                </ul>
                            </div>
                        </div>


                    </div>}


                </div>
                <div className="footer">

                    <div className="info">
            <span>
              BAHİS: <b>{ticket.bet_count}</b> MİSLİ: <b>{ticket.multiply}</b>
            </span>
                        <span>
              TUTAR: <b>{ticket.amount}₺</b>
            </span>
                    </div>
                    <div
                        className={`status cursor-pointer`}
                        onClick={() => {
                            playTicket(ticket);
                        }}
                    >
                        OYNA
                    </div>
                </div>
            </div>
        </>
    );
}

export function MobileTicket(props: any) {
    const navigate = useNavigate();
    let selectedHippodrome = props.hippodrome !== undefined ? props.hippodrome : homeHippodrome;
    let ticket = props.ticket;

    let playableTicketTypes = [];
    let availableTypes = bulletinProvider.getAvailableBettingTypes(selectedHippodrome);
    let myRuns: any = [];


    for (let i = 0; i < BettingTypesMap[ticket.bet_type].column; i++) {
        myRuns.push(i + Number(ticket.race_id));
    }
    for (const availableType of availableTypes) {
        let availableSubTypes = bulletinProvider.getAvailableBettingSubTypes(availableType);
        for (const availableSubType of availableSubTypes) {
            if (myRuns.join(",").includes(availableSubType.label.split('-').join(","))) {
                if (availableType.columnLabel !== 'At') {
                    availableSubType.betType = availableType;
                    playableTicketTypes.push(availableSubType);
                }
            }
        }
        // for (const betTypeRun of availableType.runs) {
        //     playableRuns = [];
        //     for (let i = 0; i < availableType.column; i++) {
        //         playableRuns.push(betTypeRun + i);
        //     }
        //     playableRunsMap[betTypeRun] = playableRuns;
        // }
    }

    const playTicket = (ticket: any) => {
        // console.log(BulletinMap[Number(selectedHippodrome.id)])
        let betType = BulletinMap[selectedHippodrome._id].wager_details.find(
            (w: any) => Number(w.type) === Number(ticket.bet_type)
        );
        // console.log("ticket",ticket)
        // console.log("betType",betType)

        if (betType && betType.runs.includes(ticket.race_id)) {
            let slipItem = tjkBetslipStore.filter((el: any) => {
                if (
                    el.hippodrome === Number(selectedHippodrome.id) &&
                    el.bettingType === Number(ticket.bet_type) &&
                    el.bettingSubType === Number(ticket.race_id)
                ) {
                    return el;
                }
            });
            for (let item of slipItem) {
                tjkBetslipStore.splice(tjkBetslipStore.indexOf(item), 1);
            }
            ticket.legs.map((run: any, legIndex: number) => {
                run.map((horse: any, horseIndex: number) => {
                    let isExist: any = tjkBetslipStore.findIndex(
                        (el: any) =>
                            el.hippodrome === Number(selectedHippodrome.id) &&
                            el.bettingType === Number(ticket.bet_type) &&
                            el.bettingSubType === ticket.race_id &&
                            el.column === legIndex + 1 &&
                            el.run === Number(ticket.race_id + legIndex) &&
                            el.horse === String(horse)
                    );

                    if (isExist === -1) {
                        let runNo = ticket.race_id;
                        if (BettingTypesMap[Number(ticket.bet_type)].columnLabel != "At") {
                            runNo = runNo + legIndex;
                        }
                        if (BulletinMap[selectedHippodrome._id].runsMap[Number(runNo)].horseMap[horse].run_status) {
                            tjkBetslipStore.push({
                                hippodrome: Number(selectedHippodrome.id),
                                bettingType: Number(ticket.bet_type),
                                bettingSubType: ticket.race_id,
                                column: legIndex + 1,
                                run: Number(runNo),
                                horse: String(horse),
                            });
                        }
                    }
                });
            });
            tjkBetSlipOptions.hippodrome = selectedHippodrome;
            bulletinProvider.getAvailableBettingTypes(selectedHippodrome);
            bulletinProvider.selectBettingType(selectedHippodrome, Number(ticket.bet_type));
            bulletinProvider.selectBettingSubType(
                availableBettingSubTypes.find((el: any) => Number(el.id) === Number(ticket.race_id))
            );
            tjkBetSlipOptions.column = 1;
            tjkBetSlipOptions.betSlipMultiply = ticket.multiply;
            updateBetCount.status = true;

            betSlipActionSubject.next({action: "clear", data: null});

            navigate(`${paths.betting.url}/${selectedHippodrome.keyword.toLowerCase()}${selectedHippodrome._id}`);
        } else {
            modalServiceSubject.next({
                title: "Kapanmış Bahis",
                content:
                    '<div class="text-center"><p>Bu kupon artık oynanabilir değil. Farklı bir kupon yapabilir yada yorumcularımzın diğer kuponlarını oynayabilirsiniz.</p></div>',
                confirm: {
                    cancel: {
                        label: "Tamam",
                    },
                },
            });
        }
    };

    const playRapid = async (ticket: any, type: any, subType: any) => {
        let betType = BulletinMapCardID[Number(ticket.card_id)].wager_details.find((w: any) => Number(w.type) === type);

        // let playableRuns: any = [];
        // let playableRunsMap: Record<any, any> = {};

        if (!betType) {
            modalServiceSubject.next({
                title: "Kapanmış Bahis",
                content:
                    '<div class="text-center"><p>Bu kupon artık oynanabilir değil. Farklı bir kupon yapabilir ya da yorumcularımızın diğer kuponlarını oynayabilirsiniz.</p></div>',
                confirm: {
                    cancel: {
                        label: "Tamam",
                    },
                },
            });
        }

        tjkBetSlipOptions.hippodrome = selectedHippodrome;
        bulletinProvider.selectBettingType(selectedHippodrome, type);
        bulletinProvider.selectBettingSubType(subType);
        tjkBetSlipOptions.column = 1;
        let column = 0;
        for (const subTypeElement of subType.label.split('-')) {
            for (let i = 0; i < ticket.legs.length; i++) {
                if (i + ticket.race_id === Number(subTypeElement)) {
                    column++;
                    var run = selectedHippodrome.runsMap[subTypeElement];
                    for (const legElement of ticket.legs[i]) {
                        console.log(column);
                        var horse = run.horseMap[legElement]
                        if (horse.run_status) {
                            addToTJKBetSlip(column, run, horse, "add");
                        }
                    }

                }
            }

        }

        tjkBetSlipOptions.betSlipMultiply = ticket.multiply;
        // updateBetCount.status = true;
        betSlipActionSubject.next({action: "clear", data: null});
        navigate(`${paths.betting.url}/${selectedHippodrome.keyword.toLowerCase()}${selectedHippodrome._id}`);

        // }
    };


    return (
        <>
            <div className="ticket-widget" key={ticket.id}>
                <div className="header d-flex align-items-center justify-content-between">
                    <div className={"d-flex"}>
                        <img
                            className="avatar"
                            src={tipstersData[ticket.member_id]?.avatar || "/assets/tipster/default_avatar_tipster_at_yarisi.png"}
                            alt=""
                        />
                        <div className={"d-flex flex-column"}>
                            <h3>
                                {tipstersData[ticket.member_id]?.nick_name ??
                                    `${tipstersData[ticket.member_id]?.first_name} ${tipstersData[ticket.member_id]?.last_name}`}
                            </h3>
                            <span className={"bet-info"}>{selectedHippodrome.hippodrome}</span>
                        </div>
                    </div>

                    <div className={"d-flex flex-column align-items-end"}>
            <span className="bet-info">
              {BettingTypesMap[Number(ticket.bet_type)]?.name === "6'lı Ganyan" && (ticket.race_id > 1 ? "2." : "1.")}{" "}
                {BettingTypesMap[Number(ticket.bet_type)]?.shortName ?? BettingTypesMap[Number(ticket.bet_type)]?.name}
            </span>
                        {BettingTypesMap[Number(ticket.bet_type)].columnLabel === "At" && (
                            <>
                                <span className="bet-info">{ticket.race_id}. Koşu</span>
                            </>
                        )}
                    </div>
                </div>
                <div className="body mobile-body">
                    <div className={"row"}>
                        <div className={"col-8"}>
                            <div className={"d-flex"}>
                                {ticket.legs.map((run: any, legIndex: number) => (
                                    <div
                                        className={`run ${
                                            BulletinMap[selectedHippodrome._id].runsMap[ticket.race_id + legIndex]?.status === 0
                                                ? ""
                                                : "disabled"
                                        }`}
                                        key={`legIndex-${legIndex}`}
                                    >
                                        <div>
                                            {BettingTypesMap[Number(ticket.bet_type)].columnLabel !== "At"
                                                ? ticket.race_id + legIndex
                                                : legIndex + 1}
                                            .
                                            {BettingTypesMap[Number(ticket.bet_type)].columnLabel === "At" &&
                                                BettingTypesMap[Number(ticket.bet_type)].columnLabel}
                                        </div>
                                        {run.map((horse: any, horseIndex: number) => {
                                            let isWinner = false;
                                            try {
                                                isWinner = todayResults[selectedHippodrome.keyword].runsMap[BettingTypesMap[Number(ticket.bet_type)].columnLabel !== "At"
                                                    ? ticket.race_id + legIndex
                                                    : legIndex + 1]?.winnerHorse.no.toString() === horse.toString()
                                            }catch (e) {
                                            }
                                            return <div className={`${isWinner ? 'text-danger fw-bold' : ''}`} key={`horseIndex-${horseIndex}`}>{horse}</div>
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={"col-4 ps-0"}>
                            <div className="info">
                                <div>
                                    BAHİS: <b>{ticket.bet_count}</b>
                                    <br/>
                                    MİSLİ: <b>{ticket.multiply}</b>
                                </div>
                                <div>
                                    TUTAR:
                                    <br/> <b className={"fs-5"}>{ticket.amount}₺</b>
                                </div>
                            </div>
                            <div
                                className={`cursor-pointer`}
                            >
                                <button
                                    className={`btn btn-sm btn-six dropdown-toggle  w-100 fw-bold`}
                                    data-bs-toggle="dropdown"
                                    style={{color: "#FFFFFF", borderRadius: "0"}}
                                    aria-expanded="false"
                                >
                                    Hızlı Kupon
                                </button>
                                <ul className="dropdown-menu rapid-menu bg-white">
                                    {
                                        playableTicketTypes.map((type: any, key: any) => {
                                            return <li>
                                                <a
                                                    className="dropdown-item text-start"
                                                    href="#"
                                                    onClick={() => {
                                                        playRapid(ticket, type.betType.id, type)
                                                    }}
                                                >
                                                    {type.betType.name} ({type.label})
                                                </a>
                                            </li>
                                        })
                                    }


                                </ul>
                            </div>
                            <div
                                className={`status cursor-pointer`}
                                style={{borderTopLeftRadius: "0", borderTopRightRadius: "0"}}
                                onClick={() => {
                                    playTicket(ticket);
                                }}
                            >
                                OYNA
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer"></div>
            </div>
        </>
    );
}
