import "./games.css";
import React, { useEffect, useState } from "react";
import { Storage } from "../../services/localstorege.service";
import _ from "lodash";
import { isMobile } from "../../App";
import {
  gameNavigationMiddleware,
  gtmSelectItem,
  gtmViewItemList,
  useForceUpdate,
} from "../../services/misc.functions";
import { useNavigate, useParams } from "react-router";
import paths from "../../paths";
import { auth, authSubject } from "../../store/auth.store";
import { ApiService } from "../../services/http.service";
import { env } from "../../constants/global.vars";
import { RaffleSlider } from "../raffles/widgets/raffle.slider";
import cekilis from "../../assets/icons/cekilis.svg";
import oyunlar from "../../assets/icons/oyunlar.svg";
import eSansWhite from "../../assets/icons/e-sans-white.svg";
import iconRight from "../../assets/feather/arrow-right.svg";
import gift from "../../assets/img/home_icon_products.png";
import { getGameRemains } from "../../services/auth.service";
import Game from "./game/game";

const tabs = [
  { value: "dijital-oyunlar", label: "Dijital Oyunlar" },
  { value: "yeni-oyunlar", label: "" },
  { value: "gelecek-oyunlar", label: "" },
];

export function Games() {
  let params = useParams();
  const forceUpdate = useForceUpdate();
  const api = new ApiService();
  const navigate = useNavigate();

  const [gamesList, setGamesList] = useState<any>([]);

  const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
  const handleSelect = (e: any) => {
    if (e.target.id) {
      setSelectedTab(tabs[e.target.id]);
    }
  };

  useEffect(() => {
    getGames().then((res: any) => {
      getGameRemains().then(() => {
        setGamesList(res);
        gtmViewItemList(res, "Oyunlar");
      });
    });
  }, [params]);

  useEffect(() => {
    let auth_subscriber = authSubject.subscribe((res: any) => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  const getGames = async () => {
    let games: any = Storage.get("games");

    if (games) {
      if (params.subpage === "yeni-oyunlar") {
        return games.filter((game: any) => {
          return game.is_active === true;
        });
      } else if (params.subpage === "gelecek-oyunlar") {
        return games.filter((game: any) => {
          return game.is_active === false;
        });
      } else {
        return games;
      }
    }
    return await api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then((res: any) => {
      if (res && res.status) {
        let d: any = _.orderBy(res.data, ["ordering"], "asc");
        Storage.set("games", d, 60 * 60 * 5);
        if (params.subpage === "yeni-oyunlar") {
          return d.filter((game: any) => {
            return game.is_active === true;
          });
        } else if (params.subpage === "gelecek-oyunlar") {
          return d.filter((game: any) => {
            return game.is_active === false;
          });
        } else {
          return d;
        }
      } else {
        return [];
      }
      return [];
    });
  };

  // create function to capital first letter of string
  const gameNameFixer = (string: string) => {
    if (string == "KAZI Kazan") {
      return "Kazı Kazan";
    } else if (string == "MAYIN Tarlası") {
      return "Mayın Tarlası";
    } else if (string == "AŞAĞI YUKARI") {
      return "Aşağı Yukarı";
    } else if (string == "Süper PENALTI") {
      return "Süper Penaltı";
    } else if (string == "ŞANSLI 6") {
      return "Şanslı 6";
    } else if (string == "HIZLI Tombala") return "Hızlı Tombala";
    else {
      return string;
    }
  };

  if (params.subpage && params.subpage !== "yeni-oyunlar" && params.subpage && params.subpage !== "gelecek-oyunlar") {
    return <Game></Game>;
  }

  return (
    <div className="row gap-3">
      {!isMobile && <RaffleSlider />}
      {!isMobile && (
        <div className="row gap-4 flex-nowrap p-0 m-0">
          <div className={`page-widget p-0`}>
            <svg style={{ display: "none" }}>
              <defs>
                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
              </defs>
            </svg>
            <div className={"tab-scroll"}>
              {/* Tab Header */}
              <ul className="nav nav-tabs">
                {tabs.map(({ value, label }, index) => {
                  return (
                    <li
                      key={`raffle-tabs-${index}`}
                      className={`nav-item raffle-item ${selectedTab.value === value ? "active" : ""}`}
                      onClick={label ? handleSelect : () => {}}
                    >
                      <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlinkHref="#tabshape"></use>
                      </svg>
                      <a className="nav-link" id={`${index}`}>
                        {label}
                      </a>
                      <svg className="right" viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlinkHref="#tabshape"></use>
                      </svg>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* Tab Content */}
            <div className="p-2">
              <div className={"container"}>
                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                  {gamesList.map((game: any, key: number) => {
                    return (
                      <div
                        key={`games-list-${key}`}
                        className="col-6 col-lg-3 mb-4 pointer"
                        onClick={() => {
                          gameNavigationMiddleware(game, navigate);
                          gtmSelectItem([game], "Oyunlar");
                        }}
                      >
                        <div className="game-widget">
                          <img className="games" src={env.cdn_host + "/media/" + game.image} />
                          {/* oyun gifi backendden gelmeli ve admin panelden tanımlanmalı */}
                          {/* <img
                            className="game-gif"
                            src={game.gif ? env.cdn_host + "/media/" + game.gif : env.cdn_host + "/media/" + game.image}
                            alt=""
                          /> */}
                          <h2 className="title">{gameNameFixer(game.name)}</h2>
                          {!isMobile && (
                            <div
                              className={`${game.is_active === true ? "playable" : ""} ${
                                game.is_active === false && auth.member?.id && auth.remaining_games.includes(game.id)
                                  ? "remanded"
                                  : ""
                              } body  mt-2`}
                            >
                              {/*<span className="sub-title">Beta phase zeplin game</span>*/}
                              {game.is_active === true && (
                                <a
                                  className="label text-decoration-none text-white"
                                  onClick={() => {
                                    gameNavigationMiddleware(game, navigate);
                                  }}
                                >
                                  Hemen Oyna
                                  <img
                                    src={iconRight}
                                    style={{
                                      width: 12,
                                      filter: "invert(1)",
                                      marginTop: -3,
                                    }}
                                  />
                                </a>
                              )}
                              {game.is_active !== true && (
                                <span className="label">
                                  {auth.member?.id && auth.remaining_games.includes(game.id)
                                    ? "✅ Hatırlatıcı kuruldu"
                                    : "⏰ Hatırlat"}
                                  <img
                                    src={iconRight}
                                    style={{
                                      width: 12,
                                      filter: "invert(1)",
                                      marginTop: -3,
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                          )}
                          {isMobile && (
                            <div
                              className={`${game.is_active === true ? "playable" : ""} ${
                                game.is_active === false && auth.member?.id && auth.remaining_games.includes(game.id)
                                  ? "remanded"
                                  : ""
                              } body  mt-2`}
                            >
                              {game.is_active === true && (
                                <span
                                  className="label"
                                  onClick={() => {
                                    gameNavigationMiddleware(game, navigate);
                                  }}
                                >
                                  Hemen Oyna
                                  <img
                                    src={iconRight}
                                    style={{
                                      width: 12,
                                      filter: "invert(1)",
                                      marginTop: -3,
                                    }}
                                  />
                                </span>
                              )}
                              {game.is_active !== true && game.reminder !== true && (
                                <span className="label">
                                  {auth.member?.id && auth.remaining_games.includes(game.id)
                                    ? "✅ Hatırlatıcı kuruldu"
                                    : "⏰ Hatırlat"}

                                  <img
                                    src={iconRight}
                                    style={{
                                      width: 12,
                                      filter: "invert(1)",
                                      marginTop: -3,
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className={"d-flex justify-content-center align-items-center mt-2 header"}>
          <img src={eSansWhite} height={25} style={{ marginRight: "0.5rem" }} />
          <h2 className={"text-white mb-0"}>Şans Oyunları</h2>
        </div>
      )}
      {isMobile && (
        <div className={"col-12"}>
          <div className={"mobile-tab-widget"}>
            <div className={"row"}>
              <div className={"col-5"}>
                <div
                  className="raffle-tab-item border selected"
                  onClick={() => {
                    navigate(paths.games.url);
                  }}
                >
                  <img alt={"dijital-oyunlar"} src={oyunlar} />
                  <div>
                    <h2>
                      DİJİTAL
                      <br />
                      OYUNLAR
                    </h2>
                  </div>
                </div>
              </div>

              <div className={"col-5"}>
                <div
                  className="raffle-tab-item border"
                  onClick={() => {
                    navigate(paths.raffles.url);
                  }}
                >
                  <img alt={"cekilis"} src={cekilis} />
                  <div>
                    <h2>
                      EŞYA
                      <br />
                      PİYANGOSU
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className={"col-2 p-0"}
                onClick={() => {
                  navigate(paths.products.url);
                }}
              >
                <div
                  className="row p-0 m-0 d-flex flex-column align-items-center justify-content-center fs-8 fw-bold border rounded-4 text-primary"
                  style={{ height: "70px" }}
                >
                  <img alt={"oduller"} src={gift} style={{ width: "38px" }} />
                  {/* <span className={""}>YENİ</span> */}
                  <div>
                    <span>ÖDÜLLER</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"border-bottom-dotted"}></div>
            <div className={"row"}>
              <div className={"col-12"}>
                <div
                  onClick={() => {
                    setSelectedTab(tabs[0]);
                  }}
                  className={`draw-tab-item ${selectedTab.value === "dijital-oyunlar" ? "selected" : ""}`}
                >
                  Dijital Oyunlar
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className={"container"}>
          <div className="row bg-white" style={{ borderRadius: "10px" }}>
            {gamesList.map((game: any, key: number) => {
              return (
                <div
                  key={`games-list-${key}`}
                  className="col-6 col-lg-3 mb-4 pointer"
                  onClick={() => {
                    gameNavigationMiddleware(game, navigate);
                    gtmSelectItem([game], "Oyunlar");
                  }}
                >
                  <div className="game-widget">
                    <img className="games" src={env.cdn_host + "/media/" + game.image} />

                    {/* oyun gifi backendden gelmeli ve admin panelden tanımlanmalı */}
                    {/* <img
                      className="game-gif"
                      src={game.gif ? env.cdn_host + "/media/" + game.gif : env.cdn_host + "/media/" + game.image}
                      alt=""
                    /> */}
                    <h2 className="title">{gameNameFixer(game.name)}</h2>
                    {!isMobile && (
                      <div
                        className={`${game.is_active === true ? "playable" : ""} ${
                          game.is_active === false && auth.member?.id && auth.remaining_games.includes(game.id)
                            ? "remanded"
                            : ""
                        } body  mt-2`}
                      >
                        {/*<span className="sub-title">Beta phase zeplin game</span>*/}
                        {game.is_active === true && (
                          <a
                            className="label text-decoration-none text-white"
                            onClick={() => {
                              gameNavigationMiddleware(game, navigate);
                            }}
                          >
                            Hemen Oyna
                            <img
                              src={iconRight}
                              style={{
                                width: 12,
                                filter: "invert(1)",
                                marginTop: -3,
                              }}
                            />
                          </a>
                        )}
                        {game.is_active !== true && (
                          <span className="label">
                            {auth.member?.id && auth.remaining_games.includes(game.id)
                              ? "✅ Hatırlatıcı kuruldu"
                              : "⏰ Hatırlat"}
                            <img
                              src={iconRight}
                              style={{
                                width: 12,
                                filter: "invert(1)",
                                marginTop: -3,
                              }}
                            />
                          </span>
                        )}
                      </div>
                    )}
                    {isMobile && (
                      <div
                        className={`${game.is_active === true ? "playable" : ""} ${
                          game.is_active === false && auth.member?.id && auth.remaining_games.includes(game.id)
                            ? "remanded"
                            : ""
                        } body  mt-2`}
                      >
                        {game.is_active === true && (
                          <span
                            className="label"
                            onClick={() => {
                              gameNavigationMiddleware(game, navigate);
                            }}
                          >
                            Hemen Oyna
                            <img
                              src={iconRight}
                              style={{
                                width: 12,
                                filter: "invert(1)",
                                marginTop: -3,
                              }}
                            />
                          </span>
                        )}
                        {game.is_active !== true && game.reminder !== true && (
                          <span className="label">
                            {auth.member?.id && auth.remaining_games.includes(game.id)
                              ? "✅ Hatırlatıcı kuruldu"
                              : "⏰ Hatırlat"}
                            <img
                              src={iconRight}
                              style={{
                                width: 12,
                                filter: "invert(1)",
                                marginTop: -3,
                              }}
                            />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
