import React, { useEffect, useState } from "react";
import "./game.css";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import backButton from "../../../assets/feather/chevron-left.svg";
import eSansWhite from "../../../assets/icons/e-sans-white.svg";
import { Storage } from "../../../services/localstorege.service";
import paths from "../../../paths";
import GameSelectWidget from "../widgets/game_select_widget";
import { env } from "../../../constants/global.vars";
import { ApiService } from "../../../services/http.service";
import { isMobile } from "../../../App";
import { auth, authSubject } from "../../../store/auth.store";
import { getGameItem, slugify, useForceUpdate } from "../../../services/misc.functions";

function Game(props: any) {
  const api = new ApiService();
  const code: any = props.code;
  const [game, setGame] = useState<any>(null);
  let params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (code) {
      let game = getGameItem(code);
      if (game != null) {
        setGame(game);
      }
    }
  }, [code]);

  useEffect(() => {
    if (isMobile) {
      const bottomBar = document.getElementById("mobile-footer-menu");
      const headers = document.getElementsByTagName("header");
      const body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "hidden";
      const scrollPosition = window.scrollY || window.pageYOffset;
      window.scrollTo(0, scrollPosition);

      // Hide elements
      if (bottomBar) {
        bottomBar.style.display = "none";
      }

      if (headers.length > 0) {
        headers[0].style.display = "none";
      }

      // Cleanup function to restore elements
      return () => {
        if (bottomBar) {
          bottomBar.style.display = "flex";
        }

        if (headers.length > 0) {
          headers[0].style.display = "flex";
        }

        body.style.overflowY = "visible";
      };
    }
  }, []);

  useEffect(() => {
    var slug = params.subpage;
    // console.log(slug);
    let _games: any = Storage.get("games");
    if (_games) {
      var selectedGame = _games.find((r: any) => {
        return (
          slugify(
            r.name
              .toLowerCase()
              .replaceAll("ü", "u")
              .replaceAll("ş", "s")
              .replaceAll("ç", "c")
              .replaceAll("ğ", "g")
              .replaceAll("ı", "i")
              .replaceAll("ö", "o")
          ) === slug
        );
      });
      setGame(selectedGame);
    } else {
      api.start("get", env.accounting_api + "/games/api/list-game/", null, false).then((res: any) => {
        if (res && res.status) {
          let d: any = _.orderBy(res.data, ["ordering"], "asc");
          Storage.set("games", d, 60 * 60 * 5);
          var selectedGame = d.find((r: any) => {
            return (
              slugify(
                r.name
                  .toLowerCase()
                  .replaceAll("ü", "u")
                  .replaceAll("ş", "s")
                  .replaceAll("ç", "c")
                  .replaceAll("ğ", "g")
                  .replaceAll("ı", "i")
                  .replaceAll("ö", "o")
              ) === slug
            );
          });
          setGame(selectedGame);
        } else {
        }
      });
    }
  }, [params]);

  const forceUpdate = useForceUpdate();
  useEffect(() => {
    let auth_subscriber = authSubject.subscribe(() => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  let webLink =
    window.location.host.startsWith("test") || window.location.host.startsWith("localhost")
      ? "https://test.4nala.com.tr"
      : "https://4nala.com";

  return (
    <div>
      {auth.authentication && auth.member && auth.member.id && game ? (
        <div className="row p-0 m-0">
          {isMobile && (
            <>
              <div className="col-3 d-flex  align-items-center justify-content-center">
                <img
                  src={backButton}
                  height={25}
                  style={{ marginRight: "0.5rem", position: "absolute", left: "10px", filter: "invert(100%)" }}
                  onClick={() => navigate(paths.games.url)}
                />
              </div>

              <div className="col-6 d-flex flex-column justify-content-center align-items-center">
                <div className={"d-flex justify-content-center align-items-center mt-2 me-4"}>
                  <img src={eSansWhite} height={20} style={{ marginRight: "0.5rem" }} />
                  <h2 className={"text-white mb-0"} style={{ fontSize: "16px" }}>
                    Şans Oyunları
                  </h2>
                </div>
                <GameSelectWidget game_name={game.name} />
              </div>

              <div className="col-3 game-user-balance">
                <div className="lines">
                  <span>{auth.member.balance_debit}₺</span>
                  <span>{Number(auth.member.balance_point).toFixed(2)}P</span>
                </div>
              </div>
            </>
          )}

          <iframe
            className="z-1 m-0 p-0"
            src={`${webLink}/game-widget/game-widget.html?&token=${Storage.get("token")}&vendor=${game.vendor}&code=${
              game.code
            }&userId=${auth.member.id}`}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Game;
