import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {backDrop} from "../../services/misc.functions";
import {SubmitHandler, useForm} from "react-hook-form";
import {ApiService} from "../../services/http.service";
import {agreements, env} from "../../constants/global.vars";
import paths from "../../paths";
import {Home} from "../home/home";
import "./login.css";
import {sendValidationCode, userLogin} from "../../services/auth.service";
import {LoginRequest} from "../../models/auth.model";
import {modalServiceSubject} from "../../services/modal.service";
import xIcon from "../../assets/feather/x.svg";
import ReactInputMask from "react-input-mask";
import ForgetPassword from "./forgetpassword";
import EyeIcon from "../../assets/feather/eye.svg";
import EyeOffIcon from "../../assets/feather/eye-off.svg";

let s: any;
let resendButton: any;

export function Login() {
    const params:any = useParams();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [showCodeField, setShowCodeField] = useState<any>(false);
    const [showPhoneNumber, setShowPhoneNumber] = useState<any>(false);
    const [updatedMobile, setUpdatedMobile] = useState<any>("");
    const [passwordModal, showPasswordModal] = useState(false);

    useEffect(() => {
        backDrop(true);
        return () => {
            backDrop(false);
        };
    }, []);

    if (passwordModal) {
        backDrop(false);
    } else {
        backDrop(true);
    }

    const senderTimer = (s: any = null) => {
        let resendTimer: any = document.getElementById("id_resendTimer");
        if (resendTimer) {
            let secondLeft: number = Number(
                Number(resendTimer.innerHTML.split(":")[0]) * 60 + Number(resendTimer.innerHTML.split(":")[1]) - 1
            );
            if (secondLeft < 0) {
                clearInterval(s);
                resendButton = document.getElementById("id_resendCode");
                if (resendButton) {
                    resendButton.classList.remove("hide");
                }
            } else {
                resendTimer.innerHTML = Math.floor(secondLeft / 60) + ":" + ("00" + (secondLeft % 60)).slice(-2);
            }
        }
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
        getValues,
    } = useForm<LoginRequest>();

    const doLogin = (payload: any) => {
        let loginButton: any = document.getElementById("id_btnLogin");
        if (loginButton) {
            loginButton.disabled = true;
        }
        userLogin(payload).then((response: any) => {
            if (loginButton) {
                loginButton.disabled = false;
            }

            if (response.status) {
                reset({
                    user: "",
                    password: "",
                });
                if (params.nextPage != null){
                    navigate(params.nextPage.replaceAll('|','/'));
                }else{
                    navigate(paths.home.url);
                }

            } else if (response.error === "sms_validate") {
                sendValidationCode(response.data);
                s = setInterval(senderTimer, 1000);
                setShowCodeField(response.data);
            } else if (response.error == "agreement_required") {
                let content = agreements["uyelik-sozlesmesi"];
                modalServiceSubject.next({
                    title: content.title,
                    content: content.content,
                    width: 720,
                    confirm: {
                        cancel: {
                            label: "Vazgeç",
                        },
                        sure: {
                            label: "Kabul Et",
                            class: "btn-info  w-100",
                            action: () => {
                                payload["is_checked"] = true;
                                doLogin(payload);
                            },
                        },
                    },
                });
            } else {
                setErrorMessage(response.message);
            }
        });
    };

    const onSubmit: SubmitHandler<LoginRequest> = (payload: any) => {
        // console.log(payload);

        if (payload.code && payload.code !== "") {
            let api = new ApiService();
            api
                .start(
                    "post",
                    env.accounting_api + "/member/api/validation-sms/",
                    {
                        mobile: showCodeField.mobile,
                        type: "register_validate",
                        code: payload.code,
                    },
                    false
                )
                .then((res: any) => {
                    // console.log(res);

                    if (res && res.status) {
                        doLogin(payload);
                    } else {
                        setErrorMessage(res.message);
                    }
                })
                .catch((e: any) => {
                    setErrorMessage(e);
                });
        } else {
            doLogin(payload);
        }
    };

    function updatePhoneNumber() {
        const api = new ApiService();
        api
            .start(
                "post",
                env.accounting_api + "/member/api/change-mobile/",
                {
                    mobile: updatedMobile.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", ""),
                    user: getValues("user"),
                    password: getValues("password"),
                },
                false
            )
            .then((res: any) => {
                if (res && res.status) {
                    setShowPhoneNumber(false);
                    doLogin({
                        user: getValues("user"),
                        password: getValues("password"),
                    });
                } else {
                    setErrorMessage(res.message);
                }
            })
            .catch((e: any) => {
                setErrorMessage(e);
            });
    }

    const isPasswordVisible = () => {
        let password: any = document.getElementById("password");
        let eyeIcon: any = document.getElementById("eye-icon");

        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        } else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    }

    function closePasswordModal() {
        showPasswordModal(false);
    }

    return (
        <>
            <Home></Home>

            {!passwordModal && <div className="login-form">
                <Link className="btn-close" to={paths.home.url}>
                    <img alt={"kapat"} src={xIcon} height={18}/>
                </Link>

                <h5 className="align-self-center mb-3">Üye Girişi</h5>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className="w-100">
                            <input
                                type="number"
                                className={"hide-stepper"}
                                placeholder="TC KİMLİK veya CEP NUMARANIZ"
                                {...register("user", {required: true})}
                            />
                            {errors.user && <p className="invalid-feedback">11 Haneli TC kimlik numaranızı girin</p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="w-100">
                            <input type="password" id={"password"}
                                   placeholder="Şifreniz" {...register("password", {required: true})} />
                            <div className="cursor-pointer eye-icon float-right mr-2"
                                 onClick={() => isPasswordVisible()}>
                                <img alt={"eye"} id="eye-icon" className="eye-icon" height={16} width={16}
                                     src={EyeOffIcon}/>
                            </div>
                            {errors.password && <p className="invalid-feedback">En az 8 haneli şifrenizi belirleyin</p>}
                        </div>
                    </div>
                    {errorMessage && <div className="position-relative">
                        <p className="my-2 alert alert-danger">
                            {errorMessage}
                        </p>
                    </div>}

                    {showCodeField && (
                        <div className="mt-4 position-relative">
                            <p className="my-2 alert alert-info">
                                Oturum açabilmek ve üyelik işleminizi tamamlayabilmek için
                                <b> {showCodeField.mobile}</b> numaralı telefonunuza gönderdiğimiz doğrulama kodunu
                                giriniz.
                            </p>

                            <div className="my-2">
                                <button
                                    className="btn w-100 btn-warning mt-2 "
                                    onClick={() => {
                                        setShowPhoneNumber(true);
                                    }}
                                >
                                    Telefon Numaramı değiştir
                                </button>
                            </div>

                            {showPhoneNumber ? (
                                <>
                                    <div className="col-12 col-mobile-12">
                                        <label htmlFor="id_mobile" className="form-label">
                                            Telefon Numarası
                                        </label>
                                        {/*<input type="phone" className="form-control" {...register('mobile', {required: true})} id="id_mobile"/>*/}
                                        <ReactInputMask
                                            mask="(599) 999 99 99"
                                            className="form-control"
                                            placeholder={"(___) ___ __ __"}
                                            onChange={(evt) => setUpdatedMobile(evt.target.value)}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <label>Doğrulama Kodu</label>
                                    <div id="emailHelp" style={{right: 10, marginTop: 10}}
                                         className="form-text position-absolute">
                                        <span id="id_resendTimer">2:00</span>
                                    </div>

                                    <button
                                        type="button"
                                        onClick={() => {
                                            clearInterval(s);
                                            sendValidationCode(showCodeField);
                                            if (resendButton) {
                                                resendButton.classList.add("hide");
                                            }
                                            let resendTimer: any = document.getElementById("id_resendTimer");
                                            if (resendTimer) {
                                                resendTimer.innerHTML = "2:00";
                                            }
                                            s = setInterval(senderTimer, 1000);
                                        }}
                                        style={{
                                            right: 10,
                                            bottom: 8,
                                            fontSize: 13,
                                            padding: "0px 5px",
                                        }}
                                        className="btn hide btn-sm position-absolute btn-warning"
                                        id="id_resendCode"
                                    >
                                        Tekrar Gönder
                                    </button>

                                    {/*<input className="form-control" {...register('code', {required: true})} />*/}
                                    <ReactInputMask
                                        mask="999 999"
                                        className="form-control"
                                        placeholder={"___ ___"}
                                        {...register<any>("code", {required: true})}
                                    />
                                    {errors.code &&
                                        <div className="invalid-feedback d-block">Doğrulama kodu gereklidir.</div>}
                                </>
                            )}
                        </div>
                    )}

                    <div className="form-row px-2">

                        <input id="remember" type="checkbox" {...register<any>("remember" )}/>
                        <label htmlFor="remember" className="checkbox-text" >
                            BENİ HATIRLA
                        </label>


                    </div>
                  {/*  <div className="form-row px-2">

                        <input id="openSession" type="checkbox" {...register<any>("openSession" )}/>
                        <label htmlFor="openSession" className="checkbox-text">
                            OTURUMU AÇIK TUT
                        </label>


                    </div>*/}

                    {showPhoneNumber ? (
                        <div className="mt-2 w-100">
                            <button
                                type="button"
                                className="btn btn-primary mt-2 login-btn w-100"
                                onClick={() => {
                                    updatePhoneNumber();
                                }}
                            >
                                Güncelle
                            </button>
                        </div>
                    ) : (
                        <div className="mt-2 w-100 ">
                            <button className="btn btn-login w-100 py-2" id="id_btnLogin" type="submit">
                                GİRİŞ YAP
                            </button>
                        </div>
                    )}


                    <div className="mt-3 forget-password-link" onClick={() => {
                        showPasswordModal(true);
                    }}>
                        ŞİFREMİ UNUTTUM
                    </div>

                    <hr/>

                    <button className="btn btn-create-account " onClick={() => navigate("/uye-ol")} type="button">
                        ÜCRETSİZ ÜYE OL
                    </button>
                </form>
            </div>}

            <ForgetPassword passwordModal={passwordModal} closePasswordModal={closePasswordModal}></ForgetPassword>
        </>
    );
}
