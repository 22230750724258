import React, {useEffect, useState} from "react";
import {ApiService} from "../../services/http.service";
import {ServerDate, useForceUpdate} from "../../services/misc.functions";
import {homeHippodrome, homeHippodromeSubject, tipstersCommentData, tipstersData} from "../../store/misc.store";
import moment from "moment/moment";
import {env} from "../../constants/global.vars";



export function TipstersComments(props:any){

  const [tipsters, setTipsters] = useState<any>([]);
  const [selectedTipster, setSelectedTipster] = useState<any>(null);
  const forceUpdate = useForceUpdate();
  const selectedHippodrome = props.hippodrome;

  useEffect(() => {
    let homeHippodromeSubject_subscription = homeHippodromeSubject.subscribe((res: any) => {
      forceUpdate();
    })
    return () => {
      homeHippodromeSubject_subscription.unsubscribe();
    }
  }, []);


  // console.log('selectedHippodrome', selectedHippodrome)


  let today = ServerDate();
  let date = moment(today).format('DDMMYYYY');

  useEffect(() => {

    if(tipstersCommentData[date]){
      setTipsters(tipstersCommentData[date].filter((h:any)=>Number(h.card_id)===Number(selectedHippodrome?.card_id)).map((el:any)=>el.tipster));
      setSelectedTipster(tipstersCommentData[date].filter((h:any)=>Number(h.card_id)===Number(selectedHippodrome?.card_id)).map((el:any)=>el.tipster)[0]);
      forceUpdate();
    }else{
      let api = new ApiService()
      api.start('get', `${env.tipsters_api}/misc/api/racing-tip/${date}/`, null, false)
        .then((res:any)=>{
          tipstersCommentData[date] = res.data;
          setTipsters(tipstersCommentData[date].filter((h:any)=>Number(h.card_id)===Number(selectedHippodrome?.card_id)).map((el:any)=>el.tipster));
          setSelectedTipster(tipstersCommentData[date].filter((h:any)=>Number(h.card_id)===Number(selectedHippodrome?.card_id)).map((el:any)=>el.tipster)[0]);
          forceUpdate();
        })
    }

  }, [date, selectedHippodrome?.id]);

  if(!selectedHippodrome?.id||selectedTipster===null){
    return <></>;
  }

  let tips = tipstersCommentData[date].find((el:any)=>Number(el.card_id)===Number(selectedHippodrome?.card_id)&&el.tipster.id===selectedTipster?.id)

  return (<>

    <ul className="tipsters-list">
      {tipsters.map((tipster:any)=>{
        return (<li key={'tipster-'+tipster.id} onClick={()=>{
          setSelectedTipster(tipster)
        }} className={selectedTipster?.id===tipster?.id ? "active":''}>
          <img className="avatar"
               src={tipstersData[tipster.id]?.avatar || '/assets/tipster/default_avatar_tipster_at_yarisi.png'}
               alt=""/>
          {tipster.nick_name ?? `${tipster.first_name} ${tipster.last_name}`}</li>)
      })}
    </ul>

    {tips?.run_tips?.map((run: any, index: number) => {
      return (<div key={'run-tip-' + index} className="tipster-comment">
        <div>
          {selectedHippodrome.hippodrome} {run.run}.KOŞU
        </div>
        <div
            dangerouslySetInnerHTML={{__html: run.text.replace(/\n\r/g, "").replace(/\r\n/g, "")}}></div>
      </div>)
    })}
    {
        (tips === null || tips?.run_tips == null) && <>
          <p className="text-center p-4">Bu hipodrom için henüz uzman yorumu bulunmamaktadır.</p>
        </>
    }

  </>)

}
