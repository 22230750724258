import { useEffect, useRef, useState } from "react";
import "./statistics.css";
import { useParams } from "react-router-dom";

let postScrollPos = 0;

export default function Statistics() {
  const params: any = useParams();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [hippodromeKey, setHipodromeKey] = useState("");

  useEffect(() => {
    if (params.hippodrome) {
      setHipodromeKey(params.hippodrome);
    }
  }, [params]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "RESIZE_IFRAME") {
        if (iframeRef.current) {
          iframeRef.current.style.height = `${event.data.height}px`;
        }
      }

      if (event.data.type === "MODAL_SHOW") {
        if (iframeRef.current) {
          postScrollPos = window.scrollY;
          const iframeHeight = iframeRef.current.style.height.replace("px", "");
          window.scrollTo({ top: Number(iframeHeight) / 2.5 });
        }
      }

      if (event.data.type === "MODAL_HIDE") {
        if (iframeRef.current) {
          window.scrollTo({ top: postScrollPos });
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      src={`https://tjk-statistics-web.bitrelic.io/?hipodrom=${hippodromeKey}`}
      style={{ width: "100%", border: "none", minHeight: "100vh" }}
      title="Statistics Iframe"
    />
  );
}
