import {Subject} from "rxjs";

export const DailyRaces: any = [];
export const availableHippodromes: any = [];
export const availableFobHippodromes: any = [];
export const availableBettingTypes: any = [];
export const availableFobBettingTypes: any = [];
export const availableBettingSubTypes: any = [];

export const racesActionSubject = new Subject();
export const availableHippodromesSubject = new Subject();
export const availableFobHippodromesSubject = new Subject();

export const courseColors: any = {
    'Çim': '#51b608',
    'Kum': '#c59515',
    'Sentetik': '#E99E00',
    'TAPETA': '#FC4F4F',
}


export const jewelryMapping: any = {
    'KG': `Kapalı Gözlük takılacağını ifade eder.`,
    'YP': `Yanak Peluşu takılacağını ifade eder.`,
    'ÖG': `Start makinasına önden giriş yapacağını ifade eder.`,
    'KUL': `Kulaklık takılacağını ifade eder.`,
    'K': `Kulaklık takılacağını ifade eder.`,
    'DB': `Dilinin Bağlanacağını ifade eder.`,
    'SKG': `Ring mahalinden Start'a Kadar Kapalı Gözlük ile geleceğini ifade eder.`,
    'SK': `Ring mahalinden Start'a Kadar Kapalı Gözlük ile geleceğini ifade eder.`,
    'SKUL': `Ring mahalinden Start'a Kadar Kulaklık ile geleceğini ifade eder.`,
    'SGK': `Ring mahalinden Start'a Kadar Gözleri Kapalı geleceğini ifade eder.`,
    'MBH': `Bir atın koşuya müşterek bahis harici olarak katıldığını ifade eder.`,
    'DS': `Komiserler Kararı ile atın Dış Start kullanılacağını ifade eder.`,
    'BB': `Burun Bandı takılacağını ifade eder.`,
    'GKR': `Göz Koruyucu takılacağını ifade eder.`,
    'AP': `Ata aprantinin (jokey yamağı) bineceğini ifade eder.`,
    'EKÜRİ': `Ahırdaş olduğu önceden ilan edilen ve aynı koşuya katılan atların jokeyleri aynı tip forma giyerler numarası büyük olan jokeyi bant takar.`,
    'KGS': `Koşmadığı gün sayısını ifade eder.`,
    'G.ÇIK.': `Geç çıkışı ifade eder.`,
    'EİD': `En iyi derece alanı o safkanın aynı tür pistte ve aynı mesafede yaptığı en iyi dereceyi gösterir. Safkan daha önce aynı tür pistte ve aynı mesafede koşmamışsa bu alanda veri olmaz. Örneğin; safkanın koşacağı programdaki koşu 1.500 metre çim koşusu ise, en iyi derece alanı o güne kadar o atın 1.500 m. çim pist koşusunda yaptığı en iyi dereceyi gösterir. Safkan o güne kadar 1500 m çim pistte hiç koşmamışsa bu alan boş olur.`,
    'S20': `Yarış hayatı boyunca son koştuğu 20 koşuda; En az bir birincilik, Veya en az iki ikincilik, Ya da dört adet tabela(2.lik, 3.lük, 4.lük ),Yapamamış olan atlar hiçbir hipodromdaki koşulara iştirak edemezler (Mahalli koşular hariç).Bu atların ahır tahsisi iptal edilir.(31.12.2009 tarihi itibarı ile 2009 Yılı At Yarışları Genel Hükümleri 13/b maddesine göre koşulara katılma hakkını kaybeden atlar bu hükümden yararlanamazlar.)Ancak yarış hayatı boyunca; Bir G1,veya iki G2, veya dört G3, veya bir G2 ve iki G3, Kazanan atlar için bu kural uygulanmaz. (KV 10 veya A3 koşusu kazanan atlar G3 koşusu kazanmış gibi, KV 11 veya A2 koşusu kazanan atlar G2 koşusu kazanmış gibi işlem görürler.)`
}

export const bettingTypes: any = [
    {
        id: 1881,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        name: 'Ganyan - Sabit ihtimalli',
        info: 'Bir koşunun birincisini tahmin ederek o at üzerinde oynanan bahistir. Ganyan - Sabit ihtimalli bahis türünde kupon iptal edilemez ve eküri kuralları geçerli değildir.',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 0,
        available: true,
        column: 12,
        subType: 12,
        columnLabel: 'Koşu',
        name: 'Gelecek Program',
        info: '',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 1,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        name: 'Ganyan',
        info: 'Bir koşunun birincisini tahmin ederek o at üzerinde oynanan bahistir.',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 2,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        name: 'Plase',
        info: 'En az 7 atın iştirak edeceği bir koşuda bir atın birinci veya ikinci geleceğini tahmin ederek o at veya atlar üzerinde oynanan bahistir.',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 4,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        name: 'Ganyan & Plase',
        info: 'Ganyan ve plase oyunlarının aynı kuponda oynandığı oyun türüdür. Koşu sonucuna göre Ganyan ve/veya Plase ödemesi yapılır. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 8,
        available: true,
        column: 2,
        subType: 1,
        columnLabel: 'At',
        name: 'Sıralı ikili Bahis',
        shortName: 'Sıralı ikili',
        info: 'En az 4 atın iştirak edeceği bir koşuda, birinci ve ikinci gelecek atı sırasıyla tahmin ederek o atlar üzerine oynanan bahistir. ',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 9,
        available: false,
        column: 2,
        columnLabel: 'At',
        name: 'Sıralı ikili Bahis (Komple)',
        shortName: "Sıralı ikili (K)"
    },
    {
        id: 50,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        horseCount: 2,
        name: 'Sıralı ikili (Virgüllü)',
        shortName: 'Sıralı ikili (V)',
        info: 'En az 4 atın iştirak edeceği bir koşuda, birinci ve ikinci gelecek atı sırasıyla tahmin ederek o atlar üzerine oynanan bahistir. Virgüllü oyun tipinde kazanma şartı; yazılan atların kupon onay sayfasında soldan sağa gösterildiği şekilde yarışı bitirmesidir. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 10,
        available: true,
        column: 2,
        subType: 1,
        columnLabel: 'At',
        name: 'İkili Bahis',
        info: 'En az 7 atın iştirak edeceği bir koşuda, birinci ve ikinciyi tahmin ederek, o atlar üzerine oynanan bahistir. Birinci ve ikinci sıralamanın değişmesi halinde de kazanç sağlanır. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 11,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'At',
        name: 'İkili Bahis (Tek Kolon)',
        info: 'En az 7 atın iştirak edeceği bir koşuda, birinci ve ikinciyi tahmin ederek, o atlar üzerine sıralamadan bağımsız tek kolonda seçim yaparak oynanan bahistir. Birinci ve ikinci sıralamanın değişmesi halinde de kazanç sağlanır. ',

        horseCount: 2,
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 14,
        available: true,
        column: 2,
        subType: 2,
        columnLabel: 'Koşu',
        name: 'Çifte Bahis',
        info: 'Birbiri ardına yapılan iki koşunun birincilerini tahmin ederek o atlar üzerine oynanan bahistir. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 10,
    },
    {
        id: 15,
        available: true,
        column: 3,
        subType: 3,
        columnLabel: 'Ayak',
        name: '3\'lü Ganyan',
        info: 'Birbiri ardına yapılan üç koşunun birincilerini tahmin ederek o atlar üzerine oynanan bahistir. . 3\'lü ganyanda da oyun tutarı her ayakta oynanan at sayıları toplamlarının birbirleri ile ve çıkan rakam da oyunun birim fiyatıyla çarpılarak bulunur. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 1,
    },
    {
        id: 16,
        available: true,
        column: 4,
        subType: 4,
        columnLabel: 'Ayak',
        name: '4\'lü Ganyan',
        info: 'Birbiri ardına yapılan dört koşunun birincilerini bulmak amacıyla oynanan oyundur. 4\'lü ganyanda da oyun tutarı her ayakta oynanan at sayıları toplamlarının birbirleri ile ve çıkan rakam da oyunun birim fiyatıyla çarpılarak bulunur. ',


        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.75,
    },
    {
        id: 17,
        available: true,
        column: 5,
        subType: 5,
        columnLabel: 'Ayak',
        name: '5\'li Ganyan',
        info: 'Birbiri ardına yapılan beş koşunun birincilerini bulmak amacıyla oynanan oyundur. 5\'li ganyanda da oyun tutarı her ayakta oynanan at sayıları toplamlarının birbirleri ile ve çıkan rakam da oyunun birim fiyatıyla çarpılarak bulunur.',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.50,
    },
    {
        id: 18,
        available: true,
        column: 6,
        subType: 6,
        columnLabel: 'Ayak',
        name: "6'lı Ganyan",
        info: 'Birbiri ardına yapılan altı koşunun birincilerini bulmak amacıyla oynanan oyundur. 6\'lı ganyanda oyun tutarı her ayağa yazılan at sayılarının toplamlarının birbirleri ile çıkan rakamında oyun birim fiyatıyla çarpılmasıyla bulunur. ',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.40,
    },
    {
        id: 19,
        available: true,
        column: 7,
        subType: 7,
        columnLabel: 'Ayak',
        name: '7\'li Plase',
        info: 'Birbiri ardına yapılan yedi koşuda birinci veya ikinci gelecek atları tahmin ederek o atlar üzerine oynanan bahistir. Üzerine oynanan atın birinci veya ikinci gelmesi sonucu değiştirmez ve eşit kazanç sağlar. Aynı ayakta hem birinci hem ikinci gelen atları ihtiva eden bilet her iki at için de ayrı ayrı kazançlı sayılır. 7 koşunun birincilerini içeren bir bilet ile ikincilerini içeren diğer bir biletin kazanç tutarı arasında bir fark olmayacaktır. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.75
    },
    {
        id: 47,
        available: true,
        column: 2,
        subType: 1,
        columnLabel: 'At',
        name: 'Plase İkili',
        info: 'Bir koşuda, ilk üç dereceye girecek farklı derecelere ait iki atı tahmin ederek o atlar üzerine oynanan bahistir. ',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 2,
    },
    {
        id: 48,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'At',
        name: 'Plase İkili (Tek Kolon)',
        info: 'Bir koşuda, ilk üç dereceye girecek farklı derecelere ait iki atı tahmin ederek sıralamadan bağımsız tek kolonda seçim yaparak o atlar üzerine oynanan bahistir. ',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 2,
    },
    {
        id: 51,
        available: true,
        column: 3,
        subType: 1,
        columnLabel: 'At',
        name: 'Sıralı Üçlü Bahis',
        info: 'Bir koşuda birinci, ikinci ve üçüncü gelecek atları sırasıyla tahmin ederek o atlar üzerine oynanan bahistir. ',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.70,
    },
    {
        id: 52,
        available: false,
        column: 3,
        subType: 1,
        horseCount: 3,
        columnLabel: 'At',
        name: 'Sıralı Üçlü Bahis (K)',
        shortName: 'Sıralı Üçlü (K)',
    },
    {
        id: 53,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        horseCount: 3,
        name: 'Sıralı Üçlü (Virgüllü)',
        shortName: 'Sıralı Üçlü (V)',
        info: 'Bir koşuda birinci, ikinci ve üçüncü gelecek atları sırasıyla tahmin ederek o atlar üzerine oynanan bahistir. Virgüllü oyun tipinde kazanma şartı; yazılan atların kupon onay sayfasında soldan sağa gösterildiği şekilde yarışı bitirmesidir. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.70,
    },
    {
        id: 54,
        available: true,
        column: 4,
        subType: 1,
        columnLabel: 'At',
        name: 'Tabela Bahis',
        info: 'Bir koşuda birinci, ikinci, üçüncü ve dördüncü gelecek atları yarışın bitiriş sırasına göre veya sırasız bulmak amacıyla oynanan bir oyundur. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.60,
    },
    {
        id: 55,
        available: false,
        column: 4,
        horseCount: 4,
        columnLabel: 'At',
        name: 'Tabela Bahis (Komple)',
        shortName: 'Tabela Bahis (K)',
    },
    {
        id: 56,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        horseCount: 4,
        name: 'Tabela Bahis (Virgüllü)',
        shortName: 'Tabela Bahis (V)',
        info: 'Bir koşuda, birinci, ikinci, üçüncü ve dördüncü gelecek atları sıralı veya sırasız olarak tahmin ederek o atlar üzerine oynanan bahistir. Virgüllü oyun tipinde kazanma şartı; yazılan atların kupon onay sayfasında soldan sağa gösterildiği şekilde yarışı bitirmesidir. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.60,
    },
    {
        id: 61,
        available: true,
        column: 5,
        subType: 1,
        columnLabel: 'At',
        name: 'Sıralı Beşli',
        info: 'Bir koşuda, birinci, ikinci, üçüncü, dördüncü ve beşinci gelecek atları sırasıyla tahmin ederek o atlar üzerine oynanan bahistir. ',

        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.50,
    },
    {
        id: 62,
        available: false,
        column: 5,
        subType: 1,
        horseCount: 5,
        columnLabel: 'At',
        name: 'Sıralı Beşli (Komple)',
        shortName: 'Sıralı Beşli (K)'
    },
    {
        id: 63,
        available: true,
        column: 1,
        subType: 1,
        columnLabel: 'Koşu',
        horseCount: 5,
        name: 'Sıralı Beşli (Virgüllü)',
        shortName: 'Sıralı Beşli (V)',
        info: 'Bir koşuda, birinci, ikinci, üçüncü, dördüncü ve beşinci gelecek atları sırasıyla tahmin ederek o atlar üzerine oynanan bahistir. Virgüllü oyun tipinde kazanma şartı; yazılan atların kupon onay sayfasında soldan sağa gösterildiği şekilde yarışı bitirmesidir. ',
        maxCouponAmount: 6500,
        minCouponAmount: 10,
        price: 0.50,
    },
]

export const fobBettingTypesIds = [1881];

export const BettingTypesMap: any = Object.assign({}, ...bettingTypes.map((x: any) => ({[x.id]: x})));

export const agfCondBets: any = [14, 15, 16, 17, 18, 19]

export const Hippodromes: any = [{
    "KEY": "ADANA",
    "HIPODROM": "Adana Ye\u015filoba Hipodromu",
    "YER": "Adana",
    "YABANCI": false
}, {
    "KEY": "IZMIR",
    "HIPODROM": "\u0130zmir \u015eirinyer Hipodromu",
    "YER": "\u0130zmir",
    "YABANCI": false
}, {
    "KEY": "ISTANBUL",
    "HIPODROM": "\u0130stanbul Veliefendi Hipodromu",
    "YER": "\u0130stanbul",
    "YABANCI": false
}, {"KEY": "BURSA", "HIPODROM": "Bursa Osmangazi Hipodromu", "YER": "Bursa", "YABANCI": false}, {
    "KEY": "ANKARA",
    "HIPODROM": "Ankara 75. Y\u0131l Hipodromu",
    "YER": "Ankara",
    "YABANCI": false
}, {
    "KEY": "SANLIURFA",
    "HIPODROM": "\u015eanl\u0131urfa Hipodromu",
    "YER": "\u015eanl\u0131urfa",
    "YABANCI": false
}, {
    "KEY": "ELAZIG",
    "HIPODROM": "Elaz\u0131\u011f Hipodromu",
    "YER": "Elaz\u0131\u011f",
    "YABANCI": false
}, {
    "KEY": "DIYARBAKIR",
    "HIPODROM": "Diyarbak\u0131r Hipodromu",
    "YER": "Diyarbak\u0131r",
    "YABANCI": false
}, {"KEY": "KOCAELI", "HIPODROM": "Kocaeli Kartepe Hipodromu", "YER": "Kocaeli", "YABANCI": false}, {
    "KEY": "ANTALYA",
    "HIPODROM": "Antalya Hipodromu",
    "YER": "Antalya",
    "YABANCI": false
}, {"KEY": "KARMA", "HIPODROM": "Karma", "YER": "Karma", "YABANCI": true}, {
    "KEY": "MAHMUDIYE",
    "HIPODROM": "Mahmudiye \u0130dman Pisti",
    "YER": "Mahmudiye",
    "YABANCI": false
}, {
    "KEY": "IZMITIDMAN",
    "HIPODROM": "\u0130zmit \u0130dman Merkezi",
    "YER": "\u0130zmit",
    "YABANCI": false
}, {"KEY": "DUBAI", "HIPODROM": "Meydan", "YER": "Meydan Dubai", "YABANCI": true}, {
    "KEY": "RUSYA",
    "HIPODROM": "Rusya",
    "YER": "Rusya",
    "YABANCI": true
}, {
    "KEY": "NEWMARKET",
    "HIPODROM": "Newmarket",
    "YER": "Newmarket Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "EPSOM",
    "HIPODROM": "Epsom Downs",
    "YER": "Epsom Downs Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "CURRAGH", "HIPODROM": "Curragh", "YER": "Curragh \u0130rlanda", "YABANCI": true}, {
    "KEY": "GOODWOOD",
    "HIPODROM": "Goodwood",
    "YER": "Goodwood Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "DEAUVILLE", "HIPODROM": "Deauville", "YER": "Deauville Fransa", "YABANCI": true}, {
    "KEY": "ASCOT",
    "HIPODROM": "Ascot",
    "YER": "Ascot Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "LEOPARDS", "HIPODROM": "Leopardstown", "YER": "Leopardstown \u0130rlanda", "YABANCI": true}, {
    "KEY": "YORK",
    "HIPODROM": "York",
    "YER": "York Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "DONCASTER",
    "HIPODROM": "Doncaster",
    "YER": "Doncaster Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "KEMPTON",
    "HIPODROM": "Kempton Park",
    "YER": "Kempton Park Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "SANTAANITA",
    "HIPODROM": "Santa Anita Park",
    "YER": "Santa Anita Park ABD",
    "YABANCI": true
}, {
    "KEY": "WHAMPTON",
    "HIPODROM": "Wolverhampton",
    "YER": "Wolverhampton Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "LEIGHS",
    "HIPODROM": "Great Leighs",
    "YER": "Great Leighs Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "LINGFIELD",
    "HIPODROM": "Lingfield Park",
    "YER": "Lingfield Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "SOUTHWELL",
    "HIPODROM": "Southwell",
    "YER": "Southwell Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "NEWBURY",
    "HIPODROM": "Newbury",
    "YER": "Newbury Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "SANDOWN",
    "HIPODROM": "Sandown Park",
    "YER": "Sandown Park Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "HAYDOCK",
    "HIPODROM": "Haydock Park",
    "YER": "Haydock Park Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "NOTTINGHAM",
    "HIPODROM": "Nottingham",
    "YER": "Nottingham Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "CHESTER",
    "HIPODROM": "Chester",
    "YER": "Chester Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "WINDSOR",
    "HIPODROM": "Windsor",
    "YER": "Windsor Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "LEICESTER",
    "HIPODROM": "Leicester",
    "YER": "Leicester Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "FOLKESTONE",
    "HIPODROM": "Folkestone",
    "YER": "Folkestone Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "HAMILTON",
    "HIPODROM": "Hamilton",
    "YER": "Hamilton Park Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "LIONDANGER", "HIPODROM": "Liondanger", "YER": "Liondanger Fransa", "YABANCI": true}, {
    "KEY": "TESTEDEBUC",
    "HIPODROM": "La Teste de Buch",
    "YER": "La Teste de Buch Fransa",
    "YABANCI": true
}, {
    "KEY": "CARLISLE",
    "HIPODROM": "Carlisle",
    "YER": "Carlisle Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "RIPON",
    "HIPODROM": "Ripon",
    "YER": "Ripon Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "BEVERLEY",
    "HIPODROM": "Beverley",
    "YER": "Beverley Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "THIRSK",
    "HIPODROM": "Thirsk",
    "YER": "Thirsk Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "CHANTILLY", "HIPODROM": "Chantilly", "YER": "Chantilly Fransa", "YABANCI": true}, {
    "KEY": "GALWAY",
    "HIPODROM": "Galway",
    "YER": "Galway \u0130rlanda",
    "YABANCI": true
}, {
    "KEY": "WARWICK",
    "HIPODROM": "Warwick",
    "YER": "Warwick Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "PONTEFRACT",
    "HIPODROM": "Pontefract",
    "YER": "Pontefract Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "YARMOUTH",
    "HIPODROM": "Yarmouth",
    "YER": "Yarmouth Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "PHILADELPH", "HIPODROM": "Philadelphia", "YER": "Philadelphia ABD", "YABANCI": true}, {
    "KEY": "TURFFONTEI",
    "HIPODROM": "Turffontein",
    "YER": "Turffontein G. Afrika",
    "YABANCI": true
}, {"KEY": "CAPEVERDE", "HIPODROM": "Cape Verde", "YER": "Cape Verde", "YABANCI": true}, {
    "KEY": "GREYVILLE",
    "HIPODROM": "Greyville",
    "YER": "Greyville G. Afrika",
    "YABANCI": true
}, {"KEY": "ABD", "HIPODROM": "ABD", "YER": "ABD", "YABANCI": true}, {
    "KEY": "VAAL",
    "HIPODROM": "Vaal",
    "YER": "Vaal G. Afrika",
    "YABANCI": true
}, {"KEY": "FAIRVIEW", "HIPODROM": "Fairview", "YER": "Fairview G. Afrika", "YABANCI": true}, {
    "KEY": "ARLINGTON",
    "HIPODROM": "Arlington",
    "YER": "Arlington G\u00fcney Afrika",
    "YABANCI": true
}, {
    "KEY": "CLAIRWOOD",
    "HIPODROM": "Clairwood",
    "YER": "Clairwood G\u00fcney Afrika",
    "YABANCI": true
}, {
    "KEY": "BRIGHTON",
    "HIPODROM": "Brighton",
    "YER": "Brighton Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "BRIDGE",
    "HIPODROM": "Catterick Bridge",
    "YER": "Catterick Bridge Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "WOODBINE", "HIPODROM": "Woodbine", "YER": "Woodbine Kanada", "YABANCI": true}, {
    "KEY": "CHURCHILL",
    "HIPODROM": "Churchill Downs",
    "YER": "Churchill Downs ABD",
    "YABANCI": true
}, {"KEY": "TAMPABAY", "HIPODROM": "Tampa Bay Downs", "YER": "Tampa Bay Downs ABD", "YABANCI": true}, {
    "KEY": "TURFWAY",
    "HIPODROM": "Turfway Park",
    "YER": "Turfway Park ABD",
    "YABANCI": true
}, {"KEY": "FINGER", "HIPODROM": "Finger Lakes", "YER": "Finger Lakes ABD", "YABANCI": true}, {
    "KEY": "MARSEILLE",
    "HIPODROM": "Pont De Vivaux",
    "YER": "Pont De Vivaux Fransa",
    "YABANCI": true
}, {"KEY": "MONMOUNTH", "HIPODROM": "Monmouth Park", "YER": "Monmouth Park ABD", "YABANCI": true}, {
    "KEY": "FORTERIE",
    "HIPODROM": "Fort Erie",
    "YER": "Fort Erie Kanada",
    "YABANCI": true
}, {
    "KEY": "FFOSLAS",
    "HIPODROM": "Ffos Las",
    "YER": "Ffos Las Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "SUFFOLK", "HIPODROM": "Suffolk Downs", "YER": "Suffolk Downs ABD", "YABANCI": true}, {
    "KEY": "CHARLES",
    "HIPODROM": "Charles Town",
    "YER": "Charles Town ABD",
    "YABANCI": true
}, {"KEY": "DELAWARE", "HIPODROM": "Delaware Park", "YER": "Delaware Park ABD", "YABANCI": true}, {
    "KEY": "KEENELAND",
    "HIPODROM": "Keeneland",
    "YER": "Keeneland ABD",
    "YABANCI": true
}, {"KEY": "AQUEDUCT", "HIPODROM": "Aqueduct", "YER": "Aqueduct ABD", "YABANCI": true}, {
    "KEY": "PORNICHET",
    "HIPODROM": "Pornichet La Baule ",
    "YER": "Pornichet La Baule Fransa",
    "YABANCI": true
}, {
    "KEY": "GULFSTREAM",
    "HIPODROM": "Gulfstream Park ",
    "YER": "Gulfstream Park ABD",
    "YABANCI": true
}, {
    "KEY": "SANTIAGO",
    "HIPODROM": "ClubHipico Santiago",
    "YER": "ClubHipico Santiago \u015eili",
    "YABANCI": true
}, {
    "KEY": "NEWCASTLE",
    "HIPODROM": "Newcastle",
    "YER": "Newcastle Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "BELMONT", "HIPODROM": "Belmont Park", "YER": "Belmont Park ABD", "YABANCI": true}, {
    "KEY": "BATH",
    "HIPODROM": "Bath",
    "YER": "Bath Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "FAIRMOUNT", "HIPODROM": "Fairmount Park", "YER": "Fairmount Park ABD", "YABANCI": true}, {
    "KEY": "CHEPSTOW",
    "HIPODROM": "Chepstow ",
    "YER": "Chepstow Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "SARATOGA", "HIPODROM": "Saratoga", "YER": "Saratoga ABD", "YABANCI": true}, {
    "KEY": "SALISBURY",
    "HIPODROM": "Salisbury",
    "YER": "Salisbury Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "MUSSELBURG",
    "HIPODROM": "Musselburgh",
    "YER": "Musselburgh Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "AYR", "HIPODROM": "Ayr", "YER": "Ayr Birle\u015fik Krall\u0131k", "YABANCI": true}, {
    "KEY": "WEST",
    "HIPODROM": "Gulfstream Park West",
    "YER": "Gulfstream Park West ABD",
    "YABANCI": true
}, {
    "KEY": "VALPARAISO",
    "HIPODROM": "Valparaiso Sporting",
    "YER": "Valparaiso \u015eili",
    "YABANCI": true
}, {
    "KEY": "CHILE",
    "HIPODROM": "Chile Hipodromo ",
    "YER": "Chile Hipodromo \u015eili",
    "YABANCI": true
}, {"KEY": "BAKI", "HIPODROM": "Baki", "YER": "Baki Azerbaycan", "YABANCI": true}, {
    "KEY": "CHELMSFORD",
    "HIPODROM": "Chelmsford City",
    "YER": "Chelmsford City Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "PIMLICO", "HIPODROM": "Pimlico", "YER": "Pimlico ABD", "YABANCI": true}, {
    "KEY": "WETHERBY",
    "HIPODROM": "Wetherby",
    "YER": "Wetherby Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {"KEY": "LAUREL", "HIPODROM": "Laurel Park", "YER": "Laurel Park ABD", "YABANCI": true}, {
    "KEY": "LONGCHAMP",
    "HIPODROM": "PARISLONGCHAMP",
    "YER": "Longchamp Fransa",
    "YABANCI": true
}, {
    "KEY": "MAHONING",
    "HIPODROM": "Mahoning Valley",
    "YER": "Mahoning Valley ABD",
    "YABANCI": true
}, {
    "KEY": "CONCEPCION",
    "HIPODROM": "Club Hipico de Concepcion ",
    "YER": "Club Hipico de Concepcion \u015eili",
    "YABANCI": true
}, {"KEY": "LYON", "HIPODROM": "Lyon La Soie", "YER": "Lyon la Soie Fransa", "YABANCI": true}, {
    "KEY": "VICHY",
    "HIPODROM": "Vichy",
    "YER": "Vichy Fransa",
    "YABANCI": true
}, {"KEY": "DELMAR", "HIPODROM": "Del Mar", "YER": "Del Mar ABD", "YABANCI": true}, {
    "KEY": "ALAMITOS",
    "HIPODROM": "Los Alamitos",
    "YER": "Los Alamitos ABD",
    "YABANCI": true
}, {"KEY": "ELLISPARK", "HIPODROM": "Ellis Park", "YER": "Ellis Park ABD", "YABANCI": true}, {
    "KEY": "FAIRGROUND",
    "HIPODROM": "Fair Grounds",
    "YER": "Fair Grounds ABD",
    "YABANCI": true
}, {"KEY": "SANISIDRO", "HIPODROM": "San Isidro", "YER": "San Isidro Arjantin", "YABANCI": true}, {
    "KEY": "MARONAS",
    "HIPODROM": "Maronas",
    "YER": "Maronas Uruguay",
    "YABANCI": true
}, {"KEY": "SHEBA", "HIPODROM": "Nad Al Sheba ", "YER": "Nad Al Sheba Dubai", "YABANCI": true}, {
    "KEY": "KENILWORTH",
    "HIPODROM": "Kenilworth",
    "YER": "Kenilworth G\u00fcney Afrika",
    "YABANCI": true
}, {
    "KEY": "FLAMINGO",
    "HIPODROM": "Flamingo Park",
    "YER": "Flamingo Park  G\u00fcney Afrika",
    "YABANCI": true
}, {
    "KEY": "SCOTTSVILL",
    "HIPODROM": "Scottsville",
    "YER": "Scottsville G\u00fcney Afrika",
    "YABANCI": true
}, {
    "KEY": "DURBANVILL",
    "HIPODROM": "Durbanville",
    "YER": "Durbanville G\u00fcney Afrika",
    "YABANCI": true
}, {"KEY": "KRANJI", "HIPODROM": "Kranji Singapur", "YER": "Kranji Singapur", "YABANCI": true}, {
    "KEY": "HAPPYVALLE",
    "HIPODROM": "Happy Valley",
    "YER": "Happy Valley Hong Kong",
    "YABANCI": true
}, {"KEY": "CAGNESSUR", "HIPODROM": "Cagnes Sur Mer", "YER": "Cagnes Sur Mer Fransa", "YABANCI": true}, {
    "KEY": "RIYAD",
    "HIPODROM": "Riyad",
    "YER": "Riyad Suudi Arabistan",
    "YABANCI": true
}, {"KEY": "SAINTCLOUD", "HIPODROM": "Saint Cloud", "YER": "Saint Cloud Fransa", "YABANCI": true}, {
    "KEY": "ROGERSDOWN",
    "HIPODROM": "Will Rogers Downs",
    "YER": "Will Rogers Downs ABD",
    "YABANCI": true
}, {"KEY": "NAAS", "HIPODROM": "Naas", "YER": "Naas \u0130rlanda", "YABANCI": true}, {
    "KEY": "DUNDALK",
    "HIPODROM": "Dundalk",
    "YER": "Dundalk \u0130rlanda",
    "YABANCI": true
}, {"KEY": "SHATIN", "HIPODROM": "Sha Tin", "YER": "Sha Tin Hong Kong", "YABANCI": true}, {
    "KEY": "CRANBOURNE",
    "HIPODROM": "Cranbourne",
    "YER": "Cranbourne Avustralya",
    "YABANCI": true
}, {"KEY": "PAKENHAM", "HIPODROM": "Pakenham", "YER": "Pakenham Avustralya", "YABANCI": true}, {
    "KEY": "PERTHASCOT",
    "HIPODROM": "Perth Ascot",
    "YER": "Perth Ascot Avustralya",
    "YABANCI": true
}, {"KEY": "GAVEA", "HIPODROM": "Gavea", "YER": "Gavea Brezilya", "YABANCI": true}, {
    "KEY": "KOLN",
    "HIPODROM": "K\u00f6ln",
    "YER": "Koln Almanya",
    "YABANCI": true
}, {"KEY": "HANNOVER", "HIPODROM": "Hannover", "YER": "Hannover Almanya", "YABANCI": true}, {
    "KEY": "DAGLFING",
    "HIPODROM": "M\u00fcnchen-Daglfing",
    "YER": "M\u00fcnchen-Daglfing Almanya",
    "YABANCI": true
}, {"KEY": "MULHEIM", "HIPODROM": "M\u00fclheim", "YER": "M\u00fclheim Almanya", "YABANCI": true}, {
    "KEY": "HOPPEGARTE",
    "HIPODROM": "Berlin Hoppegarten",
    "YER": "Berlin Hoppegarten Almanya",
    "YABANCI": true
}, {"KEY": "MANNHEIM", "HIPODROM": "Mannheim", "YER": "Mannheim Almanya", "YABANCI": true}, {
    "KEY": "SUNSHINE",
    "HIPODROM": "Sunshine Coast",
    "YER": "Sunshine Coast Avustralya",
    "YABANCI": true
}, {"KEY": "PARILLY", "HIPODROM": "Lyon Parilly", "YER": "Lyon Parilly Fransa", "YABANCI": true}, {
    "KEY": "RIEM",
    "HIPODROM": "M\u00fcnchen-Riem",
    "YER": "M\u00fcnchen-Riem Almanya",
    "YABANCI": true
}, {
    "KEY": "SALONDEPRO",
    "HIPODROM": "Salon de Provence",
    "YER": "Salon de Provence Fransa",
    "YABANCI": true
}, {
    "KEY": "BORELY",
    "HIPODROM": "Marseille Borely",
    "YER": "Marseille Borely Fransa",
    "YABANCI": true
}, {
    "KEY": "DUSSELDORF",
    "HIPODROM": "D\u00fcsseldorf",
    "YER": "D\u00fcsseldorf Almanya",
    "YABANCI": true
}, {"KEY": "DRESDEN", "HIPODROM": "Dresden", "YER": "Dresden Almanya", "YABANCI": true}, {
    "KEY": "LEBOUSCAT",
    "HIPODROM": "Bordeaux Le Bouscat",
    "YER": "Bordeaux Le Bouscat Fransa",
    "YABANCI": true
}, {"KEY": "BADENBADEN", "HIPODROM": "Baden-Baden", "YER": "Baden-Baden Almanya", "YABANCI": true}, {
    "KEY": "DIEPPE",
    "HIPODROM": "Dieppe Fransa",
    "YER": "Dieppe Fransa",
    "YABANCI": true
}, {
    "KEY": "CLAIREFONT",
    "HIPODROM": "Clairefontaine",
    "YER": "Clairefontaine Fransa",
    "YABANCI": true
}, {"KEY": "MOULINS", "HIPODROM": "Moulins", "YER": "Moulins Fransa", "YABANCI": true}, {
    "KEY": "DORTMUND",
    "HIPODROM": "Dortmund",
    "YER": "Dortmund Almanya",
    "YABANCI": true
}, {"KEY": "HAMBURG", "HIPODROM": "Hamburg", "YER": "Hamburg Almanya", "YABANCI": true}, {
    "KEY": "LAUNCESTON",
    "HIPODROM": "Launceston",
    "YER": "Launceston Avustralya",
    "YABANCI": true
}, {"KEY": "PAU", "HIPODROM": "Pau", "YER": "Pau Fransa", "YABANCI": true}, {
    "KEY": "ANGERS",
    "HIPODROM": "Angers",
    "YER": "Angers Fransa",
    "YABANCI": true
}, {"KEY": "TOULOUSE", "HIPODROM": "Toulouse", "YER": "Toulouse Fransa", "YABANCI": true}, {
    "KEY": "MOONEEVALL",
    "HIPODROM": "Moonee Valley",
    "YER": "Moonee Valley Avustralya",
    "YABANCI": true
}, {
    "KEY": "COLONIAL",
    "HIPODROM": "Colonial Downs",
    "YER": "Colonial Downs ABD",
    "YABANCI": true
}, {
    "KEY": "KENTUCKYDW",
    "HIPODROM": "Kentucky Downs",
    "YER": "Kentucky Downs ABD",
    "YABANCI": true
}, {"KEY": "FONTAINE", "HIPODROM": "Fontainebleau", "YER": "Fontainebleau Fransa", "YABANCI": true}, {
    "KEY": "REDCAR",
    "HIPODROM": "Redcar",
    "YER": "Redcar Birle\u015fik Krall\u0131k",
    "YABANCI": true
}, {
    "KEY": "MONTDEMARS",
    "HIPODROM": "Mont De Marsan",
    "YER": "Mont De Marsan Fransa",
    "YABANCI": true
}, {
    "KEY": "LAROCHE",
    "HIPODROM": "Le Croise-Laroche",
    "YER": "Le Croise-Laroche Fransa",
    "YABANCI": true
}, {
    "KEY": "BADHARZBUR",
    "HIPODROM": "Bad Harzburg",
    "YER": "Bad Harzburg Almanya",
    "YABANCI": true
}, {"KEY": "TOOWOOMBA", "HIPODROM": "Toowoomba", "YER": "Toowoomba Avustralya", "YABANCI": true}, {
    "KEY": "JEBELALI",
    "HIPODROM": "Jebel Ali ",
    "YER": "Jebel Ali BAE",
    "YABANCI": true
}, {
    "KEY": "AVUBELMONT",
    "HIPODROM": "Belmont Avustralya",
    "YER": "Belmont Avustralya",
    "YABANCI": true
}, {
    "KEY": "PINJARRA",
    "HIPODROM": "Pinjarra Park",
    "YER": "Pinjarra Park Avustralya",
    "YABANCI": true
}, {"KEY": "COMPIEGNE", "HIPODROM": "Compiegne", "YER": "Compiegne Fransa", "YABANCI": true}, {
    "KEY": "NAVAN",
    "HIPODROM": "Navan",
    "YER": "Navan \u0130rlanda",
    "YABANCI": true
}, {"KEY": "CORK", "HIPODROM": "Cork", "YER": "Cork \u0130rlanda", "YABANCI": true}, {
    "KEY": "ABUDHABI",
    "HIPODROM": "Abu Dhabi",
    "YER": "Abu Dhabi Birle\u015fik Arap Emirlikleri",
    "YABANCI": true
}, {"KEY": "AMIENS", "HIPODROM": "Amiens", "YER": "Amiens Fransa", "YABANCI": true}, {
    "KEY": "KILLARNEY",
    "HIPODROM": "Killarney",
    "YER": "Killarney \u0130rlanda",
    "YABANCI": true
}, {"KEY": "BALLINROBE", "HIPODROM": "Ballinrobe", "YER": "Ballinrobe \u0130rlanda", "YABANCI": true}, {
    "KEY": "GOWRAN",
    "HIPODROM": "Gowran Park",
    "YER": "Gowran Park \u0130rlanda",
    "YABANCI": true
}, {
    "KEY": "BELMONTBIG",
    "HIPODROM": "Belmont At The Big A",
    "YER": "Belmont At The Big A (Aqueduct) ABD",
    "YABANCI": true
}, {"KEY": "FAIRYHOUSE", "HIPODROM": "Fairyhouse", "YER": "Fairyhouse \u0130rlanda", "YABANCI": true}, {
    "KEY": "EVREUX",
    "HIPODROM": "Evreux",
    "YER": "Evreux Fransa",
    "YABANCI": true
}, {
    "KEY": "INDIANAPOL",
    "HIPODROM": "Horseshoe Indianapolis",
    "YER": "Horseshoe Indianapolis ABD",
    "YABANCI": true
}, {
    "KEY": "DOWNROYAL",
    "HIPODROM": "Down Royal",
    "YER": "Down Royal \u0130rlanda",
    "YABANCI": true
}, {"KEY": "SELANGOR", "HIPODROM": "Selangor", "YER": "Selangor Malezya", "YABANCI": true}, {
    "KEY": "GOLDENGATE",
    "HIPODROM": "Golden Gate",
    "YER": "Golden Gate ABD",
    "YABANCI": true
}, {"KEY": "PARADISE", "HIPODROM": "Turf Paradise", "YER": "Turf Paradise ABD", "YABANCI": true}, {
    "KEY": "ESPERANCE",
    "HIPODROM": "Esperance Avustralya",
    "YER": "Esperance Avustralya",
    "YABANCI": true
}, {"KEY": "PISA", "HIPODROM": "Pisa", "YER": "Pisa \u0130talya", "YABANCI": true}, {
    "KEY": "AGNANO",
    "HIPODROM": "Agnano",
    "YER": "Agnano \u0130talya",
    "YABANCI": true
}, {"KEY": "GERALDTON", "HIPODROM": "Geraldton", "YER": "Geraldton Avustralya", "YABANCI": true}, {
    "KEY": "ALBANY",
    "HIPODROM": "Albany",
    "YER": "Albany Avustralya",
    "YABANCI": true
}, {"KEY": "AVUYORK", "HIPODROM": "York Avustralya", "YER": "York Avustralya", "YABANCI": true}, {
    "KEY": "ROMA",
    "HIPODROM": "Roma \u0130talya",
    "YER": "Roma \u0130talya",
    "YABANCI": true
}, {"KEY": "LEMANS", "HIPODROM": "Le Mans", "YER": "Le Mans Fransa", "YABANCI": true}, {
    "KEY": "KARMAADANA",
    "HIPODROM": "Karma Adana",
    "YER": "Karma Adana",
    "YABANCI": true
}, {
    "KEY": "KARMAIZMIR",
    "HIPODROM": "Karma \u0130zmir",
    "YER": "Karma \u0130zmir",
    "YABANCI": true
}, {
    "KEY": "KARMAISTAN",
    "HIPODROM": "Karma \u0130stanbul",
    "YER": "Karma \u0130stanbul",
    "YABANCI": true
}, {"KEY": "KARMABURSA", "HIPODROM": "Karma Bursa", "YER": "Karma Bursa", "YABANCI": true}, {
    "KEY": "KARMAANKAR",
    "HIPODROM": "Karma Ankara",
    "YER": "Karma Ankara",
    "YABANCI": true
}, {
    "KEY": "KARMASANLI",
    "HIPODROM": "Karma \u015eanl\u0131urfa",
    "YER": "Karma \u015eanl\u0131urfa",
    "YABANCI": true
}, {
    "KEY": "KARMAELAZI",
    "HIPODROM": "Karma Elaz\u0131\u011f",
    "YER": "Karma Elaz\u0131\u011f",
    "YABANCI": true
}, {
    "KEY": "KARMADIYAR",
    "HIPODROM": "Karma Diyarbak\u0131r",
    "YER": "Karma Diyarbak\u0131r",
    "YABANCI": true
}, {"KEY": "KARMAKOCAE", "HIPODROM": "Karma Kocaeli", "YER": "Karma Kocaeli", "YABANCI": true}, {
    "KEY": "KARMAANTAL",
    "HIPODROM": "Karma Antalya",
    "YER": "Karma Antalya",
    "YABANCI": true
},
    {"KEY": "ARGENTAN", "HIPODROM": "Argentan", "YER": "Fransa", "YABANCI": true},
]

export const HippodromesMap: any = Object.assign({}, ...Hippodromes.map((x: any) => ({[String(x.KEY).toLowerCase()]: x})));

export const BulletinMap: any = {}

export const BulletinMapCardID: any = {}

export const OfficialResults: any = {};


export const selectedColumns: any = {
    "id": true,
    "name": true,
    "weight": true,
    "jockey": true,
    "origin": true,
    "owner": true,
    "start": true,
    "handicap": true,
    "lastSix": true,
    "lastTwenty": false,
    "kgs": true,
    "eid": false,
    "ganyan": true,
    "traning": false,
    "agf": true,
};

export const defaultColumns: any = {
    "id": true,
    "name": true,
    "weight": true,
    "jockey": true,
    "origin": true,
    "owner": true,
    "start": true,
    "handicap": true,
    "lastSix": true,
    "lastTwenty": false,
    "kgs": true,
    "eid": false,
    "ganyan": true,
    "traning": false,
    "agf": true,
}
